import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Typography, Grid, withStyles } from '@material-ui/core';
import { REACT_APP_VERSION } from '../constants';
import * as actions from '../actions';

const styles = {
  root: {
    marginTop: 40,
    //  position: 'fixed',
  },

  bottomLabel: {
    maxWidth: 300,
  },
};

const year = () => {
  const date = new Date();
  return date.getFullYear();
};

class Footer extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          style={{
            width: '100%',
            minHeight: '50px',
            maxHeight: '80px',
            backgroundColor: '#Efefef',
            bottom: 0,
          }}
        >
          <Grid
            item
            xs={12}
            sm={3}
            container
            justify="center"
            alignItems="center"
          >
            <Typography variant="caption" color="primary" align="center">
              SnapMED v{REACT_APP_VERSION}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            container
            justify="center"
            alignItems="center"
          >
            {/* It doesn't show the link if an appointment is underway */}
            {this.props.appointmentParams &&
              !this.props.appointmentParams.appointmentId && (
                <Link
                  style={{ textDecoration: 'none' }}
                  to="/TermsAndConditions"
                >
                  <Typography variant="caption" color="primary" align="center">
                    Terms of Use
                  </Typography>
                </Link>
              )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            container
            justify="center"
            alignItems="center"
          >
            {/* It doesn't show the link if an appointment is underway */}
            {this.props.appointmentParams &&
              !this.props.appointmentParams.appointmentId && (
                <Link style={{ textDecoration: 'none' }} to="/PrivacyPolicy">
                  <Typography variant="caption" color="primary" align="center">
                    Privacy Policy
                  </Typography>
                </Link>
              )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            container
            justify="center"
            alignItems="center"
          >
            <Typography variant="caption" color="primary" align="center">
              © {year()} SnapMED Inc. All Rights Reserved
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    appointmentParams: state.appointmentParams,
  };
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  appointmentParams: PropTypes.object,
};
const ConnectedFooter = connect(mapStateToProps, actions)(Footer);

export default withStyles(styles)(ConnectedFooter);
