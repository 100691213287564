import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import SymptomsForm from './SymptomsForm';
import PaymentPrompt from './PaymentPrompt';
// import '../../styles/main-style.css';
import IdleTimer from '../commonform/IdleTimer';

class SeeDoctorRoot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPaymentPrompt: false,
    };
  }

  renderContent() {
    if (this.state.showPaymentPrompt) {
      return (
        <PaymentPrompt
          onCancel={() => this.setState({ showPaymentPrompt: false })}
          patientId={this.props.appointmentParams.patientId}
        />
      );
    }

    return (
      <SymptomsForm
        patientId={this.props.appointmentParams.patientId}
        onSymptomsSubmit={(values) => {
          this.props.keepSymptomsData(values);
          if (this.props.auth.isUnlimitedPlan) {
            // TODO: unlimited account simply request to use a credit
            this.setState({ showPaymentPrompt: true });
          } else {
            this.setState({ showPaymentPrompt: true });
          }
        }}
      />
    );
  }

  render() {
    return (
      <div>
        <IdleTimer
          portal="Patient"
          component="Symptoms"
          history={this.props.history}
        />
        {this.renderContent()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    appointmentParams: state.appointmentParams,
    ohipAppointment: state.ohipAppointment,
  };
}
SeeDoctorRoot.propTypes = {
  auth: PropTypes.oneOfType([PropTypes.object]).isRequired,
  appointmentParams: PropTypes.oneOfType([PropTypes.object]).isRequired,
  ohipAppointment: PropTypes.oneOfType([PropTypes.object]).isRequired,
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
  keepSymptomsData: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, actions)(SeeDoctorRoot);
