import React, { useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import { ArrowBackIos, ExpandMore } from '@material-ui/icons';
import ConsentFormDialog from '../../dialogs/ConsentFormDialog';
import * as actions from '../../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
});
const PdfRendering = (props) => {
  const [openConsentDialog, setOpenConsentDialog] = useState(false);
  const [rxInfo, setRxInfo] = useState({});
  const [faxing, setFaxing] = useState(false);
  const { classes } = props;
  const { pharmacy } = props.auth.patient;
  const canFax =
    pharmacy.faxNumber ||
    (pharmacy.premiumPharmacy && pharmacy.premiumPharmacy.faxNumber);

  const handleFax = (prescription) => {
    setFaxing(true);
    const firstTimeFax = !prescription.destinationPharmacy.faxNumber;
    const endpoint = firstTimeFax
      ? `/api/prescriptions/${prescription._id}/fax`
      : `/api/prescriptions/${prescription._id}/resend`;
    axios.get(endpoint).then(() => {
      setFaxing(false);
      props.getAppointmentHistory(props.selectedUser._id);
      props.closeDocument();
    });
  };

  const downloadPDF = async (path) => {
    const response = await axios.get(path, {
      responseType: 'blob',
    });
    if (response.data) {
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = path.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  const renderAccordion = (rxData) =>
    rxData.map((rx) => {
      const hasUnsignedConsent =
        rx.consentForms && rx.consentForms.length > 0 && !rx.isConsentSigned;
      return (
        <Accordion key={rx._id} style={{ width: '100%' }}>
          <AccordionSummary
            style={{ backgroundColor: '#ebebeb' }}
            expandIcon={<ExpandMore />}
          >
            <Typography variant="button">
              Rx: {rx._id.substring(19, 24)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: '#fcfcfc' }}>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                {rx.medications &&
                  rx.medications.length > 0 &&
                  rx.medications.map((m) => (
                    <>
                      <Typography variant="body2">
                        <strong> Medication:</strong> {m.name} {m.dosage}{' '}
                        {m.dosageUnit}
                      </Typography>
                      <Typography variant="body2">
                        <strong> Directions:</strong> {m.bottleLabel}
                      </Typography>
                    </>
                  ))}
              </Grid>
              {hasUnsignedConsent && (
                <Grid item>
                  <Typography variant="body2">
                    Please sign consent to fax prescription
                  </Typography>
                </Grid>
              )}

              {rx.destinationPharmacy &&
                (rx.prescriptionWasFaxed ||
                  rx.prescriptionWasFaxedDashboard) && (
                  <Grid item container xs={12}>
                    <Grid item>
                      <Typography variant="body2">
                        Prescription faxed to: {rx.destinationPharmacy.name}
                      </Typography>
                      <Typography variant="body2">
                        at: {rx.destinationPharmacy.faxNumber}
                      </Typography>
                      <Typography paragraph variant="body2">
                        on:{' '}
                        {new Date(rx.faxDate).toLocaleString('en-CA', {
                          dateStyle: 'short',
                          timeStyle: 'short',
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </AccordionDetails>
          <AccordionActions style={{ backgroundColor: '#fcfcfc' }}>
            {canFax &&
              !hasUnsignedConsent &&
              !rx.prescriptionWasFaxedDashboard && (
                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={faxing}
                    onClick={() => {
                      handleFax(rx);
                    }}
                  >
                    Fax prescription
                    {faxing && (
                      <CircularProgress
                        size={15}
                        color="primary"
                        style={{ marginLeft: '5px' }}
                      />
                    )}
                  </Button>
                </Grid>
              )}
            {!props.auth.patient.pharmacy.premiumPharmacy &&
              !props.auth.patient.pharmacy.faxNumber && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => props.addPharmacy()}
                >
                  Add Pharmacy
                </Button>
              )}
            {hasUnsignedConsent ? (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setRxInfo({ form: rx.consentForms[0], id: rx._id });
                    setOpenConsentDialog(true);
                  }}
                >
                  Sign Consent
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => downloadPDF(rx.documentSrc)}
                >
                  Download PDF
                </Button>
              </Grid>
            )}
          </AccordionActions>
          {openConsentDialog && (
            <ConsentFormDialog
              open={openConsentDialog}
              close={() => {
                setOpenConsentDialog(false);
                props.closeDocument();
              }}
              rxInfo={rxInfo}
            />
          )}
        </Accordion>
      );
    });
  return (
    <>
      <main className={classes.layout}>
        <Grid
          container
          style={{
            margin: 0,
            width: '100%',
            marginTop: '5%',
          }}
          direction="row"
        >
          <Grid item xs={9} sm={10}>
            <Typography
              variant="h6"
              className={classes.spacing}
              color="primary"
            >
              Rxs for appointment{' '}
              {props.rxData[0].appointment.substring(19, 24).toUpperCase()}
            </Typography>
          </Grid>
          <Grid item container xs={3} sm={2} justify="flex-end">
            <Button
              startIcon={<ArrowBackIos />}
              variant="outlined"
              size="small"
              onClick={props.closeDocument}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '10px' }}>
            {renderAccordion(props.rxData)}
          </Grid>
        </Grid>
      </main>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedUser: state.selectedUser,
  };
}

PdfRendering.propTypes = {
  classes: PropTypes.object.isRequired,
  closeDocument: PropTypes.func.isRequired,
  rxData: PropTypes.array.isRequired,
  getAppointmentHistory: PropTypes.func.isRequired,
  addPharmacy: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  selectedUser: PropTypes.object.isRequired,
};

const ConnectedPdfRendering = connect(mapStateToProps, actions)(PdfRendering);

export default withStyles(styles)(ConnectedPdfRendering);
