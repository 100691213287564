/* eslint-disable default-param-last, func-names */
import { PAYMENTHISTORY } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case PAYMENTHISTORY:
      return {
        ...state,
        paymentHistory: action.payload,
      };

    default:
      return state;
  }
}
