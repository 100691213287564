import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Paper, Typography, Grid, withStyles } from '@material-ui/core';
import logoMain from '../SnapMedLogoMain.png';
import logoMainRetina from '../SnapMedLogoMainRetina.png';
import * as actions from '../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      maxWidth: 450,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px',
    borderRadius: '7px',
  },
});

class LoggedOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgotPasswordField: false,
      email: '',
      forgotEmailMessage: '',
      passwordIsMasked: true,
      loadingRegister: false,
      loadingResetPassword: false,
      seconds: 5,
      secondsInterval: '',
    };
    this.handleDispatch = this.handleDispatch.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      this.handleDispatch();
      this.props.history.push('/');
    }, this.state.seconds * 1000);
    this.setState({
      secondsInterval: setInterval(
        () =>
          this.setState((prevState) =>
            prevState.seconds > 0 ? { seconds: prevState.seconds - 1 } : 0
          ),
        1000
      ),
    });
  }
  componentWillUnmount() {
    clearInterval(this.state.secondsInterval);
  }
  handleDispatch() {
    const { dispatch } = this.props;
    dispatch({ type: 'logout', payload: false });
  }
  render() {
    const { classes } = this.props;
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Grid container justify="center" spacing={3}>
            <Grid item container justify="center" xs={12}>
              <img
                alt="SnapMed Logo"
                src={logoMain}
                srcSet={`${logoMainRetina} 2x,`}
              />
            </Grid>
            <Grid item container justify="center" xs={12}>
              <Typography variant="h6" align="center">
                You have logged in somewhere else! You will be redirected in{' '}
                <span style={{ color: 'red' }}>{this.state.seconds}</span>{' '}
                seconds.
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    ...actions,
    dispatch,
  };
}

LoggedOut.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const ConnectedLoggedOut = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoggedOut);
export default withStyles(styles)(ConnectedLoggedOut);
