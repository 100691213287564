/* eslint-disable default-param-last, func-names */
import { FINALIZE_SCHOOL_SICK_NOTE } from '../actions/types';

export default function (state = false, action) {
  switch (action.type) {
    case FINALIZE_SCHOOL_SICK_NOTE:
      return action.payload;
    default:
      return state;
  }
}
