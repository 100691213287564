import io from 'socket.io-client';
import { authApi } from '../api/auth-api';
import { REACT_APP_HOST_NAME } from '../constants';
import { getCookie } from '../utils/getCookie';

export class SocketIOClient {
  #reconnectInterval;
  #reconnectAttempts = 0;
  #connecting = false;

  constructor(
    namespace,
    userRole,
    reconnectMs = 5000,
    maxReconnectAttempts = 5
  ) {
    this.namespace = namespace;
    this.reconnectMs = reconnectMs;
    this.maxReconnectAttempts = maxReconnectAttempts;
    this.userRole = userRole;

    this.client = io(`${REACT_APP_HOST_NAME}/${this.namespace}`, {
      autoConnect: false,
      transports: ['websocket'],
      auth: {
        token: '',
      },
    });

    const onAuth = () => {
      if (!this.userRole || authApi.getUserType() === this.userRole) {
        this.setAuth();
        this.connect();
      }
    };

    if (authApi.isAuthenticated()) {
      onAuth();
    } else {
      authApi.once('authenticated', onAuth.bind(this));
    }

    this.client.on('connect_error', (error) => {
      if (error.message === 'Invalid auth token') {
        // refresh token
        authApi.setToken(getCookie('connect.sid'));
      }
    });

    this.client.on('disconnect', () => {
      this.reconnect();
    });
  }

  setAuth() {
    const token = authApi.getToken();

    if (this.client) {
      this.client.auth.token = token;
    } else {
      throw new Error('Cannot set auth before client is defined');
    }
  }

  connect() {
    if (!this.#connecting) {
      if (!this.client)
        throw new Error('Client must be initialized before connecting');
      if (!this.client.auth.token)
        throw new Error('Cannot connect before authenticating');

      clearInterval(this.#reconnectInterval);

      this.#connecting = true;
      this.#reconnectAttempts = 0;
      this.client.connect();

      if (this.client.connected) {
        this.#connecting = false;
      } else {
        this.#reconnectInterval = setInterval(() => {
          this.#reconnectAttempts++;

          this.client.connect();

          if (this.client.connected) {
            clearInterval(this.#reconnectInterval);
            this.#connecting = false;
          }

          if (this.#reconnectAttempts >= this.maxReconnectAttempts) {
            clearInterval(this.#reconnectInterval);
          }
        }, this.reconnectMs);
      }
    }
  }

  reconnect() {
    if (!this.client) {
      throw new Error('Client must be initialized before reconnecting');
    }

    this.client.disconnect();

    this.client = io(`${REACT_APP_HOST_NAME}/${this.namespace}`, {
      autoConnect: false,
      transports: ['websocket'],
      auth: {
        token: this.client.auth.token,
      },
    });

    this.connect();

    this.client.on('disconnect', () => {
      this.reconnect();
    });
  }

  on(event, handler) {
    this.client.on(event, handler);
  }

  off(event) {
    this.client.off(event);
  }

  emit(event, args) {
    this.client.emit(event, args);
  }

  listeners(event) {
    return this.client.listeners(event);
  }
}
