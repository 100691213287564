import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { InfoOutlined, Close } from '@material-ui/icons';
import {
  Typography,
  LinearProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  withStyles,
} from '@material-ui/core';

const styles = (theme) => ({
  passwordTooWeak: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'red',
    },
  },
  passwordWeak: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'orange',
    },
  },
  passwordMedium: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'yellow',
    },
  },
  passwordStrong: {
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: 'green',
    },
  },
  infoButton: {
    fontSize: '1em',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
  },
});

class PasswordStrengthBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHelpDialog: false,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getPasswordBarClass = (classes, strength) => {
    switch (strength.id) {
      case 0:
        return classes.passwordTooWeak;
      case 1:
        return classes.passwordWeak;
      case 2:
        return classes.passwordMedium;
      case 3:
        return classes.passwordStrong;
      default:
        return '';
    }
  };

  handleOnClick = () => {
    this.setState({ showHelpDialog: true });
  };

  handleClose = () => {
    this.setState({ showHelpDialog: false });
  };

  // eslint-disable-next-line class-methods-use-this
  renderHelpText = () => (
    <Typography variant="body1">
      We require that all passwords meet the following criteria:
      <ul>
        <li>Be at least eight characters long, ten is prefered</li>
        <li>Contain one or more numbers</li>
        <li>Include one uppercase and one lowercase letter</li>
        <li>
          Have at least one of the following special characters: <br />
          {`!"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~`}
        </li>
      </ul>
    </Typography>
  );

  render() {
    const { strength, classes, ...props } = this.props;
    const { showHelpDialog } = this.state;
    return (
      <div {...props}>
        {strength.id >= 0 && (
          <LinearProgress
            variant="determinate"
            value={(strength.id + 1) * 25}
            className={this.getPasswordBarClass(classes, strength)}
          />
        )}

        {strength.value && (
          <Fragment>
            <Typography variant="caption">{`Strength: ${strength.value}`}</Typography>

            <Tooltip title="Help" placement="right" arrow>
              <IconButton onClick={this.handleOnClick} size="small">
                <InfoOutlined className={classes.infoButton} />
              </IconButton>
            </Tooltip>
          </Fragment>
        )}

        <Dialog open={showHelpDialog} onClose={this.handleClose}>
          <DialogTitle>
            Password Strength
            <IconButton
              className={classes.closeButton}
              onClick={this.handleClose}
              size="small"
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{this.renderHelpText()}</DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

PasswordStrengthBar.propTypes = {
  strength: PropTypes.shape({
    id: PropTypes.number,
    value: PropTypes.string,
  }).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(PasswordStrengthBar);
