import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Grid, Button } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const styles = (theme) => ({
  layout: {
    width: 'auto',
    height: '100%',
    //  display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down(350 + theme.spacing.unit * 3 * 2)]: {
      width: 350,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    flexGrow: 1,
    flexDirection: 'row',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
});

class TermsAndConditions extends Component {
  render() {
    const { classes } = this.props;
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Fragment>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h6" align="center" color="primary">
                  Terms and Conditions
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  padding: '10px',
                  height: '400px',
                  overflowY: 'scroll',
                }}
              >
                <Typography variant="body1" color="primary" align="center">
                  SnapMed Website Terms of Use
                </Typography>
                <Typography variant="body2" color="primary">
                  <p>
                    <strong>
                      Use of this Website and the SnapMed Platform
                    </strong>
                    <br />
                    By using this website and by registering an account with
                    SnapMed Inc. (“SnapMed”), you agree to these terms of use.
                    These terms of use are an Ontario contract. If you do not
                    agree with the terms of use, do not use this website or the
                    SnapMed platform. SnapMed may change or modify the website,
                    the SnapMed platform or these terms of use at any time
                    without notice or liability. You may not change or modify
                    this contract in any way. You are responsible to review
                    these terms of use on a frequent basis to make yourself
                    aware of its content. When you sign into your user account,
                    you are agreeing to the most current terms of use posted on
                    this website.
                  </p>
                  <p>
                    Because of the nature of the services provided on SnapMed,
                    you must only request services and provide information only
                    for (a) yourself or, (b) if you are an authorized
                    representative (e.g., a substitute decision maker or legal
                    guardian), for the person whom you represent. Please see the
                    age requirements below.
                  </p>
                  <p>
                    <strong>Description of the Services</strong>
                    <br />
                    SnapMed’s business is to provide a medical platform that
                    allows members of the public to safely and securely connect
                    to physicians or other health practitioners for medical or
                    health related consultations. This form of consultation is
                    called Telehealth in Canada. It includes using secure
                    technology to allow communication between patients and
                    providers without them being present in the same location.
                    There are two types of providers on SnapMed’s platform:
                    physicians and other health providers. Both types of
                    providers will provide their own services through the
                    SnapMed platform. SnapMed is not a health care provider.
                    SnapMed is not responsible for any of its providers
                    services.
                  </p>
                  <p>
                    <strong>Canadian Certified Physicians</strong> - Physicians
                    providing services on SnapMed’s platform are all licensed as
                    physicians in their respective provinces in Canada. The
                    physicians operate as individual practitioners and they are
                    governed by the rules and regulations, codes of conduct and
                    professional duties established by their respective
                    professional body in their province. Patients have access to
                    their physicians’ information by reviewing the profile of
                    the physicians listed on the “College of Physicians and
                    Surgeons” of the province. Complaints about physicians can
                    be made to the physician’s governing body. Any services or
                    consultations are between the physician and patient and
                    therefore the rules surrounding the physician-patient
                    relationship apply. However, this relationship does not
                    extend to SnapMed as it is not part of that relationship
                  </p>
                  <p>
                    <strong>Other Health Practitioners</strong> - Other health
                    care practitioners permitted by SnapMED may also provide
                    services through the SnapMED platform. These practitioners
                    are independent and are regulated health care professionals.
                    They may include pharmacists, nurse practitioners,
                    psychologists or dietitians. These practitioners are all
                    licensed as regulated health professionals within their
                    respective provinces in Canada. Health practitioners operate
                    as individual practitioners and they are governed by the
                    rules and regulations, codes of conduct, and professional
                    duties established by their respective professional bodies
                    in their province. Patients have access to their health
                    practitioner’s information by reviewing the profile of the
                    practitioner listed on that profession’s College in the
                    applicable province. Complaints about health professionals
                    can be made to the practitioner’s governing body.
                  </p>
                  <p>
                    Any services or consultations are between the health care
                    practitioner and patient/client and therefore the rules
                    surrounding the health care practitioner-client relationship
                    apply. However, this relationship does not extend to SnapMed
                    as it is not part of that relationship.
                  </p>
                  <p>
                    Due to the way that telemedicine and health care are
                    regulated by provinces and territories in Canada, the
                    location of your residence and the timing of your service
                    request will be the determining factor in the type of
                    service that you receive. For example, Canadian residents
                    traveling abroad may use SnapMED subject to payment of the
                    applicable fees not covered by provincial health insurance,
                    and non-residents of Canada may be denied certain services,
                    such as prescriptions, due to health and telemedicine
                    regulations. For pharmacy services, your location will be
                    used to generate a list of the nearest locations of
                    pharmacies that, through a separate agreement with SnapMED,
                    have chosen to be added to the list of pharmacies. If, under
                    the provincial and territorial regulations, SnapMED is not
                    authorized to provide you with the service you have
                    requested, we will endeavour to notify you prior to
                    accepting any calls or payment provided that you have given
                    us the relevant information to make that determination,
                    however, SnapMED and the health care practitioner have no
                    liability to you for providing requested services outside of
                    the health care practitioner’s licensed jurisdiction.
                  </p>
                  <p>
                    Changes will be made to SnapMED’s platform and these Terms
                    of Use from time to time to remain consistent with changes
                    to rules and regulations in the provinces and territories.
                    SnapMED will not provide you with any notice when these
                    Terms of Use change. Any content, material or information
                    that is available on the SnapMED platform or website, other
                    than any services provided by a health care practitioner,
                    are for informational purposes only. To reduce health care
                    risk to you, you are advised to discuss with your family
                    physician the appropriateness of relying on the information
                    for your particular medical circumstances.
                  </p>
                  <p>
                    <strong>How Does it Work?</strong>
                    <br />
                    Physicians and other regulated health professionals are
                    permitted to use the SnapMed platform to provide health care
                    services by registering with SnapMed through its health care
                    professional’s registration process.
                  </p>
                  <p>
                    Health care services include:
                    <br />
                    • Information Request: you might request advice or further
                    information about a particular medical issue;
                    <br />
                    • Medical/Health Care Consultation: you might need a web
                    chat or video conference with the health care practitioner
                    and the practitioner may give you their opinion, diagnosis
                    and treatment advice;
                    <br />• Prescriptions: you might, as a result of a medical
                    consultation, require a prescription to be picked up from a
                    pharmacy nearby.
                  </p>
                  <p>
                    It is important to remember that the health care
                    practitioner is responsible to provide information and
                    services in compliance with their scope of practice as
                    regulated health professionals. In no event will SnapMed
                    accept any responsibility or liability for any services,
                    information, advice or diagnosis provided by any of the
                    practitioners using the SnapMed platform. All health care
                    practitioners using the SnapMed platform are required to
                    follow the SnapMed privacy policy in addition to any other
                    privacy laws or policies that apply to each practitioner.
                    Please see the SnapMed privacy policy for further details.
                  </p>
                  <p>
                    SnapMed requests that you consider only sharing your
                    “personal health information” as needed to complete the
                    health care consultation. Please refrain from providing
                    unnecessary information that is not considered relevant to
                    the health care practitioner providing the consultation.
                    Physicians and other health care professionals face
                    restrictions of which province they are authorized to
                    practice, therefore it is absolutely critical that you meet
                    the residency criteria and that you are truthful about your
                    province of residence. You agree that SnapMed is to be used
                    for the patient’s personal purposes and not for any
                    commercial activity.
                  </p>
                  <p>
                    The SnapMED platform has the capabilities to store the
                    patient’s personal information as provided by the patient on
                    the platform or shared through the virtual consult with
                    physicians or other medical professionals. When you sign up,
                    you are agreeing that we will be saving this information
                    under your user ID including items received from the
                    physicians or other medical practitioners such as
                    prescriptions, medical notes, images, etc. At your request,
                    SnapMED can relay information for medical reasons such as
                    sending a prescription on behalf of the patient to the
                    chosen pharmacy or informing the patient that a prescription
                    is ready. It is important to remember that you may be denied
                    access to or use of the services if you are unable to
                    present your government issued health card at the point of
                    registration, log in, or during an appointment.
                  </p>
                  <p>
                    <strong>Non-urgent Needs Only</strong>
                    <br />
                    The SnapMed platform is provided for non-urgent health care
                    needs only. Examples of the types of health needs supported
                    through SnapMed are listed on the platform.
                  </p>
                </Typography>
                <Typography color="error" variant="body1">
                  SERIOUS MEDICAL EMERGENCIES CANNOT BE TREATED THROUGH THE
                  SNAPMED PLATFORM. IF YOU THINK YOU HAVE AN EMERGENCY, PLEASE
                  CALL 911 OR THE AREA’S EMERGENCY NUMBER AND IMMEDIATELY ATTEND
                  THE NEAREST HOSPITAL FOR HELP.
                </Typography>
                <Typography variant="body2" color="primary">
                  <p>
                    SNAPMED IS NOT A REPLACEMENT FOR PRIMARY CARE. The virtual
                    visits done on the SnapMed platform are for non-urgent needs
                    only. You are advised to attend your family physician for
                    routine care. Furthermore, it is in your best interest to
                    develop a relationship with a family physician if you do not
                    have one. Although SnapMed does not transmit any medical
                    report to your family physician regarding the virtual visit,
                    we highly recommend that you advise your family physician
                    regarding the reason and outcome of the virtual
                    consultation. The consultation notes are on the patient’s
                    dashboard; feel free to print and submit consultation notes
                    to your family physician.
                  </p>
                  <p>
                    When choosing to use SnapMed for your medical consultations,
                    you must note that there are certain symptoms that will not
                    be treated through the platform. Symptoms that are not
                    treated include: • impairment,
                    <br />
                    • shortness of breath,
                    <br />
                    • chest pain,
                    <br />
                    • stroke symptom such as reduced body sensation or paralysis
                    on one side of the body,
                    <br />
                    • abdominal pain so severe that you are unable to
                    comfortably walk,
                    <br />
                    • high fever in newborn babies (3 months or younger),
                    <br />• difficulty speaking or understanding in the language
                    of communication.
                  </p>
                  <p>
                    Practitioners are restricted in prescribing certain
                    medications that are considered controlled substances,
                    including but not limited: narcotics, non-therapeutic drugs
                    and those substances that can be harmful to the patient or
                    it have been known to be abused. Note that, practitioners
                    have the right to deny you any prescription through SnapMed,
                    as no preceding relationship exists. If you do receive a
                    prescription, prescriptions may only be used for personal
                    use of the person on the prescription and cannot be
                    transferred to any other person under any circumstances. You
                    acknowledge that the medication prescribed will be taken
                    based on the instructions provided on the label and based on
                    your consultation with the pharmacist.
                  </p>
                  <p>
                    If there are any complications, or adverse reactions as a
                    result of any medications, you should immediately report it
                    to your family physician or if you do not have one then go
                    to the nearest walk in clinic. If the reaction appears to be
                    severe, you are advised to go to the emergency room. Do not
                    call SnapMed for follow up of drug reactions, as physical
                    examination may be required.
                  </p>
                  <p>
                    Physicians and other health care practitioners who are using
                    the SnapMed platform have the right at their own discretion
                    to terminate, refuse or discontinue to provide the health
                    services for any user at any time. The potential issues for
                    cause of termination of service can range from violation of
                    these terms of use to any issues identified in the health
                    care practitioner’s professional guidelines as a cause for
                    terminating a physician-patient or practitioner-client
                    relationship. In some instances, the SnapMed platform will
                    not be able to transmit information (e.g, if there is poor
                    resolution in an image). In such circumstances, other means
                    may be available (such as texting) but if the health care
                    practitioner deems that the communication does not lend
                    itself well to the circumstances, then the health care
                    professional has the right to terminate the session.
                  </p>
                  <p>
                    <strong>
                      Permitted Users/Jurisdiction/Age Requirements
                    </strong>
                    <br />
                    If you are in a jurisdiction where the use of this website
                    is illegal or prohibited, do not use the SnapMed platform or
                    this website. SnapMed is not responsible and will not be
                    liable for your use of the SnapMed platform or this website
                    in contravention of any laws. SnapMed is provided for use by
                    persons who are at least 18 or 19 years old (or, older
                    depending on the age of majority in your home province or
                    territory) and who can form legally binding contracts under
                    applicable law. By using this website, you represent that
                    you are at least 18 years old have the authority to enter
                    into this contract.
                  </p>
                  <p>
                    These terms of use are governed by the laws of the Province
                    of Ontario and the federal laws of Canada applicable in
                    Ontario. You attorn to the exclusive jurisdiction of the
                    courts of Ontario. Any claim or dispute that you have
                    arising from use of SnapMed’s platform or any related
                    matters must be commenced in a court of competent
                    jurisdiction in the Province of Ontario within 2 years after
                    the claim or cause of action arises, after which the claim
                    or cause of action is forever barred.
                  </p>
                  <p>
                    The SnapMed platform can only be used for a user who is
                    registered. The main user has the ability to add dependents
                    as needed; however, a profile must be created for each
                    dependent. If a spouse or a common law partner chooses to be
                    added under the same user ID as they are choosing to be part
                    of the same family plan, then they are agreeing that all
                    members who have access to the account can see your medical
                    information as shared through SnapMed. If a spouse/ common
                    law partner is not interested in sharing their medical
                    information, it is highly recommended that they each create
                    their own separate user account and forfeit access to the
                    family plan. Users are solely responsible for the
                    safekeeping of the user name and password. You are
                    responsible to ensure that each consultation is only
                    conducted under the correct patient profile. Misrepresenting
                    the patient or misusing the profile can create risk to
                    yourself and lead to termination of the use of the platform.
                    Users are also responsible for all activity under their
                    account name. It is highly recommended that you exercise
                    safeguards and monitor your account on a routine basis.
                  </p>
                  <p>
                    <strong>Use Restrictions</strong>
                    <br />
                    You are required to conduct yourself appropriately with the
                    health care practitioners at all times. Abusive language and
                    inappropriate conduct while communicating with anyone will
                    not be acceptable and may lead to termination of your
                    account without ant refund or liability. Respect and
                    courtesy is a requirement for anyone using the platform. The
                    SnapMed platform is to be solely used for health care
                    consultation purposes. The platform should only be used a
                    manner that laws permit.
                  </p>
                  <p>
                    <strong>No one is permitted to</strong>
                    <br />
                    • link, mirror or frame any portion of the SnapMed platform;
                    <br />
                    • Launch a program that copies any scripts, indexing and
                    surveying, data mining of any component of SnapMed platform;
                    or
                    <br />
                    • gain unauthorized access to or try to impair the SnapMed
                    platform;
                    <br />
                  </p>
                  <p>
                    <strong>Fees/ Physician Payment/ Payment Option</strong>
                    <br />
                    Fees are charged to the user for the health care services
                    rendered through the platform. In circumstances where the
                    service is covered by the provincial health plan, then no
                    fee will be charged to the user, but will be directly billed
                    to the province. Failure to provide provincial health card
                    information as required by the system may result in denial
                    of service. In provinces where virtual health care
                    consultation is not covered, the fee must be paid privately
                    by the users of SnapMED. Please phone 1-833-SNAP-333
                    (1-833-762-7333) or email contact@snapmed.ca if you have any
                    questions regarding the fees. Fees are as listed on the
                    website and may change from time to time at the discretion
                    of SnapMED. All fees are in Canadian dollars and applicable
                    taxes will be added to the listed fees. Payments are
                    accepted by credit card only. There are currently the
                    following separate types of options to pay for the virtual
                    medical consultation visits. Please note that these are
                    subject to change.
                  </p>
                  <p>
                    <strong>Pay-per-Visit</strong>
                    <br />
                    Where a service is not covered by your provincial health
                    care plan, you can choose to pay for each medical visit
                    separately. SnapMED will process the credit card payment on
                    behalf of the health care professional through the secure
                    platform. You must submit your credit card information and
                    validate your credit card prior to the start of each
                    consult. If the credit card is declined, you cannot proceed
                    with the consult. Once the credit card is validated, then
                    you may proceed to continue with your request for a medical
                    visit. However, if you choose to leave prior to the
                    physician/ health care professional accepting your request
                    for your medical visit, no payment will be charged onto your
                    credit card. However, if a physician has accepted and
                    started a medical consultation, then the visit is deemed
                    activated and a charge will be applied to your credit card.
                    The health care practitioner who takes the consultation is
                    entitled to the service fee and SnapMED will directly
                    arrange for payment. You will receive a copy of the invoice
                    and have access to the amount paid through the payment
                    history available on the user dashboard.
                  </p>
                  <p>
                    <strong>5 Visit Package</strong>
                    <br />
                    Where a service is not covered by your provincial health
                    care plan, you can choose to purchase a 5 visit package
                    which allows you to see the doctor and apply 1 visit to each
                    medical consultation. The 5 visit package option never
                    expires and can be used to all current family members in the
                    account. A visit is only deducted after the physician and
                    patient have connected over a video or phone call
                    consultation. However, if the patient leaves the appointment
                    without connecting with a physician, no visit is deducted
                    from the account. If you choose to terminate your account,
                    any fees for unused visits are not refunded. If SnapMED
                    chooses to terminate your account for any reason, then you
                    will be entitled to a refund prorated to the number of
                    visits remaining less a 25% withholding fee.
                  </p>
                  <p>
                    <strong>Annual Membership</strong>
                    <br />
                    You have the option to purchase a single plan or a family
                    plan for a 12-month period. The payment for the annual
                    membership will be taken prior to the initial consult. When
                    you purchase a single plan, you are only allowed to use the
                    plan for a 1 member of the account. If you purchase a family
                    plan, you can use the visits to members of the same family
                    (2 adults and up to 4 children under the age of 18). Once
                    the plan is purchased, you and the family members are
                    entitled to the allotted visits, however, any services which
                    are not covered by the plan will be billed in addition. The
                    annual plans are non-refundable, even if the account is
                    deactivated by you for any reason. However, if SnapMED
                    chooses to terminate your account, then you will be entitled
                    to a refund prorated to the number of months remaining less
                    a 25% withholding fee.
                  </p>
                  <p>
                    Annual plans are automatically renewed unless you provide
                    SnapMED with one month’s notice of termination prior to the
                    renewal. If SnapMED increases the annual fee, SnapMED will
                    provide you with one month’s notice by email in advance of
                    the increase. No increase will take effect on an annual plan
                    until the completion of the 12-month period already
                    purchased. Visits purchased as part of an annual plan will
                    be valid for a period of 12 months, after which point they
                    will expire. Once purchased, visits are non-refundable. One
                    visit is deducted at the end of each consultation. The
                    number of visits remaining in the annual plan will be listed
                    on the dashboard.
                  </p>
                  <p>
                    <strong>Privacy</strong>
                    <br />
                    SnapMed collects, uses and discloses personal information in
                    accordance with the Privacy Policy. By using the SnapMed
                    platform, you agree to the terms of the Privacy Policy.
                  </p>
                  <p>
                    <strong>Trademarks/Copyright/Ownership</strong>
                    <br />
                    SnapMed controls and owns all content on the platform and
                    website and all rights therein except for your personal
                    health information. You do not have the right to reference
                    SnapMed’s or its licensors’ brand elements, logos, company
                    name, products, services name or trademarks. No one is
                    permitted to copy or otherwise reproduce or distribute any
                    of the SnapMed content. No content can be modified,
                    republished, downloaded, or transmitted except with written
                    permission granted by SnapMed.
                  </p>
                  <p>
                    <strong>Linking to Other Sites</strong>
                    <br />
                    For convenience, this website may include links to other
                    websites. SnapMed has no control over these other websites
                    and your use of such websites is at your sole risk. SnapMed
                    welcomes links to its website if the following guidelines
                    are observed:
                    <br />
                    • You must seek prior written permission from SnapMed by
                    sending an email to [insert];
                    <br />
                    • Your link must clearly identify the source (such as
                    “snapmed.ca”);
                    <br />
                    • You will check your links often as URLs may change when
                    webpages are updated;
                    <br />
                    • Your link will not give any impression that SnapMed is
                    associated with or approves of the content on any other
                    website;
                    <br />
                    • Re-posting content or images on other websites, or framing
                    this website’s pages within another website, is prohibited;
                    and
                    <br />
                    • Paraphrasing or excerpting content from this website is
                    not permitted.
                    <br />
                  </p>
                  <p>
                    <strong>Disclaimers/Limitation of Liability</strong>
                    <br />
                    You acknowledge that there are risks inherent in all health
                    care services, including those provided through the SnapMED
                    platform. Your sole and exclusive remedy against SnapMED for
                    dissatisfaction with the SnapMED platform or this website is
                    to stop using SnapMED. SnapMED is not liable to you or
                    anyone else for any decision made or action taken based on
                    reliance upon the information contained on or provided
                    through the platform or this website.
                  </p>
                  <p>
                    Any information received through the SnapMED platform is to
                    be used at your sole discretion. SnapMED does not bear any
                    risk associated with the accuracy and associated use of the
                    information, advice, medication or treatment provided. Any
                    issues regarding the services provided by a health care
                    practitioner should be addressed directly with the health
                    care practitioner or the applicable governing body. You
                    hereby relieve SnapMED of any liability with respect to
                    health care services provided by a practitioner through
                    SnapMED’s platform. You and any users authorized by you are
                    solely responsible for any errors you make in or your abuse
                    of the administration of medications or medical treatments.
                    You and any users authorized by you are solely responsible
                    for providing the necessary information that is required by
                    the health care practitioner to conduct an appropriate
                    consultation. If you choose to withhold information that may
                    be pertinent to the consultations you access through
                    SnapMED, you do so at your own risk. You acknowledge that
                    you may be at risk if you or your health care provider
                    relies upon inappropriate or inaccurate information. Content
                    and materials appearing on SnapMED’s website may include
                    technical, photographic or typographical errors. SnapMED
                    cannot guarantee that the materials provided are accurate.
                    SnapMED makes no representations as to any matter
                    whatsoever, including accuracy, fitness for a particular
                    purpose, or non-infringement. Any information provided on
                    this website is provided on an “as is” basis. SnapMED
                    assumes no liability for or relating to the delay, failure,
                    interruption or corruption of any data or other information
                    transmitted in the SnapMED platform. Furthermore, you are
                    solely responsible for any potential data loss or breach due
                    to the use of an inappropriately or inadequately secured
                    device to access and use the SnapMED platform. SnapMED does
                    not represent that files available for downloading will be
                    free from any code that contains contaminating or
                    destructive properties, including infections, viruses, worms
                    or Trojan horses. Contents, materials appearing on SnapMeds
                    website can include technical, photographic or typographical
                    errors. SnapMed cannot guarantee that the materials provided
                    are accurate.
                  </p>
                  <p>
                    <strong>General Terms</strong>
                    <br />
                    Our failure to act with respect to a breach of these Terms
                    of Use by you or anyone else does not waive our right to act
                    with respect to subsequent or similar breaches. These Terms
                    of Use set forth the entire understanding and agreement
                    between you and SnapMED with respect to the use of this
                    website. In the event of a conflict between these Terms of
                    Use and any statement or materials on the website, these
                    Terms of Use prevail. SnapMED may assign this contract and
                    may sell the platform at its sole discretion without your
                    consent and without notice or liability. You may not assign
                    this contract or your rights or obligations under this
                    contract. The parties have expressly requested and required
                    that this contract and all other related documents be drawn
                    up in the English language. Les parties conviennent et
                    exigent expressement que ce contrat et tous les documents
                    qui s’y rapportent soient rediges en anglais. If you have
                    any questions about these Terms of Use or the website in
                    general, please contact us via e-mail at contact@snapmed.ca.
                  </p>
                  <p>
                    <strong>Termination/Survival</strong>
                    <br />
                    At any time and for any reason, SnapMed has the right to
                    terminate any user access or deny access to their SnapMed
                    platform account. If you breach these terms of use, in
                    addition to any other remedies it may have, SnapMed has the
                    right to terminate or suspend your use of the SnapMed
                    platform. SnapMed will not be liable to you or anyone else
                    for termination regardless of the reason for termination.
                    You have full discretion to deactivate your account at any
                    time. Upon termination of a user account, SnapMed is no
                    longer required to provide you access to the information
                    stored under your profile. To find out more details about
                    the management of your personal data after deactivation,
                    please refer to the Privacy Policy.
                  </p>
                  <p>
                    You may request access to your health care information from
                    the applicable health care practitioner you consulted in
                    SnapMed. The health care practitioner will be subject to
                    access requirements under the rules of their professional
                    college and the access to information legislation applicable
                    to their services. The Disclaimers/Limitation of Liability
                    provision survives any termination of this contract. If any
                    term, provision, covenant or condition of these Terms of Use
                    is declared invalid, illegal, unenforceable, ineffective or
                    inoperative for any reason, such declaration will not have
                    the effect of invalidating or voiding the remainder of these
                    Terms and Conditions. The part or parts of these Terms of
                    Use so held to be invalid, illegal, unenforceable,
                    ineffective or inoperative will be deemed to have been
                    stricken from these Terms and Conditions and the remainder
                    of these Terms of Use will have the same force and effect as
                    if such part or parts had never been included in the Terms
                    of Use herein.
                  </p>
                  <p>v1.1</p>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{ marginTop: '10px' }}
              >
                {this.props.auth.name ? (
                  <Link style={{ textDecoration: 'none' }} to="/dashboard">
                    <Button variant="contained" color="primary">
                      Back
                    </Button>
                  </Link>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.close()}
                  >
                    Close
                  </Button>
                )}
              </Grid>
            </Grid>
          </Fragment>
        </Paper>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

TermsAndConditions.propTypes = {
  classes: PropTypes.object,
  auth: PropTypes.object,
};

export default connect(mapStateToProps)(withStyles(styles)(TermsAndConditions));
