import React, { Component, Fragment, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, FormSection } from 'redux-form';
import {
  Button,
  CssBaseline,
  FormControl,
  Typography,
  Grid,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  Slide,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import EditOtherField from '../commonform/EditOtherField';
import * as actions from '../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: '1.5rem',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    float: 'right',
  },
  choices: {
    marginTop: 30,
  },
  title: {
    margin: '30px 0',
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  otherField: {
    marginTop: 40,
  },
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
  },
});

const AllergyField = ({ name, input, label, state, meta: { error } }) => (
  <FormControl error={error}>
    <FormControlLabel
      error={error || undefined}
      style={{ display: 'flex' }}
      control={
        <Checkbox
          {...input}
          id={name}
          checked={state}
          values={state}
          error={error || undefined}
          // value="checkedB"
          color="primary"
        />
      }
      label={label}
    />
    {error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const CheckedAllergyField = ({ name, input, label, meta: { error } }) => {
  const [checked, setChecked] = useState(true);

  return (
    <FormControl error={error}>
      <FormControlLabel
        error={error || undefined}
        style={{ display: 'flex' }}
        control={
          <Checkbox
            {...input}
            id={name}
            checked={checked || input.value}
            error={error || undefined}
            color="primary"
            onClick={() => setChecked(false)}
          />
        }
        label={label}
      />
      {error ? <FormHelperText styles>*{error}</FormHelperText> : null}
    </FormControl>
  );
};

class EditMedicalAllergies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allergies: [],
    };
  }

  componentDidMount() {
    this.setState({
      allergies: this.props.selectedUserMedical.medicalAllergies,
      otherFieldValue:
        this.props.selectedUserMedical.medicalAllergiesOtherField,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderAllAllergiesCheckBoxesFirstHalf(AllergiesArray, userMedicalAllergies) {
    return _.map(AllergiesArray, ({ name }, index) => {
      if (index % 2 === 0) {
        if (userMedicalAllergies.includes(name)) {
          return (
            <Field name={name} label={name} component={CheckedAllergyField} />
          );
        }
        if (name !== 'Other') {
          return <Field name={name} label={name} component={AllergyField} />;
        }
      }
      return null;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderAllAllergiesCheckBoxesSecondHalf(AllergiesArray, userMedicalAllergies) {
    return _.map(AllergiesArray, ({ name }, index) => {
      if (index % 2 !== 0) {
        if (userMedicalAllergies.includes(name)) {
          return (
            <Field name={name} label={name} component={CheckedAllergyField} />
          );
        }
        if (name !== 'Other') {
          return <Field name={name} label={name} component={AllergyField} />;
        }
      }
      return null;
    });
  }

  renderChoiceField(userAllergies) {
    let text = '';
    if (this.props.selectedUserMedical.medicalAllergiesOtherField !== '') {
      text = userAllergies
        .concat(this.props.selectedUserMedical.medicalAllergiesOtherField)
        .join(', ');
    } else {
      text = userAllergies.join(', ');
    }

    if (
      userAllergies.length === 0 &&
      this.props.selectedUserMedical.medicalAllergiesOtherField === ''
    ) {
      text = 'None';
    }

    return text;
  }

  render() {
    const {
      classes,
      selectedUserMedical,
      medicalAllergiesArray,
      handleSubmit,
    } = this.props;
    const { allergies } = this.state;
    const userMedicalAllergies =
      selectedUserMedical.medicalAllergies.join(', ');

    // keeps the list at the bottom of the form up to date, has no impact on what
    // is submitted by the form
    const handleChange = (event) => {
      const name = event.target.name.replace('medicalProfile.', '');
      let newAllergies;
      if (!allergies.includes(name)) {
        newAllergies = [...allergies, name];
      } else {
        newAllergies = allergies.filter((val) => val !== name);
      }
      this.setState({ allergies: newAllergies });
    };

    return (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <FormSection name="medicalProfile">
              <Grid container direction="row">
                <Grid item className={classes.title}>
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Edit Medical Allergies
                  </Typography>
                </Grid>
                <Grid container direction="row">
                  <Grid item container xs={6} justify="left">
                    <FormGroup onChange={handleChange}>
                      {this.renderAllAllergiesCheckBoxesFirstHalf(
                        medicalAllergiesArray,
                        userMedicalAllergies
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item container xs={6} justify="left">
                    <FormGroup onChange={handleChange}>
                      {this.renderAllAllergiesCheckBoxesSecondHalf(
                        medicalAllergiesArray,
                        userMedicalAllergies
                      )}
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container direction="row" className={classes.otherField}>
                  <Field
                    name="medicalAllergiesOtherField"
                    label="Add other medical allergies"
                    component={EditOtherField}
                  />
                </Grid>
                <Grid item xs={12} className={classes.choices}>
                  <Typography variant="body1" color="primary">
                    Your allergies:
                  </Typography>
                </Grid>
                <Grid xs={12} className={classes.textField}>
                  <Typography variant="h6" color="primary">
                    {this.renderChoiceField(allergies)}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  className={classes.bottomSpacing}
                >
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      className={classes.alignButton}
                      onClick={() => {
                        this.props.closeEditPage();
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.alignButton}
                      style={{
                        float: 'right',
                      }}
                      onClick={() => {
                        handleSubmit();
                        setTimeout(() => {
                          this.props.closeEditPage();
                        }, 300);
                      }}
                      disabled={this.props.pristine || !this.props.valid}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </FormSection>
          </main>
        </Slide>
      </Fragment>
    );
  }
}

const handleSave = (values, dispatch, props) => {
  const { medicalAllergiesOtherField, ...rest } = values.medicalProfile;
  const mapValues = () => {
    const array = [];
    Object.entries(rest).forEach(([key, value]) => {
      if (value) array.push(key);
    });
    return array;
  };

  const payload = {
    medicalProfile: {
      medicalAllergiesOtherField,
      medicalAllergies: mapValues(),
    },
    id: props.selectedUser._id,
  };

  dispatch(actions.editProfile(payload));
};

const validate = (values) => {
  const errors = { medicalProfile: {} };
  const { medicalProfile } = values;

  /* 
  When user selects an value, the corresponding obj gets added to 
  medicalProfile. When it is de-selected, it is set to false instead 
  of being removed from the collection.

  Therefore we cannot rely on the size of medicalProfile to tell us how many
  options are currently selected. Instead we get the subset of values that
  are not false using lodash.reduce and check the size of that.
  */
  const selected = _.reduce(
    medicalProfile,
    (tally, val) => {
      if (val) tally.push(val);
      return tally;
    },
    []
  );

  if (
    _.has(medicalProfile, 'No Medical Allergies') &&
    medicalProfile['No Medical Allergies'] !== false &&
    _.size(selected) > 1
  ) {
    errors.medicalProfile['No Medical Allergies'] =
      'You cannot select this with other options!';

    if (medicalProfile && medicalProfile.medicalAllergiesOtherField) {
      errors.medicalProfile.medicalAllergiesOtherField =
        'Please remove this value.';
    }
  }

  return errors;
};

function mapStateToProps(state) {
  const medicalProfile = {
    medicalAllergiesOtherField:
      state.selectedUserMedical.medicalAllergiesOtherField,
  };

  _.map(state.selectedUserMedical.medicalAllergies, (val) => {
    medicalProfile[val] = true;
  });

  return {
    auth: state.auth,
    selectedUser: state.selectedUser,
    selectedUserMedical: state.selectedUserMedical,
    medicalAllergiesArray: state.medicalData.medicalAllergiesArray,
    initialValues: { medicalProfile },
  };
}

EditMedicalAllergies.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedUserMedical: PropTypes.object.isRequired,
  medicalAllergiesArray: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  closeEditPage: PropTypes.func.isRequired,
};

AllergyField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  state: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
};

CheckedAllergyField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

const EditMedicalAllergiesRedux = reduxForm({
  form: 'medical-allergies-form',
  validate,
  destroyOnUnmount: true,
  enableReinitialize: true,
  onSubmit: handleSave,
})(EditMedicalAllergies);

const ConnectedEditMedicalAllergies = connect(
  mapStateToProps,
  actions
)(EditMedicalAllergiesRedux);

export default withStyles(styles)(ConnectedEditMedicalAllergies);
