export default function validationHelperFunction(
  values,
  medicalArray,
  stringPlaceholder,
  noneName,
  errors
) {
  const newErrors = errors;
  const medicalFormNames = medicalArray.map(({ name }) =>
    stringPlaceholder.concat(name)
  );

  const valuesKeys = Object.keys(values).filter((key) => values[key]);

  // Check if no options are selected
  if (valuesKeys.length === 0) {
    newErrors[noneName] = 'Please Select an Option';
    return newErrors;
  }

  // Find the selected medical form names
  const selectedMedicalOptions = valuesKeys.filter((key) =>
    medicalFormNames.includes(key)
  );

  // Check if none of the medical options are selected
  if (selectedMedicalOptions.length === 0) {
    newErrors[noneName] = 'Please Select an Option';
  }

  // Check if noneName is selected with other options
  if (
    selectedMedicalOptions.includes(noneName) &&
    selectedMedicalOptions.length > 1
  ) {
    newErrors[noneName] = 'You cannot select this option with another option';
  }

  return newErrors;
}
