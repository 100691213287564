import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import PaymentPlans from './PaymentPlans';
import PaymentAcknowledgement from './PaymentAcknowledgement';

class PaymentPlanRoot extends Component {
  state = {
    showAcknowledgement: false,
    planPaid: 0,
  };

  renderContent() {
    // origin is only defined when it comes from "dashboard" and "appointment",
    // because registration comes from a route (history) it cannot be passed
    const origin = this.props.origin ? this.props.origin : 'registration';
    // completed payment is either true or undefined,
    // completed payment is to trigger the action of taking you to see a doc
    const completedPayment = this.props.completedPayment
      ? this.props.completedPayment
      : false;
    if (this.state.showAcknowledgement) {
      return (
        <PaymentAcknowledgement
          origin={origin}
          completedPayment={completedPayment}
          onCancel={() => this.setState({ showAcknowledgement: false })}
          planPaid={this.state.planPaid}
        />
      );
    }
    return <PaymentPlans origin={origin} />;
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}

PaymentPlanRoot.propTypes = {
  origin: PropTypes.string,
  completedPayment: PropTypes.bool,
};

export default reduxForm({
  form: 'profileForm',
})(PaymentPlanRoot);
