import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { reduxForm } from 'redux-form';
import {
  Button,
  CssBaseline,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Grid,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  CircularProgress,
  OutlinedInput,
  Slide,
  Badge,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import {
  InsertDriveFile,
  ExpandMore,
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  LocalPharmacy,
  Description,
  Assignment,
  Chat,
} from '@material-ui/icons';
import PropTypes from 'prop-types';

import DoctorPdfRendering from './doctorPdfRendering/DoctorPdfRendering';
import AppointmentFiles from '../../dialogs/AppointmentFiles';
import * as actions from '../../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  layoutPdf: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 700,
    },
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
    float: 'right',
  },
  spacing: {
    marginBottom: 15,
  },
  align: {
    marginRight: '37%',
  },
  center: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
  },
});

const iconColours = {
  doctorNotes: '#317178',
  textChat: '#3688c7',
  prescription: '#504cc4',
  schoolSickNote: 'primary',
  generalSickNote: 'primary',
  files: '#8243bd ',
};

// Keep for reference
// const modules = {
//   toolbar: [
//     [{ header: [1, 2, false] }],
//     ['bold', 'italic', 'underline'],
//     [{ list: 'ordered' }, { list: 'bullet' }],
//   ],
// };

// Keep for reference
// const formats = [
//   'header',
//   'bold',
//   'italic',
//   'underline',
//   'list',
//   'bullet',
//   'indent',
// ];

const getBadgeColor = (props, theme) => {
  if (props.hasConsentForms && props.unsigned) {
    return theme.palette.error[500];
  }
  if (props.hasConsentForms && !props.unsigned) {
    return 'mediumseagreen';
  }
  return 'slategray';
};

const StyledBadge = withStyles((theme) => ({
  badge: (props) => ({
    backgroundColor: getBadgeColor(props, theme),
    color: 'white',
  }),
}))(Badge);

class DoctorAppointmentHistory extends Component {
  constructor(props) {
    super(props);
    this.perPage = 8;

    this.state = {
      page: 0,
      appointmentOrderValue: 'Latest',
      openPdf: false,
      pdfData: {},
      expandedPanel: false,
      openFilesDialog: false,
      selectedFiles: [],
      isClosingNotes: false,
    };
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
  }

  componentDidMount() {
    this.props.getDoctorAppointmentHistory();
  }

  nextPage() {
    this.setState({
      page: this.state.page + 1,
    });
  }

  previousPage() {
    this.setState({
      page: this.state.page - 1,
    });
  }
  lastPage() {
    this.setState({
      page: this.numPages,
    });
  }
  firstPage() {
    this.setState({
      page: 0,
    });
  }

  closeDocument() {
    this.setState({ openPdf: false });
  }

  // eslint-disable-next-line class-methods-use-this
  downloadPDF = async (path) => {
    const response = await axios.get(path, {
      responseType: 'blob',
    });
    if (response.data) {
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = path.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  downloadChatPDF = async (chatId) => {
    const res = await axios.get(`/api/chat-history/${chatId}`);
    if (res.data) {
      this.downloadPDF(res.data.documentSrc);
    }
  };

  async closeNotes(appt) {
    this.setState({ isClosingNotes: true });
    const values = {};
    values.room = appt._id;
    values.doctor_finalized_notes = true;
    if (appt.patient) {
      values.patientEmail = appt.patient.email;
      values.patientFirstName = appt.patient.name.first;
      values.patientLastName = appt.patient.name.last;
    }
    const response = await axios.post('/api/add_doctor_notes', values);
    if (response.data) {
      setTimeout(() => this.props.getDoctorAppointmentHistory(), 500);
    } else {
      this.setState({ isClosingNotes: false });
    }
  }

  renderRxButton = (rxArray) => {
    const hasConsentForms = rxArray.some((rx) => rx.consentForms.length > 0);
    const hasUnsignedConsent = rxArray.some(
      (rx) => rx.consentForms.length > 0 && !rx.isConsentSigned
    );
    return (
      <Tooltip title="Prescriptions">
        <IconButton
          onClick={() =>
            this.setState({
              openPdf: true,
              pdfData: rxArray,
            })
          }
        >
          <StyledBadge
            badgeContent={rxArray.length}
            invisible={false}
            hasConsentForms={hasConsentForms}
            unsigned={hasUnsignedConsent}
          >
            <LocalPharmacy style={{ color: iconColours.prescription }} />
          </StyledBadge>
        </IconButton>
      </Tooltip>
    );
  };

  handleOpenFilesDialog = (files) => {
    this.setState({
      openFilesDialog: true,
      selectedFiles: files,
    });
  };

  returnFields = () => {
    const { appointments, auth } = this.props;
    const { page, appointmentOrderValue, expandedPanel } = this.state;
    const history = [];
    const stepper = page * this.perPage;
    const top = stepper + this.perPage;
    let sortedAppointments = [];

    if (!appointments.appointmentHistory) {
      return [];
    }

    if (appointments.appointmentHistory.length === 0) {
      return (
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{
            marginTop: 20,
          }}
        >
          <Typography textAlign="center" variant="h5" color="primary">
            No appointment records.
          </Typography>
        </Grid>
      );
    }

    switch (appointmentOrderValue) {
      // Sort by most recent appointments first.
      case 'Latest':
        sortedAppointments = appointments.appointmentHistory.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA;
        });
        break;
      // Sort by oldest appointments first.
      case 'Oldest':
        sortedAppointments = appointments.appointmentHistory.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA - dateB;
        });
        break;
      // Sort by most recent appointments with unfinished notes first,
      // followed by most recent appointments with finalized notes.
      case 'Unfinished Notes': {
        sortedAppointments = appointments.appointmentHistory.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA;
        });
        const noNotes = sortedAppointments.filter(
          (appt) =>
            !appt.doctorNotesFinalized || appt.doctorNotesFinalized === null
        );
        const withNotes = sortedAppointments.filter(
          (appt) => appt.doctorNotesFinalized
        );
        sortedAppointments = [...noNotes, ...withNotes];
        break;
      }
      case 'Unfinished Appointment': {
        sortedAppointments = appointments.appointmentHistory.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA;
        });
        const unfinished = sortedAppointments.filter(
          (appt) => appt.appointmentFinalized
        );
        const finished = sortedAppointments.filter(
          (appt) => !appt.appointmentFinalized
        );
        sortedAppointments = [...finished, ...unfinished];
        break;
      }
      default:
        sortedAppointments = appointments.appointmentHistory.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateB - dateA;
        });
        break;
    }

    for (let i = stepper; i < top; i++) {
      const appt = sortedAppointments[i];
      if (appt === undefined) {
        break;
      }
      let rxConsent = false;
      let consentSigned = true;
      if (appt && Array.isArray(appt.prescriptions)) {
        appt.prescriptions.forEach((rx) => {
          if (rx.consentForms.length > 0) {
            rxConsent = true;
            if (!rx.isConsentSigned) {
              consentSigned = false;
            }
          }
        });
      }

      history.push(
        <Accordion
          expanded={expandedPanel === appt._id}
          onChange={(event, isExpanded) =>
            this.setState({ expandedPanel: isExpanded ? appt._id : false })
          }
        >
          <AccordionSummary
            style={{ backgroundColor: '#ebebeb' }}
            expandIcon={<ExpandMore />}
          >
            <Grid container direction="row" spacing={1}>
              <Grid item container xs={12} sm={6} alignItems="center">
                {appt.patientLeftWaitingRoom || !appt.patientConnected ? (
                  <Typography variant="button" color="error">
                    MISSED CALL - DATE:{' '}
                    {new Date(appt.date).toLocaleDateString('en-CA')}
                  </Typography>
                ) : (
                  <Typography variant="button" color="primary">
                    Appointment Date:{' '}
                    {new Date(appt.date).toLocaleDateString('en-CA')}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                xs={12}
                sm={6}
                direction="row"
                spacing={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="flex-start"
                  sm={8}
                  style={{
                    padding: 0,
                  }}
                >
                  {!appt.doctorNotesFinalized &&
                    !appt.patientLeftWaitingRoom && (
                      <Grid item>
                        <Typography variant="caption" color="error">
                          Unfinished Notes
                        </Typography>
                      </Grid>
                    )}
                  {!appt.appointmentFinalized && (
                    <Grid item>
                      <Typography variant="caption" color="error">
                        Unfinished Appointment
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid item sm={4}>
                  <StyledBadge
                    badgeContent=""
                    variant="dot"
                    invisible={!rxConsent}
                    hasConsentForms={rxConsent}
                    unsigned={!consentSigned}
                  >
                    <Typography
                      variant="button"
                      color="primary"
                      style={{ float: 'right' }}
                    >
                      ID: {appt._id.substring(19, 24)}
                    </Typography>
                  </StyledBadge>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>

          <AccordionDetails style={{ backgroundColor: '#fcfcfc' }}>
            <Grid container>
              <Grid item xs={12} style={{ marginLeft: '15px' }}>
                {appt.ohipAppointment && (
                  <Typography variant="body1" color="error">
                    OHIP Appointment
                  </Typography>
                )}
                {appt.patient ? (
                  <div>
                    <Typography variant="body2">
                      <strong> Patient:</strong> {appt.patient.name.first}{' '}
                      {appt.patient.name.last}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Phone Number:</strong> {appt.patient.mobilePhone}
                    </Typography>
                  </div>
                ) : (
                  <Typography variant="body2">
                    <strong> Patient:</strong> No Patient
                  </Typography>
                )}

                {appt.ohipAppointment && (
                  <Typography variant="body2">
                    <strong>OHIP Number:</strong> {appt.ohipNumber}
                  </Typography>
                )}
                <Typography variant="body2">
                  <strong>Reason for Visit:</strong> {appt.shortReason}
                </Typography>

                {appt.symptoms && appt.symptoms.length > 0 && (
                  <Typography variant="body2">
                    <strong> Symptoms:</strong> {appt.symptoms.join(', ')}
                  </Typography>
                )}
              </Grid>
              {!appt.patientLeftWaitingRoom && (
                <Grid item container direction="row" xs={12}>
                  {!appt.doctorNotesEditable ? (
                    <>
                      {!appt.doctorNotesFinalized && (
                        <Grid item container xs={12} alignItems="center">
                          <Grid item xs={1}>
                            <Typography variant="body2">
                              <strong>Notes:</strong>
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <IconButton
                              onClick={() =>
                                this.downloadPDF(appt.doctorNotesDocumentSrc)
                              }
                            >
                              <Assignment
                                style={{ color: iconColours.doctorNotes }}
                              />
                            </IconButton>
                          </Grid>
                          {!this.state.isClosingNotes ? (
                            <Grid item container xs={4} spacing={1}>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={() => {
                                    this.props.openEditNote(appt);
                                    this.props.disableToolbar(true);
                                  }}
                                >
                                  <Typography variant="subtitle2" color="error">
                                    Edit
                                  </Typography>
                                </Button>
                              </Grid>
                              <Grid item>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  onClick={() => this.closeNotes(appt)}
                                >
                                  <Typography variant="subtitle2">
                                    Close
                                  </Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid item>
                              <CircularProgress size={20} />
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </>
                  ) : (
                    <Grid container alignItems="center">
                      <Grid item xs={12} sm={6}>
                        <Typography variant="caption" color="error">
                          Please finish your notes Dr. {auth.name.last}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            this.props.disableToolbar(true);
                            this.props.openEditNote(appt);
                          }}
                        >
                          <Typography variant="subtitle2" color="error">
                            Edit Notes
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
              {appt.doctorNotesFinalized && (
                <Tooltip title="Doctor Notes">
                  <IconButton
                    onClick={() =>
                      this.downloadPDF(appt.doctorNotesDocumentSrc)
                    }
                  >
                    <Assignment style={{ color: iconColours.doctorNotes }} />
                  </IconButton>
                </Tooltip>
              )}
              {appt.savedTextChat && (
                <Tooltip title="Text Chat">
                  <IconButton>
                    <Chat
                      style={{ color: iconColours.textChat }}
                      onClick={() =>
                        this.props.openChatHistory(appt.savedTextChat)
                      }
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Grid>
                {appt.prescriptions &&
                  appt.prescriptions.length > 0 &&
                  this.renderRxButton(appt.prescriptions)}
                {appt.fileList && appt.fileList.length > 0 && (
                  <Tooltip title="Files">
                    <IconButton
                      onClick={() => this.handleOpenFilesDialog(appt.fileList)}
                    >
                      <InsertDriveFile style={{ color: iconColours.files }} />
                    </IconButton>
                  </Tooltip>
                )}
                {appt.schoolSicknote !== undefined && (
                  // <DoctorPdfDialog
                  //   data={appt.schoolSicknote.schoolSickNoteValues}
                  //   type={'Sick Note'}
                  // />
                  <IconButton
                    onClick={() =>
                      this.setState({
                        openPdf: true,
                        pdfData: appt.schoolSicknote.schoolSickNoteValues,
                      })
                    }
                  >
                    <Description color="primary" />
                  </IconButton>
                )}
                {appt.generalSicknote !== undefined && (
                  <IconButton
                    onClick={() =>
                      this.setState({
                        openPdf: true,
                        pdfData: appt.generalSicknote.generalSickNoteValues,
                      })
                    }
                  >
                    <Description color="primary" />
                  </IconButton>
                )}
              </Grid>
              {!appt.appointmentFinalized && (
                <Grid container alignItems="center" style={{ paddingTop: 10 }}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="caption" color="error">
                      This appointment is unfinished.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      variant="outlined"
                      size="small"
                      style={{}}
                      onClick={() => this.props.openFinishAppointment(appt)}
                    >
                      <Typography variant="subtitle2" color="error">
                        Edit Appointment
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    }
    return history;
  };

  render() {
    const { classes, appointments } = this.props;

    const numberAppts = appointments.appointmentHistory
      ? appointments.appointmentHistory.length
      : 0;
    this.numPages = Math.floor(numberAppts / this.perPage);
    this.bottomPerPage = this.state.page * this.perPage + 1;
    this.topPerPage = this.state.page * this.perPage + this.perPage;

    return !this.state.openPdf ? (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid
              container
              spacing={1}
              style={{
                margin: 0,
                width: '100%',
              }}
              direction="row"
            >
              <Grid item xs={12} style={{ marginTop: '5%' }}>
                <Typography
                  variant="h5"
                  className={classes.spacing}
                  color="primary"
                >
                  Your Appointment History
                </Typography>
              </Grid>
              <br />
              <Grid item xs={12}>
                <Typography variant="body1">
                  {' '}
                  You can see all your previous appointments below, just click
                  on any tab to expand it for more information:{' '}
                </Typography>
                <br />
                {/* */}
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={6} sm={4}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel htmlFor={'order'}>Order by</InputLabel>
                      <Select
                        id="order"
                        input={<OutlinedInput label="Order by" />}
                        value={this.state.appointmentOrderValue}
                        onChange={(event) =>
                          this.setState({
                            appointmentOrderValue: event.target.value,
                          })
                        }
                      >
                        {[
                          'Latest',
                          'Oldest',
                          'Unfinished Notes',
                          'Unfinished Appointment',
                        ].map((order) => (
                          <MenuItem value={order} key={order}>
                            {order}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={4}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="caption" className={classes.center}>
                      {' '}
                      {this.bottomPerPage}{' '}
                      {this.state.page === this.numPages
                        ? `- ${numberAppts}`
                        : `- ${this.topPerPage}`}{' '}
                      of {numberAppts}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Grid container direction={'row'}>
                      <Grid item xs={3}>
                        <IconButton
                          onClick={this.firstPage}
                          disabled={this.state.page === 0}
                        >
                          <FirstPage />
                        </IconButton>
                      </Grid>
                      <Grid item xs={3}>
                        <IconButton
                          onClick={this.previousPage}
                          disabled={this.state.page === 0}
                        >
                          <KeyboardArrowLeft />
                        </IconButton>
                      </Grid>

                      <Grid item xs={3}>
                        <IconButton
                          onClick={this.nextPage}
                          disabled={this.state.page === this.numPages}
                        >
                          <KeyboardArrowRight />
                        </IconButton>
                      </Grid>
                      <Grid item xs={3}>
                        <IconButton
                          onClick={this.lastPage}
                          disabled={this.state.page === this.numPages}
                        >
                          <LastPage />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {!appointments.appointmentHistory && (
                  <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                    alignContent="center"
                    style={{
                      height: '200px',
                    }}
                  >
                    <CircularProgress size={40} color="primary" />
                  </Grid>
                )}
                {this.returnFields()}
              </Grid>
            </Grid>
          </main>
        </Slide>
        {this.state.openFilesDialog && (
          <AppointmentFiles
            open={this.state.openFilesDialog}
            onClose={() =>
              this.setState({ openFilesDialog: false, selectedFiles: [] })
            }
            files={this.state.selectedFiles}
          />
        )}
      </Fragment>
    ) : (
      <Fragment>
        <Slide in={true} direction="right">
          <main className={classes.layoutPdf}>
            <Grid item>
              <DoctorPdfRendering
                rxData={this.state.pdfData}
                closeDocument={() => this.closeDocument()}
                disableToolbar={this.props.disableToolbar}
              />
            </Grid>
          </main>
        </Slide>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    form: state.form,
    auth: state.auth,
    appointments: state.appointments,
  };
}

DoctorAppointmentHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  appointments: PropTypes.object.isRequired,
  getDoctorAppointmentHistory: PropTypes.func.isRequired,
  openEditNote: PropTypes.func.isRequired,
  openChatHistory: PropTypes.func.isRequired,
  openFinishAppointment: PropTypes.func.isRequired,
  disableToolbar: PropTypes.func.isRequired,
};

const DoctorAppointmentHistoryRedux = reduxForm({
  //  validate,
  form: 'unfinishedNotesForm',
  destroyOnUnmount: false,
})(DoctorAppointmentHistory);

export default withStyles(styles)(
  connect(mapStateToProps, actions)(DoctorAppointmentHistoryRedux)
);
