import React, { useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import {
  Grid,
  Button,
  Typography,
  Badge,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
  withStyles,
} from '@material-ui/core';

import { ExpandMore } from '@material-ui/icons';

function PrescriptionCard(props) {
  const { classes } = props;

  const StyledBadge = withStyles((theme) => ({
    badge: (componentProps) => ({
      backgroundColor: componentProps.signed
        ? 'mediumseagreen'
        : theme.palette.error[500],
    }),
  }))(Badge);

  const downloadPDF = async (path) => {
    const response = await axios.get(path, {
      responseType: 'blob',
    });
    if (response.data) {
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = path.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  const renderPrescriptions = (rxArray) => {
    if (rxArray.length > 0) {
      return rxArray.map((prescription) => (
        <Accordion key={prescription._id} style={{ width: '100%' }}>
          <AccordionSummary
            style={{ backgroundColor: '#ebebeb' }}
            expandIcon={<ExpandMore />}
          >
            <Typography variant="button">
              Rx: {prescription._id.substring(19, 24)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: '#fcfcfc' }}>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                {prescription.medications &&
                  prescription.medications.length > 0 &&
                  prescription.medications.map((m) => (
                    <>
                      <Typography variant="body2">
                        <strong> Medication:</strong> {m.name} {m.dosage}{' '}
                        {m.dosageUnit} - {m.frequency} {m.duration}{' '}
                        {m.durationUnit}
                      </Typography>
                      <Typography variant="body2">
                        <strong> Directions:</strong> {m.bottleLabel}
                      </Typography>
                    </>
                  ))}
              </Grid>
            </Grid>
          </AccordionDetails>
          <AccordionActions style={{ backgroundColor: '#fcfcfc' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => downloadPDF(prescription.documentSrc)}
            >
              Download PDF
            </Button>
          </AccordionActions>
        </Accordion>
      ));
    }
    return null;
  };

  return (
    <Grid container item xs={12}>
      {props.editNoteParams.prescriptions.length > 0 ? (
        renderPrescriptions(props.editNoteParams.prescriptions)
      ) : (
        <Grid container item xs={12} justify="center" alignContent="center">
          <Typography> No prescriptions</Typography>
        </Grid>
      )}
    </Grid>
  );
}

PrescriptionCard.propTypes = {
  editNoteParams: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles()(PrescriptionCard);
