import _ from 'lodash';
import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, FormSection } from 'redux-form';
import PropTypes from 'prop-types';
import {
  Button,
  CssBaseline,
  FormControl,
  Typography,
  Grid,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  Slide,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import EditOtherField from '../commonform/EditOtherField';
import * as actions from '../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: '1.5rem',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    float: 'right',
  },
  choices: {
    marginTop: 30,
  },
  title: {
    margin: '30px 0',
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  otherField: {
    marginTop: 40,
  },
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
  },
});

const AllergyField = ({ name, input, label, state, meta: { error } }) => (
  <FormControl error={error}>
    <FormControlLabel
      error={error || undefined}
      style={{ display: 'flex' }}
      control={
        <Checkbox
          {...input}
          id={name}
          checked={state}
          values={state}
          error={error || undefined}
          // value="checkedB"
          color="primary"
        />
      }
      label={label}
    />
    {error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const CheckedAllergyField = ({ name, input, label, meta: { error } }) => {
  const [checked, setChecked] = useState(true);
  return (
    <FormControl error={error}>
      <FormControlLabel
        error={error || undefined}
        style={{ display: 'flex' }}
        control={
          <Checkbox
            {...input}
            id={name}
            checked={checked || input.value}
            error={error || undefined}
            color="primary"
            onClick={() => setChecked(false)}
          />
        }
        label={label}
      />
      {error ? <FormHelperText styles>*{error}</FormHelperText> : null}
    </FormControl>
  );
};

class EditOtherAllergies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allergies: [],
      openOtherField: false,
      otherFieldValue: '',
      touchedOther: false,
    };
  }

  componentDidMount() {
    this.setState({
      allergies: this.props.selectedUserMedical.otherAllergies,
      otherFieldValue: this.props.selectedUserMedical.otherAllergiesOtherField,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderAllAllergiesCheckBoxesFirstHalf(AllergiesArray, userOtherAllergies) {
    return _.map(AllergiesArray, ({ name }, index) => {
      if (index % 2 === 0) {
        if (userOtherAllergies.includes(name) && name !== 'Other') {
          return (
            <Field name={name} label={name} component={CheckedAllergyField} />
          );
        }
        if (name !== 'Other') {
          return <Field name={name} label={name} component={AllergyField} />;
        }
      }
      return null;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderAllAllergiesCheckBoxesSecondHalf(AllergiesArray, userOtherAllergies) {
    return _.map(AllergiesArray, ({ name }, index) => {
      if (index % 2 !== 0) {
        if (userOtherAllergies.includes(name) && name !== 'Other') {
          return (
            <Field name={name} label={name} component={CheckedAllergyField} />
          );
        }
        if (name !== 'Other') {
          return <Field name={name} label={name} component={AllergyField} />;
        }
      }
      return null;
    });
  }

  renderChoiceField(userAllergies) {
    let text = '';
    if (this.props.selectedUserMedical.otherAllergiesOtherField !== '') {
      text = userAllergies
        .concat(this.props.selectedUserMedical.otherAllergiesOtherField)
        .join(', ');
    } else {
      text = userAllergies.join(', ');
    }

    if (
      userAllergies.length === 0 &&
      this.props.selectedUserMedical.otherAllergiesOtherField === ''
    ) {
      text = 'None';
    }

    return text;
  }

  render() {
    const { classes, selectedUserMedical, otherAllergiesArray, handleSubmit } =
      this.props;
    const { allergies } = this.state;
    const userOtherAllergies = selectedUserMedical.otherAllergies.join(', ');

    const handleChange = (event) => {
      const name = event.target.name.replace('medicalProfile.', '');
      let newAllergies;

      if (!allergies.includes(name)) {
        newAllergies = [...allergies, name];
      } else {
        newAllergies = allergies.filter((val) => val !== name);
      }
      this.setState({ allergies: newAllergies });
    };

    return (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <FormSection name="medicalProfile">
              <Grid container direction="row">
                <Grid item className={classes.title}>
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Edit Other Allergies
                  </Typography>
                </Grid>
                <Grid container direction="row">
                  <Grid item container xs={6} justify="left">
                    <FormGroup onChange={handleChange}>
                      {this.renderAllAllergiesCheckBoxesFirstHalf(
                        otherAllergiesArray,
                        userOtherAllergies
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item container xs={6} justify="left">
                    <FormGroup onChange={handleChange}>
                      {this.renderAllAllergiesCheckBoxesSecondHalf(
                        otherAllergiesArray,
                        userOtherAllergies
                      )}
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container direction="row" className={classes.otherField}>
                  <Field
                    name="otherAllergiesOtherField"
                    label="Add other allergies"
                    component={EditOtherField}
                  />
                </Grid>
                <Grid item xs={12} className={classes.choices}>
                  <Typography variant="body1" color="primary">
                    Your allergies:
                  </Typography>
                </Grid>
                <Grid xs={12} className={classes.textField}>
                  <Typography variant="h6" color="primary">
                    {this.renderChoiceField(allergies)}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  className={classes.bottomSpacing}
                >
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      className={classes.alignButton}
                      onClick={() => this.props.closeEditPage()}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.alignButton}
                      style={{
                        float: 'right',
                      }}
                      onClick={() => {
                        handleSubmit();
                        setTimeout(() => {
                          this.props.closeEditPage();
                        }, 300);
                      }}
                      disabled={this.props.pristine || !this.props.valid}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </FormSection>
          </main>
        </Slide>
      </Fragment>
    );
  }
}

const handleSave = (values, dispatch, props) => {
  const { otherAllergiesOtherField, ...rest } = values.medicalProfile;
  const mapValues = () => {
    const array = [];
    Object.entries(rest).forEach(([key, value]) => {
      if (value) array.push(key);
    });
    return array;
  };

  const payload = {
    medicalProfile: {
      otherAllergiesOtherField,
      otherAllergies: mapValues(),
    },
    id: props.selectedUser._id,
  };

  dispatch(actions.editProfile(payload));
};

const validate = (values) => {
  const errors = { medicalProfile: {} };
  const { medicalProfile } = values;

  /* 
  when user selects an option, the associated obj gets added to medicalProfile
  when it is de-selected, entry is not removed from the list

  Therefore we cannot rely on the size of medicalProfile to tell us how many
  options are currently selected. Instead we get the subset of entries that
  do not equal false using lodash.reduce and check the size of that.
  */
  const selected = _.reduce(
    medicalProfile,
    (result, val) => {
      if (val) {
        result.push(val);
      }
      return result;
    },
    []
  );

  if (
    _.has(medicalProfile, 'No Other Allergies') &&
    medicalProfile['No Other Allergies'] !== false &&
    _.size(selected) > 1
  ) {
    errors.medicalProfile['No Other Allergies'] =
      'You cannot select this with other options!';

    if (medicalProfile && medicalProfile.otherAllergiesOtherField) {
      errors.medicalProfile.otherAllergiesOtherField =
        'Please remove this value.';
    }
  }

  return errors;
};

function mapStateToProps(state) {
  const medicalProfile = {
    otherAllergiesOtherField:
      state.selectedUserMedical.otherAllergiesOtherField,
  };

  _.map(state.selectedUserMedical.otherAllergies, (val) => {
    medicalProfile[val] = true;
  });

  return {
    auth: state.auth,
    selectedUser: state.selectedUser,
    selectedUserMedical: state.selectedUserMedical,
    otherAllergiesArray: state.medicalData.otherAllergiesArray,
    initialValues: { medicalProfile },
  };
}

EditOtherAllergies.propTypes = {
  classes: PropTypes.object.isRequired,
  closeEditPage: PropTypes.func.isRequired,
  selectedUserMedical: PropTypes.object.isRequired,
  otherAllergiesArray: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
};

AllergyField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  state: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
};

CheckedAllergyField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

const EditOtherAllergiesRedux = reduxForm({
  validate,
  form: 'other-allergies-form',
  destroyOnUnmount: true,
  enableReinitialize: true,
  onSubmit: handleSave,
})(EditOtherAllergies);

const ConnectedEditOtherAllergies = connect(
  mapStateToProps,
  actions
)(EditOtherAllergiesRedux);

export default withStyles(styles)(ConnectedEditOtherAllergies);
