import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';

import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements';
import {
  Button,
  CssBaseline,
  Paper,
  CircularProgress,
  Typography,
  withStyles,
} from '@material-ui/core';
import * as actions from '../../actions';

import 'typeface-roboto';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 500,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  disclaimer: {
    fontSize: 10,
  },
  empoweredHealth: {
    fontSize: 10,
    color: 'orange',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  input: {
    width: '100%',
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  link: {
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
    width: '100px',
  },
});

class CheckoutFormMonthlyFamilyPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      promoCode: '',
      promoCodeMessage: '',
      promoCodeApplied: false,
      disableButton: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePromoCode = this.handlePromoCode.bind(this);
  }

  async componentDidMount() {
    const res = await axios.get('/api/billing/stripe/GetMonthlyFamilyPlanData');

    const { price, credits } = res.data;

    this.setState({
      price,
      credits,
    });
  }

  handlePromoCode(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  PromoCodeSubmit = async () => {
    if (this.state.promoCode.length !== 0) {
      const { promoCode } = this.state;
      let percentOff = await axios.post('/api/billing/stripe/GetPromoCodes', {
        promoCode,
      });
      percentOff = percentOff.data.percentOff;
      if (percentOff && !this.state.promoCodeApplied) {
        this.setState({
          promoCodeMessage: 'Promo Code Successfully Applied!',
          promoCodeApplied: true,
        });
        this.setState((state) => ({
          price: state.price * (1 - percentOff / 100),
        }));
      } else if (this.state.promoCodeApplied) {
        this.setState({
          promoCodeMessage:
            'You can only apply promo code. Please refresh the page and try again if you would like to apply a different promo code',
        });
      } else {
        this.setState({ promoCodeMessage: 'Promo Code Did Not Work' });
      }
    }
  };

  async handleSubmit(ev) {
    ev.preventDefault();
    const cardNumber = await this.props.elements.getElement('cardNumber');

    const paymentMethod = await this.props.stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: {
        email: this.props.auth.email,
      },
    });

    const { promoCode } = this.state;

    const promoCodeObj = promoCode;

    const subscribedCustomer = await axios.post(
      '/api/billing/stripe/MonthlyFamilyPlanSubscribeCustomer',
      { ...paymentMethod, ...promoCodeObj }
    );
    if (!subscribedCustomer.data) {
      this.setState({
        promoCodeMessage: 'The Card was declined. Please try again',
      });
      return;
    }

    const { payment_intent: paymentIntent } =
      subscribedCustomer.data.latest_invoice;

    if (paymentIntent) {
      const { client_secret: clientSecret, status } = paymentIntent;

      if (status === 'requires_action') {
        const result = await this.props.stripe.confirmCardPayment(clientSecret);
        if (result.error) {
          this.setState({
            promoCodeMessage: 'The Card was declined. Please try again',
          });
          return;
        }
      }

      let path = '/paymentacknowledgment';
      if (this.props.existing) {
        path += '/existing';
      } else if (this.props.origin === 'dashboard') {
        path += '/dashboard';
      }
      this.props.history.push(path);
    }
  }
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography varient="h3" color="primary">
              Register for the <b>Monthly Family Plan</b>, unlimited* doctor
              appointments for the whole family at{' '}
              {Math.round(this.state.price)} $CAD/month.{' '}
            </Typography>
            <br />
            <Typography style={{ fontSize: '14px', fontStyle: 'italic' }}>
              Once paid, you will be brought to your dashboard with an {''}
              <span style={{ color: 'orange', fontWeight: 'bold' }}>
                empowered health.
              </span>
            </Typography>
            <form onSubmit={this.handleSubmit}>
              <h3>Insert Your Card Number</h3>
              <CardNumberElement
                style={{
                  base: { fontSize: '18px', backgroundColor: 'white' },
                }}
              />

              <h3>Insert the Expiry Date</h3>
              <CardExpiryElement style={{ base: { fontSize: '18px' } }} />

              <h3>Insert Your Cards CVC</h3>
              <CardCvcElement style={{ base: { fontSize: '18px' } }} />

              <h3>Promo code?</h3>
              <input
                type="text"
                name="promoCode"
                placeholder="Save Money Now!"
                style={{
                  fontSize: '18px',
                  border: 'none',
                  marginTop: '0%',
                }}
                onChange={this.handlePromoCode}
              />
              <Button
                color="primary"
                variant="outlined"
                onClick={this.PromoCodeSubmit}
              >
                Apply Promo Code
              </Button>
              <br />
              <br />
              <Button
                type="submit"
                variant="contained"
                textPrimary="hi"
                color="primary"
                disabled={this.state.disableButton}
              >
                Subscribe Now!
                {this.state.disableButton && (
                  <CircularProgress
                    size={15}
                    color="white"
                    style={{
                      marginLeft: 5,
                    }}
                  />
                )}
              </Button>
            </form>
            <br />
            <Typography>{this.state.promoCodeMessage}</Typography>
            <br />
            <br />
            <Typography className={classes.disclaimer} color="primary">
              * A maximum of {this.state.credits} doctor appointments are
              allowed per family monthly. A family plan consists 2 adults and 2
              children under 18. 13 % tax rates will apply on all payments.
              Email receipts for all payments will be sent within a day.
            </Typography>
          </Paper>
        </main>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

CheckoutFormMonthlyFamilyPlan.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  existing: PropTypes.bool,
  origin: PropTypes.string,
};

const StyledCheckoutFormMonthlyFamilyPlan = withStyles(styles)(
  CheckoutFormMonthlyFamilyPlan
);

export default injectStripe(
  connect(
    mapStateToProps,
    actions
  )(withRouter(StyledCheckoutFormMonthlyFamilyPlan))
);
