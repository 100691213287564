import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, FormSection, change } from 'redux-form';
import PropTypes from 'prop-types';
import {
  Button,
  CssBaseline,
  FormControl,
  MenuItem,
  Select,
  Typography,
  TextField,
  Grid,
  InputLabel,
  OutlinedInput,
  withStyles,
} from '@material-ui/core';
import { createTextMask } from 'redux-form-input-masks';
import ReactFlagsSelect from 'react-flags-select';
import InputMask from 'react-input-mask';
import * as actions from '../../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
  },
  bottomSpacing: {
    marginBottom: 20,
  },
  spacing: {
    marginBottom: 15,
  },
  tagBottomSpacing: {
    marginBottom: 5,
  },
  menuFlagsContainer: {
    maxWidth: '96px',
    height: '100%',
    paddingBottom: 0,
  },
  menuFlagsButton: {
    height: '56px',
  },
});

const customPostalCodeMaskDefinitions = {
  9: {
    regExp: /[0-9]/,
  },
  A: {
    regExp: /[A-Za-z]/,
    transform: (char) => char.toUpperCase(),
  },
};

const postalCodeMask = createTextMask({
  pattern: 'A9A 9A9',
  guide: false,
  stripMask: false,
  maskDefinitions: customPostalCodeMaskDefinitions,
});

const customPhoneNumberMaskDefinitions = {
  9: {
    regExp: /[0-9]/,
  },
};

const phoneNumberMask = createTextMask({
  pattern: '(999) 999-9999',
  guide: false,
  stripMask: false,
  allowEmpty: true,
  maskDefinitions: customPhoneNumberMaskDefinitions,
});

const ProfileField = ({ input, placeholder, disabled, name, type }) => (
  <TextField
    {...input}
    variant="outlined"
    type={type}
    id={name}
    fullWidth
    placeholder={placeholder}
    disabled={disabled}
    InputProps={{
      style: { color: 'black' },
    }}
  />
);

const DateField = ({ input, disabled, name, defaultValue }) => (
  <TextField
    {...input}
    variant="outlined"
    disabled={disabled}
    defaultValue={defaultValue}
    placeholder={defaultValue ? defaultValue.substring(0, 10) : ''}
    InputProps={{
      style: { color: 'black' },
    }}
    id={name}
    fullWidth
    inputProps={{ max: '9999-12-31' }}
  />
);

const Gender = ['Male', 'Female', 'Rather not say'];

const GenderField = ({
  name,
  input,
  placeholder,
  meta: { touched, error },
}) => (
  <FormControl variant="outlined" fullWidth>
    <InputLabel id={name}> {placeholder} </InputLabel>
    <Select
      {...input}
      error={touched && error}
      helperText={touched && error}
      input={<OutlinedInput id={name} label={placeholder} />}
    >
      {Gender.map((gend) => (
        <MenuItem key={gend} value={gend}>
          {gend}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const language = [
  'English',
  // ' Mandarin'
  // ' Spanish',
  // ' Italian',
  // ' Hindi',
  // ' Arabic',
  // ' Portuguese',
  // ' Bengali',
  // ' Russian',
  // ' Japanese',
  // ' Punjabi',
  // ' German',
  // ' French',
  // ' Korean' //uncomment for future use
];

const phoneLabels = {
  GB: {
    primary: 'GB',
    secondary: '+44',
  },
  US: {
    primary: 'US',
    secondary: '+1',
  },
  CA: {
    primary: 'CA',
    secondary: '+1',
  },
  AU: {
    primary: 'AU',
    secondary: '+61',
  },
  LK: {
    primary: 'LK',
    secondary: '+94',
  },
  IN: {
    primary: 'IN',
    secondary: '+91',
  },
  FR: {
    primary: 'FR',
    secondary: '+33',
  },
};

const LanguageField = ({ name, input, placeholder }) => (
  <FormControl fullWidth variant="outlined">
    <InputLabel id={name}> {placeholder} </InputLabel>
    <Select
      {...input}
      // multiple //uncomment for future use
    >
      {language.map((lang) => (
        <MenuItem value={lang} key={lang}>
          {lang}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

class DrDashboard extends Component {
  menuFlags = () => {
    const [selected, setSelected] = useState('CA');
    const { classes } = this.props;
    return (
      <ReactFlagsSelect
        selected={selected}
        onSelect={(code) => {
          setSelected(code);
          switch (code) {
            case 'US':
              this.props.dispatch(
                change(
                  'drDashboardUpdateForm',
                  'DrDashboard.country_code',
                  `${phoneLabels.US.secondary}`
                )
              );
              break;
            case 'CA':
              this.props.dispatch(
                change(
                  'drDashboardUpdateForm',
                  'DrDashboard.country_code',
                  `${phoneLabels.CA.secondary}`
                )
              );
              break;
            case 'AU':
              this.props.dispatch(
                change(
                  'drDashboardUpdateForm',
                  'DrDashboard.country_code',
                  `${phoneLabels.AU.secondary}`
                )
              );
              break;
            case 'LK':
              this.props.dispatch(
                change(
                  'drDashboardUpdateForm',
                  'DrDashboard.country_code',
                  `${phoneLabels.LK.secondary}`
                )
              );
              break;
            case 'IN':
              this.props.dispatch(
                change(
                  'drDashboardUpdateForm',
                  'DrDashboard.country_code',
                  `${phoneLabels.IN.secondary}`
                )
              );
              break;
            case 'FR':
              this.props.dispatch(
                change(
                  'drDashboardUpdateForm',
                  'DrDashboard.country_code',
                  `${phoneLabels.FR.secondary}`
                )
              );
              break;
            default:
              this.props.dispatch(
                change(
                  'drDashboardUpdateForm',
                  'DrDashboard.country_code',
                  `${phoneLabels.CA.secondary}`
                )
              );
              break;
          }
        }}
        showSelectedLabel={false}
        showOptionLabel={false}
        selectedSize={14}
        optionsSize={14}
        className={classes.menuFlagsContainer}
        selectButtonClassName={classes.menuFlagsButton}
        countries={['CA']}
        customLabels={phoneLabels}
      />
    );
  };

  // eslint-disable-next-line class-methods-use-this
  PhoneNumberField = ({
    input,
    name,
    placeholder,
    meta: { touched, error },
  }) => (
    <InputMask mask="(999) 999-9999" {...input} onChange={input.onChange}>
      {(inputProps) => (
        <TextField
          {...inputProps}
          fullWidth
          name={name}
          variant="outlined"
          placeholder={placeholder}
          error={touched && error}
          helperText={touched && error}
        />
      )}
    </InputMask>
  );

  // eslint-disable-next-line class-methods-use-this
  renderLanguageField(placeholder) {
    return (
      <Field
        name="spoken_languages"
        type="text"
        placeholder={placeholder}
        multiple
        format={
          (value) => value || '' /* (Array.isArray(value) ? value : []) */
        }
        onBlur={null}
        component={LanguageField}
      />
    );
  }
  // eslint-disable-next-line class-methods-use-this
  renderGenderField(placeholder) {
    return (
      <Field
        name="gender"
        type="text"
        placeholder={placeholder}
        multiple
        format={(value) => value || ''}
        onBlur={null}
        component={GenderField}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderFields(name, type, placeholder, disabled) {
    return (
      <Field
        name={name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        component={ProfileField}
      />
    );
  }

  renderPhoneFields(name, type, placeholder) {
    return (
      <Grid item container>
        <Grid item xs={4}>
          <Field name="country_code" component={this.menuFlags} />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={name}
            type={type}
            placeholder={placeholder}
            component={this.PhoneNumberField}
            {...phoneNumberMask}
          />
        </Grid>
      </Grid>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderDateField(name, disabled, type, defaultValue) {
    return (
      <Field
        name="date_of_birth"
        disabled={disabled}
        type="date"
        defaultValue={defaultValue}
        component={DateField}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderPostalCodeFields(name, type, placeholder, disabled) {
    return (
      <Field
        name={name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        component={ProfileField}
        {...postalCodeMask}
      />
    );
  }

  // Mariana Below
  onClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <FormSection name="DrDashboard">
            <Grid container spacing={2} direction="row">
              <Grid item xs={12} style={{ marginTop: '5%' }}>
                <Typography
                  className={classes.spacing}
                  variant="h5"
                  color="primary"
                >
                  Doctor Profile
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.tagBottomSpacing}
                >
                  First Name
                </Typography>
                {this.renderFields(
                  'first_name',
                  'text',
                  this.props.auth.name.first,
                  true
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.tagBottomSpacing}
                >
                  Last Name
                </Typography>
                {this.renderFields(
                  'last_name',
                  'text',
                  this.props.auth.name.last,
                  true
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.tagBottomSpacing}
                >
                  Email
                </Typography>
                {this.renderFields(
                  'email',
                  'text',
                  this.props.auth.email,
                  true
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.tagBottomSpacing}
                >
                  CPSO
                </Typography>
                {this.renderFields('CPSO', 'text', this.props.auth.CPSO, true)}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.tagBottomSpacing}
                >
                  Date of Birth
                </Typography>
                {this.renderDateField(
                  'date_of_birth',
                  true,
                  'date',
                  this.props.auth.dateOfBirth
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.tagBottomSpacing}
                >
                  Gender
                </Typography>
                {this.renderGenderField(this.props.auth.gender)}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.tagBottomSpacing}
                >
                  Spoken Languages
                </Typography>
                {this.renderLanguageField(
                  this.props.auth.spokenLanguages.join(', ')
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.tagBottomSpacing}
                >
                  Mobile Phone Number
                </Typography>
                {this.renderPhoneFields(
                  'mobile_phone_number',
                  'text',
                  this.props.auth.mobilePhone,
                  false
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  className={classes.tagBottomSpacing}
                >
                  Street Address
                </Typography>
                {this.renderFields(
                  'address',
                  'text',
                  this.props.auth.address,
                  false
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.tagBottomSpacing}
                >
                  City
                </Typography>
                {this.renderFields('city', 'text', this.props.auth.city, false)}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.tagBottomSpacing}
                >
                  Province
                </Typography>
                {this.renderFields(
                  'province',
                  'text',
                  this.props.auth.province,
                  false
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.tagBottomSpacing}
                >
                  Postal Code
                </Typography>
                {this.renderPostalCodeFields(
                  'postal_zip_code',
                  'text',
                  this.props.auth.zipCode,
                  false
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="body1"
                  className={classes.tagBottomSpacing}
                >
                  Country
                </Typography>
                {this.renderFields(
                  'country',
                  'text',
                  this.props.auth.country,
                  true
                )}
              </Grid>
              <Grid container direction="row" className={classes.bottomSpacing}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    className={classes.alignButton}
                    onClick={() => {
                      this.props.changePage();
                      this.props.disableToolbar();
                    }}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.alignButton}
                    form="dr_info_update"
                    onClick={this.onSubmit}
                    type="submit"
                    style={{
                      float: 'right',
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </FormSection>
        </main>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = {};
  if (
    values.DrDashboard &&
    values.DrDashboard.mobile_phone_number &&
    values.DrDashboard.mobile_phone_number.replace(/\D+/g, '').length !== 10
  ) {
    errors.DrDashboard = {
      mobile_phone_number: 'Please enter a valid North-American number',
    };
  }

  return errors;
}

function mapStateToProps(state) {
  return {
    form: state.form,
    auth: state.auth,
  };
}

DrDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  changePage: PropTypes.func.isRequired,
  disableToolbar: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

ProfileField.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

DateField.propTypes = {
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  meta: PropTypes.object,
};

GenderField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

LanguageField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const ConnectedDrDashboard = connect(mapStateToProps, actions)(DrDashboard);

const DrDashboardRedux = reduxForm({
  validate,
  form: 'drDashboardUpdateForm',
  destroyOnUnmount: false,
})(ConnectedDrDashboard);

export default withStyles(styles)(DrDashboardRedux);
