import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';

import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from 'react-stripe-elements';
import {
  Button,
  CssBaseline,
  Paper,
  CircularProgress,
  Grid,
  Typography,
  Tooltip,
  InputBase,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  withStyles,
} from '@material-ui/core';

import { Lock, HelpOutline } from '@material-ui/icons';
import trustBadge from '../../images/Stripe-trust-badge.png';
import cardLogosTrio from '../../images/credit-card-logos.png';
import cvcCardInfo from '../../images/cvc-card.png';
import * as actions from '../../actions';
import 'typeface-roboto';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 500,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  disclaimer: {
    fontSize: 10,
  },
  empoweredHealth: {
    fontSize: 10,
    color: 'orange',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  input: {
    width: '100%',
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  link: {
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
    width: '100px',
  },
  cardFields: {
    border: 'solid 1px lightgray',
    borderRadius: '5px',
    padding: '10px',
  },
});

class CheckoutFormYearlyPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      promoCode: '',
      promoCodeMessage: '',
      promoCodeApplied: false,
      disableButton: false,
      openCvcInfo: false,
      cardHolderName: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePromoCode = this.handlePromoCode.bind(this);
  }

  async componentDidMount() {
    const res = await axios.get('/api/billing/stripe/GetYearlyPlanData');

    const { price, credits } = res.data;

    this.setState({
      price,
      credits,
    });
  }

  handlePromoCode(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  }

  PromoCodeSubmit = async () => {
    if (this.state.promoCode.length !== 0) {
      const { promoCode } = this.state;
      let percentOff = await axios.post('/api/billing/stripe/GetPromoCodes', {
        promoCode,
      });
      percentOff = percentOff.data.percentOff;

      if (percentOff && !this.state.promoCodeApplied) {
        this.setState({
          promoCodeMessage: 'Promo Code Successfully Applied!',
          promoCodeApplied: true,
        });
        this.setState((state) => ({
          price: state.price * (1 - percentOff / 100),
        }));
      } else if (this.state.promoCodeApplied) {
        this.setState({
          promoCodeMessage:
            'You can only apply promo code. Please refresh the page and try again if you would like to apply a different promo code',
        });
      } else {
        this.setState({ promoCodeMessage: 'Promo Code Did Not Work' });
      }
    }
  };

  async handleSubmit(ev) {
    ev.preventDefault();
    this.setState({ promoCodeMessage: '', disableButton: true });
    const cardNumber = await this.props.elements.getElement('cardNumber');
    const { cardHolderName } = this.state;

    const paymentMethod = await this.props.stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber,
      billing_details: {
        name: cardHolderName,
        email: this.props.auth.email,
      },
    });
    // const setupIntent = await axios.post(
    //   '/api/billing/stripe/YearlyPlanSetupIntent/',
    //   paymentMethod
    // );
    // if (setupIntent.data.error) {
    //   this.setState({
    //     disableButton: false,
    //     promoCodeMessage: 'The card was declined. Please try again 1',
    //   });
    //   return;
    // };
    const { promoCode } = this.state;

    const promoCodeObj = promoCode;

    if (paymentMethod.error) {
      this.setState({
        promoCodeMessage: 'The Card was declined. Please try again.',
        disableButton: false,
      });
    } else {
      try {
        const subscriptionResponse = await axios.post(
          '/api/billing/stripe/YearlyPlanSubscribeCustomer',
          { ...paymentMethod, ...promoCodeObj }
        );
        if (subscriptionResponse.data.error) {
          this.setState({
            disableButton: false,
            promoCodeMessage: 'The card was declined. Please try again.',
          });
        } else {
          this.props.history.push('/paymentacknowledgment/dashboard');
        }
      } catch (err) {
        this.setState({
          promoCodeMessage: 'Something went wrong! Please try again',
          disableButton: false,
        });
      }
    }

    //   const { latest_invoice } = subscribedCustomer.data;
    //   const { payment_intent } = latest_invoice;

    //   if (payment_intent) {
    //     const { client_secret, status } = payment_intent;

    //     if (status === 'requires_action') {
    //       let result = await this.props.stripe.confirmCardPayment(client_secret);
    //       if (result.error) {
    //         this.setState({
    //           promoCodeMessage: 'The Card was declined. Please try again'
    //         });
    //       } else {
    //         this.props.history.push('/paymentacknowledgment/dashboard');
    //       }
    //     } else {
    //       this.props.history.push('/paymentacknowledgment/dashboard');
    //     }
    //   }
  }
  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Typography variant="h6" color="primary">
              Register for the <b>Yearly Plan</b>
            </Typography>
            <Typography variant="body1" color="primary" align="center">
              Get unlimited* appointments for ${this.state.price} CAD/year.
            </Typography>
            <Typography variant="caption" color="primary">
              * A maximum of {this.state.credits} doctor appointments are
              allowed per year. A 13% tax rate will apply on all payments. Your
              credit card information will be saved to your account for the
              purpose of annually auto-renewing your subscription. Unused
              credits expire 1 year from the subscription start date and the
              subscription will be automatically renewed. Auto-renewal can be
              cancelled at any time prior to your renewal date from your
              dashboard settings. You can also change your auto-renewal credit
              card at any time.
            </Typography>
            <Grid container justify="center" style={{ padding: '30px 0px' }}>
              <img src={cardLogosTrio} alt="Credit card logos" width="200px" />
            </Grid>
            <form onSubmit={this.handleSubmit}>
              <Typography
                variant="body1"
                color="primary"
                style={{
                  marginBottom: '10px',
                  borderBottom: '1px solid lightgray',
                }}
              >
                <b>Credit Card Details</b>
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Typography variant="overline" color="primary">
                    card holder
                  </Typography>
                  <InputBase
                    className={classes.cardFields}
                    onChange={(event) =>
                      this.setState({ cardHolderName: event.target.value })
                    }
                    fullWidth
                    placeholder="Name"
                    inputProps={{
                      style: {
                        padding: '0px',
                        fontSize: '0.9rem',
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="overline" color="primary">
                    exp. date
                  </Typography>
                  <CardExpiryElement
                    className={classes.cardFields}
                    style={{
                      base: {
                        textAlign: 'center',
                        '::placeholder': {
                          color: '#a2a4a6',
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="overline" color="primary">
                    card number
                  </Typography>
                  <CardNumberElement
                    className={classes.cardFields}
                    // placeholder='Card Number'
                    style={{
                      base: {
                        '::placeholder': {
                          color: '#a2a4a6',
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Grid item xs={12}>
                    <Typography variant="overline" color="primary">
                      CVC/CVV
                    </Typography>
                    <CardCvcElement
                      className={classes.cardFields}
                      placeholder="CVC/CVV"
                      style={{
                        base: {
                          textAlign: 'center',
                          '::placeholder': {
                            color: '#a2a4a6',
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item container alignItems="flex-end" xs={1}>
                  <Tooltip
                    title="This is the 3 or 4 digit number found on the back or front of your card"
                    placement="top"
                  >
                    <IconButton
                      style={{
                        padding: '6px 0px',
                      }}
                    >
                      <HelpOutline
                        style={{
                          color: '#9c9ea1',
                          fontSize: '1.5rem',
                        }}
                        onClick={() => this.setState({ openCvcInfo: true })}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>

              {/* <h3>Promo code?</h3>
              <input
                type="text"
                name="promoCode"
                placeholder="Save Money Now!"
                style={{
                  fontSize: '18px',
                  border: 'none',
                  marginTop: '0%'
                }}
                onChange={this.handlePromoCode}
              />
              <Button
                color="primary"
                variant="outlined"
                onClick={this.PromoCodeSubmit}
              >
                Apply Promo Code
              </Button> */}
              <Grid
                container
                spacing={2}
                justify="center"
                style={{ padding: '30px 0px' }}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: '#ff4a4a', color: '#ffffff' }}
                  >
                    <Link
                      style={{ color: 'white', textDecoration: 'none' }}
                      to="/dashboard"
                    >
                      Cancel
                    </Link>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={this.state.disableButton}
                  >
                    Pay ${this.state.price}
                    {this.state.disableButton && (
                      <CircularProgress
                        size={15}
                        color="white"
                        style={{
                          marginLeft: 5,
                        }}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Typography color="error" style={{ marginBottom: '10px' }}>
              {this.state.promoCodeMessage}
            </Typography>
            <Grid container justify="center" alignItems="center">
              <Lock color="primary" style={{ fontSize: 20 }} />
              <Typography color="primary" style={{ margin: '5px' }}>
                Guaranteed <b>safe & secure</b> checkout.
              </Typography>
            </Grid>
            <Grid item>
              <img
                src={trustBadge}
                alt="Stripe Trust Badge Image"
                width="150px"
              />
            </Grid>
          </Paper>
          <Dialog
            open={this.state.openCvcInfo}
            onClose={() => {
              this.setState(() => ({ openCvcInfo: false }));
            }}
            fullWidth
          >
            <DialogTitle>What is the CVC?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                The Card Verification Code is the 3 or 4 digit number found on
                the back or front of your card.
              </DialogContentText>
              <Grid container justify="center">
                <img src={cvcCardInfo} alt="Card CVC info" width="200px" />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  this.setState(() => ({ openCvcInfo: false }));
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </main>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

CheckoutFormYearlyPlan.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
};

const StyledCheckoutFormYearlyPlan = withStyles(styles)(CheckoutFormYearlyPlan);

export default injectStripe(
  connect(mapStateToProps, actions)(withRouter(StyledCheckoutFormYearlyPlan))
);
