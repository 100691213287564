export const generalSicknoteField = [
  {
    name: 'general_sicknote_final_validation_yes',
    type: 'input',
  },
  { name: 'general_sicknote_final_validation_na', type: 'input' },
];

export const schoolSicknoteField = [
  { name: 'school_sicknote_final_validation_yes', type: 'input' },
  { name: 'school_sicknote_final_validation_na', type: 'input' },
];

export const prescriptionFinalField = [
  { name: 'prescription_final_validation_yes', type: 'input' },
  { name: 'prescription_final_validation_na', type: 'input' },
];

export const finalIdValidationField = [
  { name: 'final_id_validation_field_yes', type: 'input' },
  { name: 'final_id_validation_field_na', type: 'input' },
];
