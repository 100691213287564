import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useQueue } from '../../../contexts/QueueContext';

const QueueDisplay = () => {
  const { queue } = useQueue();
  const [count, setCount] = useState(queue);

  useEffect(() => {
    setCount(queue);
  }, [queue]);

  return (
    <Typography
      variant="body1"
      color="secondary"
      style={{ textAlign: 'right' }}
    >
      Patients Waiting: <strong>{count}</strong>
    </Typography>
  );
};

export default QueueDisplay;
