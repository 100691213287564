import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography, Grid, Button, Slide } from '@material-ui/core';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 80,
      maxWidth: 600,
    },
  },
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
  },
});

class PatientProfile extends Component {
  render() {
    const { classes, selectedUser } = this.props;

    return (
      <Fragment>
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid container direction="row">
              <Grid
                item
                container
                style={{ margin: '50px 0px' }}
                alignItems="center"
              >
                <Grid item xs={9} sm={10}>
                  <Typography variant="h5" color="primary">
                    Your Profile
                  </Typography>
                </Grid>
                <Grid item container xs={3} sm={2} justify="flex-end">
                  <Button
                    variant="outlined"
                    size="small"
                    style={{ width: '70px' }}
                    onClick={() => {
                      this.props.changePage();
                      this.props.disableToolbar();
                    }}
                  >
                    Edit
                  </Button>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item container>
                  <Grid item xs={12}>
                    <Typography variant="body1">Name</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {`${selectedUser.name.first} ${selectedUser.name.last}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Date of Birth</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {selectedUser.dateOfBirth.substring(0, 10)}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Gender Identity</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {selectedUser.gender}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">
                      Sex Assigned at Birth
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {selectedUser.sex}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Spoken Languages</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {selectedUser.spokenLanguages[0]
                        ? this.props.selectedUser.spokenLanguages.join(', ')
                        : 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Mobile Phone Number</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {selectedUser.mobilePhone}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Address</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {selectedUser.address}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">City</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {selectedUser.city}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Province/Region</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {selectedUser.province}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Postal Code</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {selectedUser.zipCode}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Country</Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.textField}>
                    <Typography variant="h6" color="primary">
                      {selectedUser.country}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </main>
        </Slide>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    data: state.data,
    selectedUser: state.selectedUser,
  };
}

PatientProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedUser: PropTypes.object.isRequired,
  changePage: PropTypes.func.isRequired,
  disableToolbar: PropTypes.func.isRequired,
};

const ConnectedPatientProfile = connect(mapStateToProps)(PatientProfile);

export default withStyles(styles)(ConnectedPatientProfile);
