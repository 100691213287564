import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import PropTypes from 'prop-types';

// component imports
import {
  CssBaseline,
  withStyles,
  Typography,
  Grid,
  Button,
  Slide,
} from '@material-ui/core';
import { Close, NoteAdd } from '@material-ui/icons';

// src imports
import FinishAppointmentForm from './FinishAppointmentForm';
import DashRxForm from './DashRxForm';

// styling
const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 700,
    },
  },
  title: {
    marginTop: 15,
    marginBottom: 15,
  },
  addButton: {
    marginBottom: 20,
  },
  cancelButton: {
    borderColor: '#ff4a4a',
    color: '#ff4a4a',
  },
});

class FinishAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRx: false,
      medicationList: [],
      medWithConsent: false,
    };
  }

  componentDidMount() {
    this.props.disableToolbar(true);
  }

  componentWillUnmount() {
    this.props.disableToolbar(false);
  }

  updateMedicationList = (values) => {
    this.setState({ medWithConsent: false });
    values.forEach((med) => {
      if (med.consentId) this.setState({ medWithConsent: true });
    });
    this.setState({ medicationList: values });
  };

  handleCancelRx = () => {
    this.setState((prevState) => ({
      showRx: !prevState.showRx,
      medicationList: [],
    }));
    this.props.dispatch(change('finish-appointment-form', 'medications', []));
  };

  render() {
    const { appointment, classes } = this.props;
    const { showRx, medWithConsent } = this.state;
    const apptId = appointment._id.slice(-5).toUpperCase();
    return (
      <>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Typography variant="h5" color="primary" className={classes.title}>
              {`Finish Appointment - ${apptId}`}
            </Typography>
            <Grid item xs={12}>
              <Button
                fullWidth
                startIcon={showRx ? <Close /> : <NoteAdd />}
                className={`${classes.addButton} ${
                  showRx ? classes.cancelButton : ''
                }`}
                size="small"
                variant={showRx ? 'outlined' : 'contained'}
                color="primary"
                onClick={() =>
                  showRx
                    ? this.handleCancelRx()
                    : this.setState((prevState) => ({
                        showRx: !prevState.showRx,
                        medWithConsent: false,
                      }))
                }
              >
                {showRx ? 'Cancel Prescription' : 'Add Prescription'}
              </Button>
            </Grid>
            {showRx && (
              <DashRxForm
                {...this.props}
                getMedicationList={this.state.medicationList}
                updateMedicationList={this.updateMedicationList}
              />
            )}
            <FinishAppointmentForm
              selectedAppointment={appointment}
              showRx={showRx}
              medWithConsent={medWithConsent}
              updateMedicationList={this.updateMedicationList}
              closePage={() => this.props.exitPage()}
            />
          </main>
        </Slide>
      </>
    );
  }
}

FinishAppointment.propTypes = {
  appointment: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  disableToolbar: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  exitPage: PropTypes.func.isRequired,
};

const ConnectedFinishAppointment = connect(null, null)(FinishAppointment);

export default withStyles(styles)(ConnectedFinishAppointment);
