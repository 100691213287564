import React, { Component, Fragment } from 'react';
import SessionNotification from './SessionNotification';
import ParallelLoginNotification from './ParallelLoginNotification';
import { sessionSocket } from '../../api/session-ws';

export default class NotificationProvider extends Component {
  socket = sessionSocket;

  render() {
    return (
      <Fragment>
        <SessionNotification socket={this.socket} />
        <ParallelLoginNotification socket={this.socket} />
      </Fragment>
    );
  }
}
