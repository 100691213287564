import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';

import {
  Grid,
  Typography,
  FormControl,
  Tooltip,
  IconButton,
  InputBase,
  Button,
  Snackbar,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  InputLabel,
  withStyles,
} from '@material-ui/core';
import { ExpandMore, HelpOutline } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  injectStripe,
} from 'react-stripe-elements';

import CheckoutPaymentInfo from './CheckoutPaymentInfo';

import './StripeElements.css';
import visaLogo from '../../images/visa_logo.png';
import mastercardLogo from '../../images/mc_logo.png';
import amexLogo from '../../images/amex_logo.png';
import discoverLogo from '../../images/discover_logo.png';

const styles = (theme) => ({
  layout: {
    width: 'auto',
    height: '100%',
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down(350 + theme.spacing.unit * 3 * 2)]: {
      width: 350,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    flexGrow: 1,
    flexDirection: 'row',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  cardField: {
    border: 'solid 1px lightgray',
    borderRadius: 5,
    padding: 10,
    '&:hover': {
      border: 'solid 1px #192b4a',
    },
    '&:focus-within': {
      border: 'solid 2px #192b4a',
      padding: 9,
    },
    fontFamily: 'sans-serif',
    fontSize: '1em',
    height: 38.8,
  },
  trust: {
    paddingTop: theme.spacing.unit * 3,
  },
  cancelButton: {
    backgroundColor: '#ff4a4a',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#e54242',
    },
  },
  processing: {
    paddingTop: theme.spacing.unit * 3,
  },
  buttonContainer: {
    paddingTop: theme.spacing.unit * 2,
  },
});

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

class CheckoutPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      processing: false,
      error: false,
      success: false,
      message: undefined,
      expanded: false,
      cardholderName: '',
      paymentIntent: undefined,
      paymentMethod: undefined,
      paymentMethods: [],
      savePayment: false,
    };
  }

  /*
    Event handler functions
  */

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({ processing: true });

    let paymentMethod;

    // create customer if needed
    if (!this.props.auth.customerId) {
      await this.createCustomer();
    }

    if (!this.state.paymentMethod) {
      // create payment method if no saved method was selected
      paymentMethod = await this.createPaymentMethod();
    } else {
      // use saved payment method
      paymentMethod = {
        id: this.state.paymentMethod,
      };
    }

    if (paymentMethod) {
      if (this.props.product.product_type === 'Subscription') {
        // subscriptions are charged to user's default payment method
        // so we set the method to match what was provided in the form
        await this.setDefaultMethod(paymentMethod);
      } else {
        // apply payment method to payment intent
        await this.applyMethodToIntent(paymentMethod);
      }

      // save payment method if customer requests
      if (this.state.savePayment) {
        await this.savePaymentMethod(paymentMethod);
      }
    } else {
      // do not proceed if we do not have a valid payment method
      this.setState({
        processing: false,
      });
      return;
    }

    // confirm payment
    if (this.props.product.product_type === 'Subscription') {
      try {
        const complete = await this.completePurchase();
        if (!complete) {
          this.setState({ processing: false });
          return;
        }
      } catch (err) {
        this.handleError(err);
        this.setState({
          processing: false,
        });
        return;
      }
    } else {
      try {
        const res = await this.props.stripe.confirmCardPayment(
          this.state.paymentIntent.client_secret
        );

        // error handling
        if (res.paymentIntent.status === 'succeeded') {
          await this.completePurchase();
        } else if (res.paymentIntent.status === 'requires_payment_method') {
          // payment did not process properly
          throw new Error('Could not process payment, please try again.');
        } else {
          // all other errors
          if (res.error) {
            throw new Error(res.error.message);
          }
          throw new Error('Unknown error occured.');
        }
      } catch (err) {
        this.handleError(err);
        this.setState({
          processing: false,
        });
        return;
      }
    }

    // remove payment method if user did not request it be added
    if (!this.state.savePayment && this.state.expanded === 'New') {
      this.removePaymentMethod(paymentMethod);
    }

    this.props.history.push({
      pathname: '/checkout/confirmation',
      state: {
        product: this.props.product,
        redirect: this.props.redirect,
      },
    });
  };

  handleError = (err) => {
    this.setState({
      error: true,
      message: err.message,
    });
  };

  handleSuccess = (message) => {
    this.setState({
      success: true,
      message,
    });
  };

  handleClose = () => {
    this.setState({
      error: false,
      success: false,
    });

    setTimeout(() => {
      this.setState({
        message: undefined,
      });
    }, 1000);
  };

  handleSelectChange = (event) => {
    this.setState({
      paymentMethod: event.target.value,
    });
  };

  handleAccordionChange = (panel) => (e, isExpanded) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };

  /*
    Workflow functions
  */

  setDefaultMethod = async (paymentMethod) => {
    try {
      await axios.post('/api/billing/stripe/set-payment-method', {
        paymentMethod,
      });
    } catch (err) {
      this.handleError(err);
    }
  };

  savePaymentMethod = async (paymentMethod) => {
    try {
      await axios.post('/api/billing/add_credit_card', { paymentMethod });
    } catch (err) {
      this.handleError(err);
    }
  };

  removePaymentMethod = async (paymentMethod) => {
    try {
      await axios.post('/api/billing/remove_credit_card', {
        cardId: paymentMethod.id,
      });
    } catch (err) {
      this.handleError(err);
    }
  };

  createPaymentMethod = async () => {
    const { elements, auth } = this.props;
    const cardNumber = await elements.getElement('cardNumber');
    let paymentMethod;

    try {
      if (!this.state.cardholderName) {
        throw new Error('Your card holder name is incomplete.');
      }

      const res = await this.props.stripe.createPaymentMethod({
        type: 'card',
        card: cardNumber,
        billing_details: {
          name: this.state.cardholderName,
          email: auth.email,
          phone: auth.mobilePhone,
          address: {
            line1: auth.address,
            city: auth.city,
            country: 'CA',
            postal_code: auth.zipCode,
            state: auth.province,
          },
        },
      });

      if (res.error) {
        throw new Error(res.error.message);
      }

      paymentMethod = res.paymentMethod;
    } catch (err) {
      this.handleError(err);
    }

    return paymentMethod;
  };

  applyMethodToIntent = async (paymentMethod) => {
    const { paymentIntent } = this.state;
    try {
      const res = await axios.post(
        '/api/billing/stripe/update/payment-intent',
        {
          paymentIntent,
          paymentMethod,
        }
      );

      await this.setState({
        paymentIntent: res.data,
      });
    } catch (err) {
      this.handleError(err);
    }
  };

  createPaymentIntent = async () => {
    const { product } = this.props;
    try {
      const res = await axios.post(
        '/api/billing/stripe/create/payment-intent',
        {
          product,
        }
      );

      this.setState({ paymentIntent: res.data });
    } catch (err) {
      this.handleError(err);
    }
  };

  createCustomer = async () => {
    let customer;
    try {
      const res = await axios.post('/api/billing/stripe/create/customer');
      customer = res.data;
    } catch (err) {
      this.handleError(err);
    }
    return customer;
  };

  completePurchase = async () => {
    try {
      const res = await axios.post('/api/billing/stripe/complete-purchase', {
        userId: this.props.selectedUser._id,
        product: this.props.product,
        paymentIntent: this.state.paymentIntent || {},
      });
      if (!res.data) return false;
      return true;
    } catch (err) {
      this.handleError(err);
      return false;
    }
  };

  getPaymentMethods = async () => {
    try {
      const methods = await axios.post('/api/billing/get_customer_card_list');
      return methods;
    } catch (err) {
      this.handleError(err);
      return {};
    }
  };

  /*
    Component lifecycle functions
  */

  componentDidMount = async () => {
    // we do not need an intent for subscriptions
    if (this.props.product.product_type !== 'Subscription') {
      this.createPaymentIntent();
    }

    // check if customer has saved payement methods
    if (this.props.auth.customerId) {
      const res = await this.getPaymentMethods();
      if (res.data && res.data.length > 0) {
        this.setState({
          paymentMethods: res.data,
          expanded: 'Saved',
        });
      } else {
        this.setState({
          expanded: 'New',
        });
      }
    }

    // show content
    this.setState({ loading: false });
  };

  render() {
    const { classes, product } = this.props;

    return (
      <Fragment>
        <form onSubmit={this.handleSubmit} className={classes.form}>
          <Typography
            variant="body1"
            color="primary"
            style={{
              marginBottom: '10px',
              borderBottom: '1px solid lightgray',
            }}
          >
            <b>Credit Card Details</b>
          </Typography>

          {this.state.loading ? (
            <Fragment>
              <Grid container justify="center" className={classes.processing}>
                <Grid item>
                  <CircularProgress size={25} color="primary" />
                </Grid>
              </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <Accordion
                expanded={this.state.expanded === 'Saved'}
                onChange={this.handleAccordionChange('Saved')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography color="primary">Use a saved card</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container>
                    <Grid item xs={12}>
                      {this.state.paymentMethods.length > 0 ? (
                        <FormControl fullWidth variant="outlined" size="small">
                          <InputLabel id="saved-cards-lable">
                            Saved Cards
                          </InputLabel>
                          <Select
                            id="saved-cards"
                            labelId="saved-cards-label"
                            label="Saved Cards"
                            onChange={this.handleSelectChange}
                          >
                            <MenuItem value={undefined}>None</MenuItem>
                            {this.state.paymentMethods.map((method, i) => (
                              <MenuItem key={i} value={method.id}>
                                <Grid container xs={12}>
                                  <Grid
                                    item
                                    container
                                    alignItems="center"
                                    xs={2}
                                  >
                                    {method.card.brand === 'discover' && (
                                      <img
                                        src={discoverLogo}
                                        alt="Amex Logo"
                                        width="30px"
                                      />
                                    )}
                                    {method.card.brand === 'amex' && (
                                      <img
                                        src={amexLogo}
                                        alt="Amex Logo"
                                        width="30px"
                                      />
                                    )}
                                    {method.card.brand === 'visa' && (
                                      <img
                                        src={visaLogo}
                                        alt="Visa Logo"
                                        width="30px"
                                      />
                                    )}
                                    {method.card.brand === 'mastercard' && (
                                      <img
                                        src={mastercardLogo}
                                        alt="Mastercard Logo"
                                        width="30px"
                                      />
                                    )}
                                  </Grid>
                                  <Grid
                                    item
                                    container
                                    alignItems="center"
                                    xs={10}
                                  >
                                    <Typography>
                                      Card ending in •••• {method.card.last4}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <Fragment>
                          <Typography variant="body2">
                            You do not have any saved cards.
                          </Typography>
                        </Fragment>
                      )}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={this.state.expanded === 'New'}
                onChange={this.handleAccordionChange('New')}
              >
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography color="primary">Use a new card</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Typography variant="overline" color="primary">
                        CARD HOLDER
                      </Typography>
                      <FormControl fullWidth>
                        <InputBase
                          placeholder="NAME"
                          className={classes.cardField}
                          onChange={(event) =>
                            this.setState({
                              cardholderName: event.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="overline" color="primary">
                        EXP. DATE
                      </Typography>
                      <FormControl fullWidth>
                        <CardExpiryElement
                          className={classes.cardField}
                          style={{
                            base: {
                              '::placeholder': {
                                color: '#a2a4a6',
                              },
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="overline" color="primary">
                        CARD NUMBER
                      </Typography>
                      <FormControl fullWidth>
                        <CardNumberElement
                          className={classes.cardField}
                          style={{
                            base: {
                              '::placeholder': {
                                color: '#a2a4a6',
                              },
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="overline" color="primary">
                        CVC/CVV
                      </Typography>
                      <FormControl fullWidth>
                        <CardCvcElement
                          className={classes.cardField}
                          style={{
                            base: {
                              '::placeholder': {
                                color: '#a2a4a6',
                              },
                            },
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item container alignItems="flex-end" xs={1}>
                      <Tooltip
                        title="This is the 3 or 4 digit number found on the back or front of your card"
                        placement="top"
                      >
                        <IconButton
                          style={{
                            padding: 4,
                            marginLeft: -10,
                            marginBottom: 4,
                          }}
                        >
                          <HelpOutline
                            style={{
                              color: '#9c9ea1',
                              fontSize: '1.5rem',
                            }}
                            onClick={() => this.setState({ openCvcInfo: true })}
                          />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        label="Save this payment method."
                        control={
                          <Checkbox
                            color="primary"
                            size="small"
                            onChange={(event) => {
                              this.setState({
                                savePayment: event.target.checked,
                              });
                            }}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Fragment>
          )}

          <CheckoutPaymentInfo product={product} />
          <Grid
            container
            direction="row"
            justify="center"
            spacing={2}
            className={classes.buttonContainer}
          >
            <Grid item xs={6} sm={4}>
              <Button
                fullWidth
                variant="contained"
                className={classes.cancelButton}
                onClick={() =>
                  this.props.history.push(this.props.location.state.origin)
                }
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} sm={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={
                  this.state.loading ||
                  this.state.processing ||
                  (!this.state.paymentMethod &&
                    (this.state.expanded === 'Saved' ||
                      this.state.expanded === false))
                }
                type="submit"
              >
                Purchase
              </Button>
            </Grid>
          </Grid>
          {this.state.processing && (
            <Grid container justify="center" className={classes.processing}>
              <Grid item>
                <CircularProgress size={25} color="primary" />
              </Grid>
            </Grid>
          )}
        </form>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={this.handleClose}
          open={this.state.error || this.state.success}
          autoHideDuration={5000}
        >
          <Alert severity={this.state.error ? 'error' : 'success'}>
            {this.state.message}
          </Alert>
        </Snackbar>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedUser: state.selectedUser,
  };
}

CheckoutPaymentForm.propTypes = {
  auth: PropTypes.object.isRequired,
  selectedUser: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  redirect: PropTypes.string,
};

const ConnectedCheckoutPaymentForm = connect(mapStateToProps)(
  withRouter(CheckoutPaymentForm)
);

const StyledCheckoutPaymentForm = withStyles(styles)(
  ConnectedCheckoutPaymentForm
);

export default injectStripe(StyledCheckoutPaymentForm);
