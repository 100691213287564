import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  withStyles,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import * as actions from '../../../actions';

const styles = (theme) => ({
  title: {
    marginBottom: '15px',
  },
  tableHeader: {
    padding: '0px 55px 0px 15px',
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  infoTag: {
    color: theme.palette.text.secondary,
  },
});

class CanceledDeliveries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedPanel: '',
      selectedDelivery: '',
      openDialog: false,
      updateValue: '',
    };
  }
  // eslint-disable-next-line class-methods-use-this
  renderStatusChip = (status) => (
    <Chip
      label={status.name}
      style={{
        color: 'white',
        backgroundColor: status.color,
      }}
      size="small"
    />
  );

  renderAccordion() {
    const { classes } = this.props;
    const { expandedPanel } = this.state;
    const array = this.props.deliveries;
    const addDigits = (value) => {
      switch (value.toString().length) {
        case 3:
          return '0';
        case 2:
          return '00';
        default:
          return '000';
      }
    };
    const accordion = [];
    array.forEach((item) => {
      if (item.status && item.status.status === 7) {
        accordion.push(
          <Accordion
            key={item._id}
            id={item._id}
            expanded={expandedPanel === item._id}
            onChange={(event, isExpanded) => {
              this.setState({
                expandedPanel: isExpanded ? item._id : false,
                selectedDelivery: item,
              });
            }}
          >
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Grid item xs={1}>
                <Typography className={classes.accordionHeading} align="center">
                  {addDigits(item.number)}
                  {item.number}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.accordionHeading} align="center">
                  {item.customer.name.first} {item.customer.name.last}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.accordionHeading} align="center">
                  {new Date(item.date).toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography className={classes.accordionHeading} align="center">
                  {new Date(item.dueDate).toLocaleDateString()}
                </Typography>
              </Grid>
              <Grid item xs={2} container justify="center">
                {this.renderStatusChip(item.status)}
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.infoTag}
                  >
                    Patient
                  </Typography>
                  <Typography color="primary">
                    {item.customer.name.first} {item.customer.name.last}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.infoTag}
                  >
                    Phone Number
                  </Typography>
                  <Typography color="primary">{item.phoneNumber}</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.infoTag}
                  >
                    Address
                  </Typography>
                  <Typography color="primary">{item.address}</Typography>
                  <Typography color="primary">
                    {item.city}, {item.province}
                  </Typography>
                  <Typography color="primary">{item.postalCode}</Typography>
                </Grid>
                {item.notes && (
                  <Grid item xs={4}>
                    <Typography
                      variant="body2"
                      color="primary"
                      className={classes.infoTag}
                    >
                      Delivery Notes
                    </Typography>
                    <Typography
                      color="primary"
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {item.notes}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      }
    });
    return accordion.length > 0 ? (
      accordion
    ) : (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{
          color: '#bcbcbc',
          height: '30vh',
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            No canceled deliveries.
          </Typography>
        </Grid>
      </Grid>
    );
  }

  handleDialogClose() {
    this.setState({ openDialog: false });
  }

  async handleSaveStatus() {
    const { selectedDelivery, updateValue } = this.state;
    const result = await axios.post('/api/delivery-status-update', {
      delivery: selectedDelivery._id,
      status: updateValue,
    });
    if (result.data) {
      this.setState({ updateValue: '', expandedPanel: '' });
      this.props.getDeliveriesList();
      this.handleDialogClose();
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} className={classes.title}>
          <Typography variant="h5" color="primary">
            Delivered Prescriptions
          </Typography>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Grid container className={classes.tableHeader}>
            <Grid item xs={1}>
              <Typography variant="subtitle2" align="center">
                No.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" align="center">
                Customer
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" align="center">
                Date
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2" align="center">
                Due
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="subtitle2" align="center">
                Status
              </Typography>
            </Grid>
          </Grid>
          {this.renderAccordion()}
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    deliveries: state.deliveriesList,
  };
}

CanceledDeliveries.propTypes = {
  classes: PropTypes.object.isRequired,
  deliveries: PropTypes.array.isRequired,
  getDeliveriesList: PropTypes.func.isRequired,
};

const ConnectedCanceledDeliveries = connect(
  mapStateToProps,
  actions
)(withRouter(CanceledDeliveries));

export default withStyles(styles)(ConnectedCanceledDeliveries);
