/* eslint-disable default-param-last, func-names */
import { NOTIFICATION_LIST } from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case NOTIFICATION_LIST:
      return action.payload;
    default:
      return state;
  }
}
