/* eslint-disable default-param-last, func-names */
import { DRUG_INTERACTION_CHECK } from '../actions/types';

export default function (state = false, action) {
  switch (action.type) {
    case DRUG_INTERACTION_CHECK:
      return action.payload;
    default:
      return state;
  }
}
