import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  Grid,
  Typography,
  Tabs,
  Tab,
  withStyles,
} from '@material-ui/core';
import * as actions from '../../../actions';
import PharmacyProfile from './PharmacyProfile';
import PharmacyPaymentHistory from './PharmacyPaymentHistory';

const styles = () => ({
  sectionContent: {
    height: '82vh',
  },
  paper: {
    height: '100%',
    padding: '30px',
    overflow: 'auto',
  },
  sectionTab: {
    margin: 'auto 10px',
    borderRight: 'solid lightgray 1px',
  },
});

class SettingsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'Profile',
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  renderTabContent() {
    const { selectedTab } = this.state;
    switch (selectedTab) {
      case 'Payment':
        return <PharmacyPaymentHistory />;
      default:
        return <PharmacyProfile />;
    }
  }

  render() {
    const { selectedTab } = this.state;
    const { classes } = this.props;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper>
            <Tabs
              value={selectedTab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Grid item xs={1} className={classes.sectionTab}>
                <Typography
                  color="primary"
                  align="center"
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                  }}
                >
                  Settings
                </Typography>
              </Grid>
              <Tab label="Profile" value="Profile" />
              <Tab label="Payment" value="Payment" />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12} className={classes.sectionContent}>
          {this.renderTabContent()}
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

SettingsSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ConnectedSettingsSection = connect(
  mapStateToProps,
  actions
)(withRouter(SettingsSection));

export default withStyles(styles)(ConnectedSettingsSection);
