import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const imgExtensions = ['.jpg', '.jpeg', '.png'];

function PhotosCard(props) {
  return (
    <Grid
      container
      item
      xs={12}
      spacing={1}
      alignItems="center"
      style={{ height: '100%' }}
    >
      {props.editNoteParams.fileList &&
        props.editNoteParams.fileList.length > 0 &&
        props.editNoteParams.fileList.map(
          (file) =>
            imgExtensions.includes(file.slice(file.lastIndexOf('.'))) && (
              <Grid item xs={12} sm={6} md={4} key={file}>
                <img src={file} alt="file" style={{ width: '100%' }} />
              </Grid>
            )
        )}
    </Grid>
  );
}

PhotosCard.propTypes = {
  editNoteParams: PropTypes.object.isRequired,
};

export default PhotosCard;
