import React from 'react';
import PropTypes from 'prop-types';
import {
  Assignment,
  LocalPharmacy,
  Description,
  Person,
  CastForEducation,
  Videocam,
  MeetingRoom,
} from '@material-ui/icons';

const ToolbarIcons = (props) => {
  const { name } = props;
  let component;
  switch (name) {
    case 'videochat':
      component = <Videocam {...props} />;
      break;
    case 'doctor-notes':
      component = <Assignment {...props} />;
      break;
    case 'prescription-button':
      component = <LocalPharmacy {...props} />;
      break;
    case 'general-sicknote':
      component = <Description {...props} />;
      break;
    case 'school-sicknote':
      component = <CastForEducation {...props} />;
      break;
    case 'patient-info-button':
      component = <Person {...props} />;
      break;
    case 'patient-apt-history':
      component = <MeetingRoom {...props} />;
      break;
    default:
      component = <Videocam {...props} />;
      break;
  }
  return component;
};

ToolbarIcons.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ToolbarIcons;
