import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Typography, Chip, withStyles } from '@material-ui/core';
import * as actions from '../../../actions';

const styles = () => ({
  container: {
    marginTop: '3%',
  },
  tableHeader: {
    marginTop: '10px',
    padding: '5px',
    borderBottom: 'solid 1px lightgray',
  },
  deliveryList: {
    height: '40vh',
    overflow: 'auto',
  },
});

class PendingDeliveriesCard extends Component {
  // eslint-disable-next-line class-methods-use-this
  renderStatusChip = (status) => (
    <Chip
      label={status.name}
      style={{
        color: 'white',
        backgroundColor: status.color,
      }}
      size="small"
    />
  );

  renderDeliveries = (deliveries) => {
    const deliveriesArray = [];
    deliveries.forEach((delivery) => {
      const date = new Date(delivery.date).toLocaleDateString('en-CA');
      const dueDate = new Date(delivery.dueDate).toLocaleDateString('en-CA');
      if (delivery.status && delivery.status.status === 6) {
        deliveriesArray.push(
          <Grid
            container
            style={{
              padding: '5px 0px',
            }}
          >
            <Grid item xs={2}>
              <Typography align="center">{delivery.number}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography>
                {delivery.customer.name.first} {delivery.customer.name.last}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>{date}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>{dueDate}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="subtitle2">
                {this.renderStatusChip(delivery.status)}
              </Typography>
            </Grid>
          </Grid>
        );
      }
    });
    return deliveriesArray;
  };

  render() {
    const { classes, deliveries } = this.props;

    return (
      <Grid item xs={12}>
        <Grid container className={classes.tableHeader}>
          <Grid item xs={2}>
            <Typography variant="subtitle2" align="center">
              #
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">Customer</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">Date</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">Due</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">Status</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.deliveryList}>
          {deliveries ? (
            this.renderDeliveries(deliveries)
          ) : (
            <Grid
              container
              style={{ height: '100%' }}
              alignItems="center"
              justify="center"
            >
              <Typography
                variant="h6"
                align="center"
                style={{ color: 'lightgray' }}
              >
                No pending deliveries.
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    deliveries: state.deliveriesList,
  };
}

PendingDeliveriesCard.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deliveries: PropTypes.array.isRequired,
};

const ConnectedPendingDeliveriesCard = connect(
  mapStateToProps,
  actions
)(withRouter(PendingDeliveriesCard));

export default withStyles(styles)(ConnectedPendingDeliveriesCard);
