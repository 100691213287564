import React, { Component, Fragment } from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import axios from 'axios';
import toast from 'react-hot-toast';
import {
  Button,
  CssBaseline,
  Paper,
  TextField,
  Typography,
  FormControl,
  Snackbar,
  Grid,
  withStyles,
} from '@material-ui/core';
import PasswordField from '../../registration/RegistrationPasswordField';
import * as actions from '../../../actions';
import logoMain from '../../SnapMedLogoMain.png';
import logoMainRetina from '../../SnapMedLogoMainRetina.png';

const styles = (theme) => ({
  layout: {
    display: 'block',
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      maxWidth: 450,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  form: {
    width: '100%',
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
});

const usernameField = ({
  input,
  label,
  name,
  type,
  meta: { touched, error },
}) => (
  <TextField
    {...input}
    variant="outlined"
    size="small"
    fullWidth
    label={label}
    type={type}
    id={name}
    error={touched && error}
    helperText={touched && error}
    margin="normal"
    inputProps={{
      autoCapitalize: 'none',
    }}
    onChange={(event) => input.onChange(event.target.value.toLowerCase())}
  />
);

const passwordField = ({ input, label, name, meta: { touched, error } }) => (
  <FormControl fullWidth error={touched && error} margin="normal">
    <PasswordField
      input={input}
      name={name}
      label={label}
      touched={touched}
      error={error}
    />
  </FormControl>
);

const authPinField = ({ input, id, label, meta: { touched, error } }) => (
  <FormControl fullWidth error={touched && error}>
    <InputMask mask="999999" {...input} onChange={input.onChange} maskChar="">
      {(inputProps) => (
        <TextField
          {...inputProps}
          autoFocus
          autoComplete="off"
          disableUnderline
          type="text"
          label={label}
          id={id}
          variant="outlined"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    </InputMask>
  </FormControl>
);

class DoctorLoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordIsMasked: true,
      openSnackbar: false,
      verified: false,
      message: false,
      recipient: '',
      authToken: '',
    };
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.idleLogout === true) {
        this.setState({ openSnackbar: true });
      }
    }, 500);
  }

  handleClose = () => {
    // clear all snackbar open states
    this.setState({
      openSnackbar: false,
      success: false,
      error: false,
    });

    // delay clearing message for smoother animation
    setTimeout(() => {
      this.setState({
        message: false,
      });
    }, 1000);
  };

  async handleVerification(values) {
    const res = await axios.post('/api/auth/doctor/two-factor-auth', values);

    if (res.data) {
      const method = res.data.method === 'sms' ? 'SMS' : 'Email';

      toast.success(`${method} with PIN was sent.`, {
        position: 'bottom-center',
        duration: 4000,
      });
      this.setState({
        verified: true,
        recipient: res.data.recipient,
        authToken: res.data.token,
      });
      this.props.reset();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async handleResendPin() {
    const res = await axios.post('/api/auth/doctor/resend-pin', {
      token: this.state.authToken,
    });

    if (res.data) {
      const method = res.data === 'sms' ? 'SMS' : 'Email';

      toast.success(`${method} with PIN was resent.`, {
        position: 'bottom-center',
        duration: 4000,
      });
    }
  }

  handleLogin = (values) => {
    const newValues = { ...values, token: this.state.authToken };
    this.props.doctorLogin(newValues, this.props.history);
  };

  renderError() {
    return <div>{this.props.auth}</div>;
  }

  render() {
    const { handleSubmit, classes, authPin } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <img
              alt="SnapMed Logo"
              src={logoMain}
              srcSet={`${logoMainRetina} 2x,`}
            />
            {!this.state.verified ? (
              <form
                className={classes.form}
                onSubmit={handleSubmit((values) =>
                  this.handleVerification(values)
                )}
              >
                <Fragment>
                  <Field
                    name="username"
                    label="Doctor Username"
                    type="email"
                    component={usernameField}
                  />
                  <Field
                    name="password"
                    label="Password"
                    type="password"
                    classes={this.props.classes}
                    component={passwordField}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Sign in
                  </Button>
                </Fragment>
              </form>
            ) : (
              <form
                className={classes.form}
                onSubmit={handleSubmit(this.handleLogin)}
              >
                <Fragment>
                  <Grid item xs={12} style={{ margin: '15px 0px' }}>
                    <Typography color="primary">
                      We have sent a 6 digit PIN to{' '}
                      <b>{this.state.recipient}</b>.
                    </Typography>
                  </Grid>
                  <Field
                    name="authPin"
                    label="Enter PIN"
                    type="text"
                    component={authPinField}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={!authPin || (authPin && authPin.length < 6)}
                  >
                    Submit
                  </Button>
                  <Grid
                    item
                    xs={12}
                    style={{ marginTop: '10px' }}
                    container
                    justify="center"
                    spacing={1}
                  >
                    <Grid item xs={12} container justify="center">
                      <Typography color="primary">
                        {`Didn't get the PIN?`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container justify="center">
                      <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={() => this.handleResendPin()}
                      >
                        Resend PIN
                      </Button>
                    </Grid>
                  </Grid>
                </Fragment>
              </form>
            )}
            {!this.props.auth ? this.renderError() : <div />}
          </Paper>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={this.state.openSnackbar}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={'You have been logged out.'}
          />
        </main>
      </Fragment>
    );
  }
}

const selector = formValueSelector('doctorloginForm');

function mapStateToProps(state) {
  return {
    auth: state.auth,
    idleLogout: state.idleLogout,
    email: selector(state, 'username'),
    authPin: selector(state, 'authPin'),
  };
}

function validate(values) {
  const errors = {};

  if (!values.username) {
    errors.username = 'Field required';
  }
  if (!values.password) {
    errors.password = 'Field required';
  }

  return errors;
}

DoctorLoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  auth: PropTypes.string,
  idleLogout: PropTypes.bool,
  email: PropTypes.string,
  authPin: PropTypes.string,
  doctorLogin: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
};

const DoctorLoginFormRedux = reduxForm({
  validate,
  form: 'doctorloginForm',
  destroyOnUnmount: true,
})(DoctorLoginForm);

const ConndectedDoctorLoginForm = connect(
  mapStateToProps,
  actions
)(withRouter(DoctorLoginFormRedux));

export default withStyles(styles)(ConndectedDoctorLoginForm);
