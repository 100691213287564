import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PharmacyDashboard from '../pharmacies/dashboard/PharmacyDashboard';

const PharmacyRoutes = () => (
  <Switch>
    <Route exact path="/" component={PharmacyDashboard} />
    <Route exact path="/pharmacy-dashboard" component={PharmacyDashboard} />
    <Route exact path="*" component={PharmacyDashboard} />
  </Switch>
);

export default PharmacyRoutes;
