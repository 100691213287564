import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Paper,
  Grid,
  Typography,
  Tabs,
  Tab,
  withStyles,
} from '@material-ui/core';
import * as actions from '../../../actions';
import ActiveDeliveries from './ActiveDeliveries';
import DeliveredPrescriptions from './DeliveredPrescriptions';
import PendingDeliveries from './PendingDeliveries';
import CanceledDeliveries from './CanceledDeliveries';

const styles = () => ({
  sectionContent: {
    height: '80vh',
  },
  paper: {
    height: '100%',
    padding: '30px',
    overflow: 'auto',
  },
  sectionTab: {
    margin: 'auto 10px',
    borderRight: 'solid lightgray 1px',
  },
});

class DeliveriesSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 'Active',
    };
  }

  handleTabChange = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  };

  renderTabContent() {
    const { selectedTab } = this.state;
    switch (selectedTab) {
      case 'Delivered':
        return <DeliveredPrescriptions />;
      case 'Pending':
        return <PendingDeliveries />;
      case 'Canceled':
        return <CanceledDeliveries />;
      default:
        return <ActiveDeliveries />;
    }
  }

  render() {
    const { selectedTab } = this.state;
    const { classes } = this.props;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper>
            <Tabs
              value={selectedTab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Grid item xs={1} className={classes.sectionTab}>
                <Typography
                  color="primary"
                  align="center"
                  style={{
                    color: 'rgba(0, 0, 0, 0.54)',
                  }}
                >
                  Deliveries
                </Typography>
              </Grid>
              <Tab label="Active" value="Active" />
              <Tab label="Pending" value="Pending" />
              <Tab label="Delivered" value="Delivered" />
              <Tab label="Canceled" value="Canceled" />
            </Tabs>
          </Paper>
        </Grid>
        <Grid item xs={12} className={classes.sectionContent}>
          <Paper className={classes.paper}>{this.renderTabContent()}</Paper>
        </Grid>
      </Grid>
    );
  }
}

DeliveriesSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ConnectedDeliveriesSection = connect(
  null,
  actions
)(withRouter(DeliveriesSection));

export default withStyles(styles)(ConnectedDeliveriesSection);
