import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { FiberManualRecord } from '@material-ui/icons';
import { useDoctorList } from '../../../contexts/DoctorListContext';
import { AvailableDoctorInfoDialog } from './AvailableDoctorInfoDialog';

export function AvailableDoctorCount({ className }) {
  const [anchor, setAnchor] = useState();
  const [open, setOpen] = useState(false);

  const { doctorList } = useDoctorList();

  function handleClick(event) {
    event.preventDefault();
    setAnchor(event.currentTarget);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Typography
        variant="body1"
        color="secondary"
        style={{ textAlign: 'right' }}
      >
        <Link to="#" onClick={handleClick} className={className}>
          Available doctors:
        </Link>{' '}
        <strong>{doctorList.length}</strong>
      </Typography>

      <AvailableDoctorsList open={open} anchor={anchor} onClose={handleClose} />
    </>
  );
}

function AvailableDoctorsList({ open, anchor, onClose }) {
  const { doctorList } = useDoctorList();

  const [openDialog, setOpenDialog] = useState(false);
  const [doctorInfo, setDoctorInfo] = useState();

  function showDoctorInfo(doctor) {
    onClose();
    setDoctorInfo(doctor);
    setOpenDialog(true);
  }

  return (
    <>
      <Menu
        id="doctor-in-session-menu"
        anchorEl={anchor}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        keepMounted
        open={open}
        onClose={onClose}
      >
        {doctorList.map((doctor) => (
          <MenuItem onClick={() => showDoctorInfo(doctor)} key={doctor._id}>
            <ListItemText
              primary={`Dr. ${doctor.name.first} ${doctor.name.last}`}
            />
            <ListItemIcon
              style={{
                color: doctor.inSession ? 'red' : '#2ac108',
                marginLeft: '20px',
                minWidth: 20,
              }}
            >
              <FiberManualRecord fontSize="small" />
            </ListItemIcon>
          </MenuItem>
        ))}
      </Menu>
      <AvailableDoctorInfoDialog
        open={openDialog}
        doctor={doctorInfo}
        onClose={() => setOpenDialog(false)}
      />
    </>
  );
}

AvailableDoctorCount.propTypes = {
  className: PropTypes.string,
};

AvailableDoctorsList.propTypes = {
  open: PropTypes.bool,
  anchor: PropTypes.object,
  onClose: PropTypes.func,
};
