import axios from 'axios';

class LoginService {
  // eslint-disable-next-line class-methods-use-this
  forgotPassword(email) {
    return axios
      .post('/api/auth/forgot-password', { email })
      .then((response) => response.data)
      .catch((error) => error.response.data);
  }

  // eslint-disable-next-line class-methods-use-this
  forgotPharmacyPassword(email) {
    return axios
      .post('/api/auth/forgot-pharmacy-password', { email })
      .then((response) => response.data)
      .catch((error) => error.response.data);
  }
}

export default LoginService;
