import _ from 'lodash';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Button,
  CssBaseline,
  Paper,
  Typography,
  Grid,
  withStyles,
} from '@material-ui/core';
import { ArrowBackIos, Check } from '@material-ui/icons';
import * as actions from '../../../actions';

const styles = (theme) => ({
  submit: {
    marginTop: 40,
    marginBottom: theme.spacing.unit * 3,
    float: 'right',
  },
  previous: {
    marginTop: 40,
    marginBottom: theme.spacing.unit * 3,
    float: 'left',
  },
});

const WizardFormReview = ({
  previousStep,
  formValues,
  onSubmit,
  user,
  medicalAllergiesArray,
  otherAllergiesArray,
  medicalProceduresArray,
  currentMedicalConditionArray,
  currentMedicationArray,
  classes,
}) => {
  const reviewMedicalData = (medicalArray, type) =>
    _.map(medicalArray, (medicalData) => {
      if (
        formValues[type.concat(medicalData.name)] &&
        formValues[type.concat(medicalData.name)].name === medicalData.name
      ) {
        return (
          <div key={medicalData.name}>
            {' '}
            <label>{medicalData.name} </label>
          </div>
        );
      }
      return null;
    });

  return (
    <Fragment>
      <CssBaseline />
      <main
        style={{
          width: '90%',
          marginLeft: 'auto',
          marginRight: 'auto',
          maxWidth: 600,
        }}
      >
        <Paper
          style={{
            borderRadius: '7px',
            display: 'flex',
            flexGrow: 1,
            padding: '1.5rem',
          }}
        >
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Typography
                style={{ textAlign: 'center' }}
                variant="h4"
                color="primary"
              >
                Please review and confirm your entries.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                Allergies:
              </Typography>

              <Typography variant="subtitle1">
                {reviewMedicalData(medicalAllergiesArray, 'medical_allergy_')}
              </Typography>
              {formValues.medical_allergies_other_field && (
                <Grid item>
                  <Typography variant="subtitle2" color="primary">
                    More Medical Allergies:
                  </Typography>
                  <Typography variant="subtitle1">
                    {formValues.medical_allergies_other_field}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                Other Allergies:
              </Typography>
              <Typography variant="subtitle1">
                {reviewMedicalData(otherAllergiesArray, 'other_allergy_')}
              </Typography>
              {formValues.other_allergies_other_field && (
                <Grid item>
                  <Typography variant="subtitle2" color="primary">
                    More Allergies:
                  </Typography>
                  <Typography variant="subtitle1">
                    {formValues.other_allergies_other_field}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                Current Medication:
              </Typography>
              <Typography variant="subtitle1">
                {reviewMedicalData(
                  currentMedicationArray,
                  'current_medication_'
                )}
              </Typography>
              {formValues.current_medication_other_field && (
                <Grid item>
                  <Typography variant="subtitle2" color="primary">
                    Other Medication:
                  </Typography>
                  <Typography variant="subtitle1">
                    {formValues.current_medication_other_field}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" color="primary">
                Medical Conditions:
              </Typography>
              <Typography variant="subtitle1">
                {reviewMedicalData(
                  currentMedicalConditionArray,
                  'current_medical_condition_'
                )}
              </Typography>
              {formValues.current_medical_condition_other_field && (
                <Grid item>
                  <Typography variant="subtitle2" color="primary">
                    Other Medical Conditions:
                  </Typography>
                  <Typography variant="subtitle1">
                    {formValues.current_medical_condition_other_field}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                Past Medical Procedures:
              </Typography>
              <Typography variant="subtitle1">
                {reviewMedicalData(
                  medicalProceduresArray,
                  'medical_procedure_'
                )}
              </Typography>
              {formValues.medical_procedures_other_field && (
                <Grid item>
                  <Typography variant="subtitle2" color="primary">
                    Other Medical Procedures:
                  </Typography>
                  <Typography variant="subtitle1">
                    {formValues.medical_procedures_other_field}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} container>
              <Grid item xs={12}>
                <Typography variant="h6" color="primary">
                  Substances:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body2">Tobacco?</Typography>
                <Typography variant="subtitle1">
                  {formValues.isTobaccoSmoker === 'isTobaccoSmoker_yes'
                    ? 'Yes'
                    : 'No'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body2">Alcohol?</Typography>
                <Typography variant="subtitle1">
                  {formValues.isAlcoholConsumer === 'isAlcoholConsumer_yes'
                    ? 'Yes'
                    : 'No'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="body2">Any other substance?</Typography>
                <Typography variant="subtitle1">
                  {formValues.isSubstanceUser === 'isSubstanceUser_yes'
                    ? formValues.substanceInputField
                    : 'No'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                startIcon={<ArrowBackIos />}
                color="primary"
                className={classes.previous}
                onClick={previousStep}
                variant="outlined"
              >
                Previous
              </Button>
              <Button
                endIcon={<Check />}
                className={classes.submit}
                variant="contained"
                color="primary"
                onClick={() => {
                  const values = { ...formValues, id: user };
                  onSubmit(values);
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </main>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    formValues: state.form.wizard.values,
    medicalAllergiesArray: state.medicalData.medicalAllergiesArray,
    otherAllergiesArray: state.medicalData.otherAllergiesArray,
    medicalProceduresArray: state.medicalData.medicalProceduresArray,
    currentMedicalConditionArray:
      state.medicalData.currentMedicalConditionArray,
    currentMedicationArray: state.medicalData.currentMedicationArray,
  };
}

WizardFormReview.propTypes = {
  classes: PropTypes.object.isRequired,
  previousStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formValues: PropTypes.object.isRequired,
  user: PropTypes.string.isRequired,
  medicalAllergiesArray: PropTypes.array.isRequired,
  otherAllergiesArray: PropTypes.array.isRequired,
  medicalProceduresArray: PropTypes.array.isRequired,
  currentMedicalConditionArray: PropTypes.array.isRequired,
  currentMedicationArray: PropTypes.array.isRequired,
};

export default withStyles(styles)(
  connect(mapStateToProps, actions)(withRouter(WizardFormReview))
);
