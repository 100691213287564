/* eslint-disable default-param-last, func-names */
import { PHOTO_ID_VALIDATION_CHECK } from '../actions/types';

export default function (state = false, action) {
  switch (action.type) {
    case PHOTO_ID_VALIDATION_CHECK:
      return action.payload;
    default:
      return state;
  }
}
