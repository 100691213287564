import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Grid, Step, Stepper, StepLabel } from '@material-ui/core';
import AboutYouForm from '../aboutyou/AboutYouForm';
import MedicalProcedureForm from '../medicalProcedures/MedicalProcedureForm';
import CurrentMedicationForm from '../medication/CurrentMedicationForm';
import CurrentMedicalConditionForm from '../medicalCondition/CurrentMedicalConditionForm';
import UploadOhipCard from '../uploadOhipCard/UploadOhipCard';
import WizardProfileReview from './WizardProfileReview';
import * as actions from '../../../actions';

const pagesNoCard = [
  'About You',
  'Current Medication',
  'Medical Conditions',
  'Medical Procedures',
  'Review and Confirm',
];

const pagesWithCard = [
  'About You',
  'Current Medication',
  'Medical Conditions',
  'Medical Procedures',
  'Upload OHIP Card',
  'Review and Confirm',
];

class WizardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderOhipCard: false,
      activeStep: 0,
      pages: [],
      step: 'About You',
    };
  }
  componentDidMount() {
    if (this.props.auth.province === 'Ontario') {
      this.setState({ renderOhipCard: true, pages: pagesWithCard });
    } else {
      this.setState({ pages: pagesNoCard });
    }
  }

  handleStepChange = (step) => {
    this.setState({ step, activeStep: this.state.pages.indexOf(step) });
  };

  render() {
    const { renderOhipCard, step, activeStep, pages } = this.state;
    const { name } = this.props.match.params; // TODO: change 'name' to 'id' in routing.

    const onSubmit = (values) => {
      // convert medical info to array of strings containing name
      let medicalAllergies = [];
      let currentMedicalCondition = [];
      let currentMedication = [];
      let otherAllergies = [];
      let medicalProcedures = [];
      const id = name;
      Object.entries(values).forEach(([key, val]) => {
        if (key.includes('medical_allergy_') && val.name) {
          medicalAllergies = [val.name, ...medicalAllergies];
        } else if (key.includes('current_medical_condition_') && val.name) {
          currentMedicalCondition = [val.name, ...currentMedicalCondition];
        } else if (key.includes('current_medication_') && val.name) {
          currentMedication = [val.name, ...currentMedication];
        } else if (key.includes('other_allergy_') && val.name) {
          otherAllergies = [val.name, ...otherAllergies];
        } else if (key.includes('medical_procedure_') && val.name) {
          medicalProcedures = [val.name, ...medicalProcedures];
        }
      });

      // convert Y/N question values to boolean
      const isTobaccoSmoker = values.isTobaccoSmoker === 'isTobaccoSmoker_yes';
      const isSubstanceUser = values.isSubstanceUser === 'isSubstanceUser_yes';
      const isAlcoholConsumer =
        values.isAlcoholConsumer === 'isAlcoholConsumer_yes';

      // if substance user, defined substance
      let substance;
      if (isSubstanceUser) substance = values.substanceInputField;

      // get other values if option was selected
      let currentMedicalConditionOtherField;
      let currentMedicationOtherField;
      let medicalAllergiesOtherField;
      let medicalProceduresOtherField;
      let otherAllergiesOtherField;
      if (values.medical_allergy_Other)
        medicalAllergiesOtherField = values.medical_allergies_other_field;
      if (values.other_allergy_Other)
        otherAllergiesOtherField = values.other_allergies_other_field;
      if (values.current_medication_Other)
        currentMedicationOtherField = values.current_medication_other_field;
      if (values.current_medical_condition_Other)
        currentMedicalConditionOtherField =
          values.current_medical_condition_other_field;
      if (values.medical_procedure_Other)
        medicalProceduresOtherField = values.medical_procedures_other_field;

      // submit to server
      this.props.submitWizard(
        {
          medicalAllergies,
          currentMedicalCondition,
          currentMedication,
          otherAllergies,
          medicalProcedures,
          isAlcoholConsumer,
          isSubstanceUser,
          isTobaccoSmoker,
          substance,
          currentMedicalConditionOtherField,
          currentMedicationOtherField,
          medicalAllergiesOtherField,
          medicalProceduresOtherField,
          otherAllergiesOtherField,
          id,
        },
        this.props.history
      );
    };

    return (
      <div>
        <Grid container direction="row" xs={12}>
          <Grid
            item
            xs={12}
            style={{
              width: '70vw',
            }}
          >
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              style={{
                backgroundColor: '#caebf2',
                marginBottom: 10,
                maxWidth: '30rem',
                margin: 'auto',
              }}
            >
              {pages.map((label) => (
                <Step key={label}>
                  <StepLabel></StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>

        <div>
          {step === 'About You' && (
            <AboutYouForm
              onSubmit={() => this.handleStepChange('Current Medication')}
            />
          )}
          {step === 'Current Medication' && (
            <CurrentMedicationForm
              previousStep={() => this.handleStepChange('About You')}
              onSubmit={() => this.handleStepChange('Medical Conditions')}
            />
          )}
          {step === 'Medical Conditions' && (
            <CurrentMedicalConditionForm
              previousStep={() => this.handleStepChange('Current Medication')}
              onSubmit={() => this.handleStepChange('Medical Procedures')}
            />
          )}
          {step === 'Medical Procedures' && (
            <MedicalProcedureForm
              previousStep={() => this.handleStepChange('Medical Conditions')}
              onSubmit={() => {
                if (renderOhipCard) {
                  this.handleStepChange('Upload OHIP Card');
                } else {
                  this.handleStepChange('Review and Confirm');
                }
              }}
            />
          )}
          {step === 'Upload OHIP Card' && (
            <UploadOhipCard
              previousStep={() => this.handleStepChange('Medical Procedures')}
              skip={() => this.handleStepChange('Review and Confirm')}
            />
          )}
          {step === 'Review and Confirm' && (
            <WizardProfileReview
              previousStep={() => {
                if (renderOhipCard) {
                  this.handleStepChange('Upload OHIP Card');
                } else {
                  this.handleStepChange('Medical Procedures');
                }
              }}
              user={name}
              onSubmit={onSubmit}
            />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    form: state.form,
    auth: state.auth,
  };
}

WizardForm.propTypes = {
  auth: PropTypes.object.isRequired,
  submitWizard: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const ConnectedWizardForm = connect(
  mapStateToProps,
  actions
)(withRouter(WizardForm));

const WizardFormRedux = reduxForm({
  // validate: validate,
  form: 'wizard',
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(ConnectedWizardForm);

export default WizardFormRedux;
