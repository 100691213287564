import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  CssBaseline,
  Button,
  Paper,
  Typography,
  Grid,
  withStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
});

class WizardProfileCompletionAcknowledgement extends Component {
  componentDidMount() {
    this.props.selectedUserChange({ id: this.props.auth._id });
    this.props.fetchUser();
  }

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Grid container spacing={24} justify="center">
              <div className="section" />
              <div className="section" />
              <Typography
                variant="subtitle1"
                color="primary"
                style={{ marginBottom: 20 }}
              >
                Congratulations! Your profile is now complete. Click on the
                button below to continue. Thank you.
              </Typography>
              <div className="section" />
              <div className="section" />
              <Button variant="contained" color="primary">
                <Link
                  style={{ color: 'white', textDecoration: 'none' }}
                  to="/dashboard"
                >
                  Continue
                </Link>
              </Button>
            </Grid>
          </Paper>
        </main>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

WizardProfileCompletionAcknowledgement.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  selectedUserChange: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
};

const ConnectedWizardProfileCompletionAcknowledgement = connect(
  mapStateToProps,
  actions
)(withStyles(styles)(WizardProfileCompletionAcknowledgement));

export default ConnectedWizardProfileCompletionAcknowledgement;
