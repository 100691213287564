import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  Slide,
  withStyles,
} from '@material-ui/core';
import 'react-quill/dist/quill.snow.css';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
});

const modules = {
  toolbar: [[{ header: [1, 2, false] }], ['bold', 'italic', 'underline']],
};

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'list',
  'bullet',
  'indent',
];

const Quill = ({ input }) => (
  <ReactQuill
    formats={formats}
    modules={modules}
    style={{
      backgroundColor: 'white',
      overflow: 'hidden',
      minHeight: 200,
      height: '425px',
      width: '100%',
      borderBottom: '1px solid #B0B0B0',
    }}
    {...input}
  />
);

const NoteLaterCheck = ({ input, name }) => (
  <FormControl>
    <FormControlLabel
      control={
        <Checkbox
          {...input}
          id={name}
          checked={input.value}
          value="finish later"
          color="primary"
        />
      }
    />
    {}
  </FormControl>
);

class DoctorAppointmentNotes extends Component {
  // eslint-disable-next-line class-methods-use-this
  renderFields(name, type, label, key) {
    const options = {
      quill: Quill,
      later: NoteLaterCheck,
    };

    const component = options[key];

    if (component) {
      return (
        <Field
          name={name}
          id={name}
          type={type}
          label={label}
          component={component}
        />
      );
    }

    return null;
  }

  render() {
    const { classes } = this.props;
    return (
      <Slide direction="right" in={true}>
        <main className={classes.layout}>
          <Grid
            container
            id="actualNote"
            direction="row"
            justify="center"
            alignItems="center"
            style={{
              padding: '1rem',
            }}
          >
            <Typography variant="h5" color="primary">
              Appointment Notes
            </Typography>
            <Typography
              variant="caption"
              style={{ marginLeft: '5%', marginBottom: '2%' }}
            >
              Please use the space below to complete your notes. If you would
              like to finish them at a more convenient time, please ensure you{' '}
              <strong>DO NOT</strong> check off the checkbox located at the
              bottom
            </Typography>
            <Grid item xs={12} id="doctorNotes">
              {this.renderFields('doctorNote', 'text', '', 'quill')}
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              spacing={1}
            >
              <Grid item xs={11}>
                <Typography variant="subtitle2" style={{ textAlign: 'right' }}>
                  Finalize Appointment Notes
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  textAlign: 'center',
                }}
              >
                {this.renderFields(
                  'doctor_finalized_notes',
                  'input',
                  'label',
                  'later',
                  this.props.noteState
                )}
              </Grid>
            </Grid>
          </Grid>
        </main>
      </Slide>
    );
  }
}
const selector = formValueSelector('noteForm');

function mapStateToProps(state) {
  return {
    doctorNote: selector(state, 'doctorNote'),
  };
}

DoctorAppointmentNotes.propTypes = {
  classes: PropTypes.object.isRequired,
  noteState: PropTypes.bool.isRequired,
};

NoteLaterCheck.propTypes = {
  input: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

Quill.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

const DoctorAppointmentNotesRedux = reduxForm({
  // validate,
  form: 'noteForm',
  destroyOnUnmount: false,
})(DoctorAppointmentNotes);

const ConnectedDoctorAppointmentNotes = connect(mapStateToProps)(
  DoctorAppointmentNotesRedux
);

export default withStyles(styles)(ConnectedDoctorAppointmentNotes);
