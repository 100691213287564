import React, { Component, Fragment } from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import {
  Button,
  Paper,
  TextField,
  Typography,
  IconButton,
  FormControl,
  CircularProgress,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fade,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import loginFormFields from './loginFormFields';
import PasswordField from '../registration/RegistrationPasswordField';
import logoMain from '../SnapMedLogoMain.png';
import logoMainRetina from '../SnapMedLogoMainRetina.png';
import '../../styles/loginFormStyles.css';
import { REACT_APP_VERSION } from '../../constants';
import AuthPinForm from './AuthPinForm';
import * as actions from '../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      maxWidth: 450,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  link: {
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
    width: '100px',
  },
  eye: {
    cursor: 'pointer',
  },
  passwordCard: {
    boxShadow: '0px 5px 5px -1px gray',
    backgroundColor: 'aliceblue',
  },
});

const usernameField = ({
  input,
  label,
  name,
  type,
  meta: { touched, error },
}) => (
  <TextField
    {...input}
    variant="outlined"
    size="small"
    fullWidth
    label={label}
    type={type}
    id={name}
    error={touched && error}
    helperText={touched && error}
    margin="normal"
    inputProps={{
      autoCapitalize: 'none',
    }}
    onChange={(event) => input.onChange(event.target.value.toLowerCase())}
  />
);

const passwordField = ({ input, label, name, meta: { touched, error } }) => (
  <FormControl fullWidth error={touched && error} margin="normal">
    <PasswordField
      input={input}
      name={name}
      label={label}
      touched={touched}
      error={error}
    />
  </FormControl>
);

const authMethodRadioBtn = ({ input, hasMobile, recipient }) => (
  <FormControl component="fieldset">
    <RadioGroup {...input} aria-label="auth-method" name="auth-method1">
      <FormControlLabel
        value="email"
        control={<Radio color="primary" />}
        label="Email"
      />
      {hasMobile && (
        <FormControlLabel
          value="sms"
          control={<Radio color="primary" />}
          label={`SMS to ${recipient}`}
          color="primary"
        />
      )}
    </RadioGroup>
  </FormControl>
);

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgotPasswordField: false,
      email: '',
      forgotEmailMessage: '',
      loadingRegister: false,
      loadingResetPassword: false,
      openAuthMethodChoice: false,
      verified: false,
      recipient: '',
      hasMobile: false,
      authToken: '',
    };
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.props.idleLogout === true) {
        this.setState({ forgotEmailMessage: 'You have been logged out.' });
      }
    }, 500);
  }

  handleClose = () => {
    this.setState({
      forgotEmailMessage: '',
      email: '',
      loadingResetPassword: false,
    });
  };

  async handleVerification(values) {
    const res = await axios.post(
      `/api/auth/patient/two-factor-auth/${values.authMethod}`,
      values
    );

    if (res.data) {
      const method = res.data.method === 'sms' ? 'SMS' : 'Email';

      toast.success(`${method} with PIN was sent.`, {
        position: 'bottom-center',
        duration: 4000,
      });
      this.setState({
        verified: true,
        recipient: res.data.recipient,
      });
    }
  }

  async handleMethod(values) {
    const res = await axios.post(
      '/api/auth/patient/two-factor-auth/none',
      values
    );
    if (res.data) {
      this.setState({
        hasMobile: res.data.hasMobilePhone,
        openAuthMethodChoice: true,
        recipient: res.data.recipient,
        authToken: res.data.token,
      });
      this.props.reset();
    }
  }

  render() {
    const { handleSubmit, classes, authMethod } = this.props;
    const {
      loadingResetPassword,
      showForgotPasswordField,
      verified,
      openAuthMethodChoice,
    } = this.state;
    return (
      <Fragment>
        <main className={classes.layout}>
          <Fade in={true} timeout={500}>
            <Paper className={classes.paper}>
              <img
                alt="SnapMed Logo"
                src={logoMain}
                srcSet={`${logoMainRetina} 2x,`}
              />
              {!verified && !openAuthMethodChoice && (
                <form
                  className={classes.form}
                  onSubmit={handleSubmit((values) => this.handleMethod(values))}
                >
                  <Field
                    name="username"
                    label="Username"
                    type="email"
                    component={usernameField}
                  />
                  <Field
                    name="password"
                    label="Password"
                    type="password"
                    classes={this.props.classes}
                    component={passwordField}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Sign in
                  </Button>
                  {}
                  <Link
                    to="/registration/new"
                    style={{ textDecoration: 'none' }}
                  >
                    <Button // className={classes.link}
                      variant="outlined"
                      fullWidth
                      disabled={this.state.loadingRegister}
                      onClick={() => this.setState({ loadingRegister: true })}
                    >
                      Register
                    </Button>
                  </Link>
                </form>
              )}
              {!verified && openAuthMethodChoice && (
                <form
                  className={classes.form}
                  onSubmit={handleSubmit((values) => {
                    const newValues = {
                      ...values,
                      token: this.state.authToken,
                    };
                    this.handleVerification(newValues);
                  })}
                >
                  <Fragment>
                    <Grid item xs={12} style={{ margin: '15px 0px' }}>
                      <Typography color="primary">
                        Please choose how you would like to receive your 2FA
                        PIN.
                      </Typography>
                    </Grid>
                    <Field
                      name="authMethod"
                      component={authMethodRadioBtn}
                      hasMobile={this.state.hasMobile}
                      recipient={this.state.recipient}
                    />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={!authMethod}
                    >
                      Submit
                    </Button>
                  </Fragment>
                </form>
              )}
              {verified && (
                <AuthPinForm
                  recipient={this.state.recipient}
                  token={this.state.authToken}
                />
              )}
              {!verified && !openAuthMethodChoice && (
                <Button
                  // className={classes.link}
                  size="small"
                  style={{ marginTop: '50px' }}
                  onClick={() => {
                    this.setState({
                      showForgotPasswordField: !showForgotPasswordField,
                    });
                  }}
                >
                  forgot password?
                </Button>
              )}
              <Typography
                align="center"
                variant="caption"
                style={{ marginTop: 16 }}
              >
                SnapMED v{REACT_APP_VERSION}
              </Typography>
              {loadingResetPassword && (
                <CircularProgress size={25} color="primary" />
              )}
            </Paper>
          </Fade>

          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={!!this.state.forgotEmailMessage}
            autoHideDuration={3000}
            onClose={this.handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={
              <span id="message-id">{this.state.forgotEmailMessage}</span>
            }
            action={
              <Fragment>
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={this.handleClose}
                >
                  <Close />
                </IconButton>
              </Fragment>
            }
          />

          <Dialog
            open={showForgotPasswordField}
            onClose={() => this.setState({ showForgotPasswordField: false })}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Forgot Password</DialogTitle>
            <DialogContent>
              <Typography variant="body1" color="primary">
                Email associated to your account:
              </Typography>
              <TextField
                id="email"
                type="email"
                label="Email"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() =>
                  this.setState({ showForgotPasswordField: false })
                }
              >
                Close
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  this.submitForgotEmail(this.state.email);
                  this.setState({
                    showForgotPasswordField: false,
                    loadingResetPassword: true,
                  });
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </main>
      </Fragment>
    );
  }

  async submitForgotEmail(email) {
    const res = await this.props.LoginService.forgotPassword(email);
    this.setState({ forgotEmailMessage: res, loadingResetPassword: false });
  }
}

function validate(values) {
  const errors = {};

  _.each(loginFormFields, ({ name }) => {
    if (!values[name]) {
      errors[name] = 'field required';
    }
  });

  return errors;
}

const selector = formValueSelector('loginForm');

function mapStateToProps(state) {
  return {
    auth: state.auth,
    idleLogout: state.idleLogout,
    email: selector(state, 'username'),
    authPin: selector(state, 'authPin'),
    authMethod: selector(state, 'authMethod'),
  };
}

LoginForm.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  authMethod: PropTypes.string,
  LoginService: PropTypes.object.isRequired,
  idleLogout: PropTypes.bool,
  reset: PropTypes.func.isRequired,
};

const ConnectedLoginForm = connect(
  mapStateToProps,
  actions
)(withRouter(LoginForm));

const LoginFormRedux = reduxForm({
  validate,
  form: 'loginForm',
})(ConnectedLoginForm);

export default withStyles(styles)(LoginFormRedux);
