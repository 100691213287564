import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DoctorDashboard from '../doctors/dashboard/Dashboard';
import DoctorAppointmentRoom from '../doctors/appointmentroom/DoctorAppointmentRoom';
import LoggedOut from '../login/LoggedOut';
import TermsAndConditions from '../../legal/TermsAndConditions';
import PrivacyPolicy from '../../legal/PrivacyPolicy';

const DoctorRoutes = () => (
  <Switch>
    <Route exact path="/" component={DoctorDashboard} />
    <Route exact path="/dashboard" component={DoctorDashboard} />
    <Route
      exact={true}
      path="/appointmentroomdr/:room"
      component={DoctorAppointmentRoom}
    />
    <Route exact={true} path="/logged_out" component={LoggedOut} />
    <Route
      exact={true}
      path="/TermsAndConditions"
      component={TermsAndConditions}
    />
    <Route exact={true} path="/PrivacyPolicy" component={PrivacyPolicy} />
    <Route exact path="*" component={DoctorDashboard} />
  </Switch>
);

export default DoctorRoutes;
