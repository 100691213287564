import React, { Component } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { Backdrop, CircularProgress, withStyles } from '@material-ui/core';

const styles = (theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

class HTTPErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backdrop: false /*  */,
    };

    axios.interceptors.request.use(
      (config) => {
        const requestId = uuidv4();
        const newConfig = { ...config };
        // Add UUID to request headers
        newConfig.headers['X-Request-uuid'] = requestId;

        if (newConfig.data && newConfig.data.backdrop) {
          this.handleBackdrop(true);
          const newData = { ...config.data };
          delete newData.backdrop;
          return { ...newConfig, data: newData };
        }

        return newConfig;
      },
      (err) => err
    );

    axios.interceptors.response.use(
      (response) => {
        if (response.data.success) {
          this.handleSuccess(response);
        }
        this.handleBackdrop(false);
        return response;
      },
      (err) => {
        if (err.response) {
          this.handleError(err);
        }
        return err;
      }
    );
  }

  handleBackdrop = (value) => {
    this.setState({
      backdrop: value,
    });
  };

  handleSuccess = (response) => {
    toast.success(response.data.success);
    this.setState({
      backdrop: false,
    });
  };

  handleError = (err) => {
    toast.error(this.getErrorMessage(err));
    this.setState({
      backdrop: false,
    });
  };

  // eslint-disable-next-line class-methods-use-this
  getErrorMessage(error) {
    const { data } = error.response;

    if (!data) {
      return 'Unknown error occurred: Error lacked data';
    }

    if (data.error) {
      return data.error;
    }

    if (data.message) {
      return data.message;
    }

    if (typeof data === 'string') {
      return data;
    }

    // TODO: send a message to server that it got an error without a known
    //       error field
    return 'Unknown error occurred: Error did not have known field';
  }

  handleClose = () => {
    this.setState({
      open: false,
    });

    setTimeout(() => {
      this.setState({
        message: '',
      });
    }, 500);
  };

  render() {
    const { backdrop } = this.state;
    const { classes } = this.props;
    return (
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}

HTTPErrorHandler.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(HTTPErrorHandler);
