import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, FormSection } from 'redux-form';
import {
  CssBaseline,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Grid,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Slide,
  OutlinedInput,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import {
  ExpandMore,
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from '@material-ui/icons';
import * as actions from '../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
    float: 'right',
  },
  spacing: {
    marginBottom: 15,
  },
  align: {
    marginRight: '45%',
  },
  noPadding: {
    padding: 0,
  },
  center: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
  },
  textBottomSpacing: {
    marginBottom: 20,
  },
});

class PaymentHistory extends Component {
  constructor(props) {
    super(props);

    this.perPage = 8;
    this.state = {
      page: 0,
      descendingOrder: true,
      expandedPanel: null,
    };
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.lastPage = this.lastPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
  }

  componentDidMount() {
    this.props.getPaymentHistory();
  }

  nextPage = () => {
    this.setState({ page: this.state.page + 1 });
  };

  previousPage = () => {
    this.setState({
      page: this.state.page - 1,
    });
  };
  lastPage = () => {
    this.setState({
      page: this.numPages,
    });
  };
  firstPage = () => {
    this.setState({
      page: 0,
    });
  };

  sortPayments = (payments) => {
    const { descendingOrder } = this.state;
    payments.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return descendingOrder ? dateB - dateA : dateA - dateB;
    });
  };

  renderExpansionFields = () => {
    const { payments, classes } = this.props;
    const { page, expandedPanel } = this.state;

    if (payments.paymentHistory.length === 0) {
      return (
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{
            marginTop: 20,
          }}
        >
          <Typography textAlign="center" variant="h5" color="primary">
            No payment records.
          </Typography>
        </Grid>
      );
    }

    const history = [];
    const stepper = page * this.perPage;
    const top = stepper + this.perPage;

    this.sortPayments(payments.paymentHistory);

    for (let i = stepper; i < top; i++) {
      if (payments.paymentHistory[i] === undefined) {
        break;
      }
      const payment = payments.paymentHistory[i];
      const purchaseDate = new Date(payment.date);
      const expirationDate = new Date(payment.date);
      expirationDate.setHours(
        expirationDate.getHours() + payment.durationInHours
      );

      history.push(
        <Accordion
          expanded={expandedPanel === payment._id}
          onChange={(event, isExpanded) =>
            this.setState({ expandedPanel: isExpanded ? payment._id : false })
          }
        >
          <AccordionSummary
            style={{ backgroundColor: '#ebebeb' }}
            expandIcon={<ExpandMore />}
          >
            <Typography
              className={classes.align}
              variant="button"
              color="primary"
            >
              Payment Date: {new Date(payment.date).toLocaleDateString('en-CA')}
            </Typography>

            <Typography variant="button" color="primary">
              ID:
              {payment._id.substring(19, 24)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: '#fcfcfc' }}>
            <Grid item xs={12}>
              <Typography variant="body2" paragraph>
                <strong>Amount Paid:</strong> ${payment.amount}
              </Typography>
              <Typography variant="body2" paragraph>
                <strong>Item:</strong> {payment.paymentReason}
              </Typography>
              <Typography variant="body2" paragraph>
                <strong>Date of Purchase:</strong>{' '}
                {purchaseDate.toLocaleDateString()}
              </Typography>
              {payment.paymentReason === 'Standard Subscription' && (
                // content for Standard Subscription
                <>
                  {payment.recipient && (
                    <Typography variant="body2" paragraph>
                      <strong>Patient:</strong>{' '}
                      {`${payment.recipient.name.first} ${payment.recipient.name.last}`}
                    </Typography>
                  )}
                  <Typography variant="body2" paragraph>
                    <strong>Duration:</strong>{' '}
                    {payment.durationInHours <= 24
                      ? `${payment.durationInHours} hours`
                      : `${payment.durationInHours / 24} days`}
                  </Typography>
                  <Typography variant="body2" paragraph>
                    <strong>Ends:</strong> {expirationDate.toLocaleDateString()}{' '}
                    {expirationDate.toLocaleTimeString()}
                  </Typography>
                </>
              )}
              {payment.credits && (
                // content for everything else
                <Typography variant="body2" paragraph>
                  <strong>Credits:</strong> {payment.credits}
                </Typography>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    }
    return history;
  };

  renderToolbar = () => {
    const { classes } = this.props;
    const { paymentHistory } = this.props.payments;

    const numberPayments = paymentHistory ? paymentHistory.length : 0;
    // pages start at 0 therefore subtract one
    let pages;

    if (this.perPage > numberPayments || numberPayments / this.perPage < 2) {
      pages = numberPayments / this.perPage;
    } else {
      pages = numberPayments / this.perPage - 1;
    }

    // if the division isnt even, get the quotient to add that extra page at end
    // normal case 10.3 - 10 = 0.3
    // edge case
    const quotient = pages - Math.floor(pages);

    if (pages < 1) {
      this.numPages = 0;
    } else if (quotient === 0) {
      this.numPages = pages;
    } else {
      this.numPages = Math.floor(pages) + 1;
    }

    this.bottomPerPage = this.state.page * this.perPage + 1;
    this.topPerPage = this.state.page * this.perPage + this.perPage;
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={6} sm={4}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel htmlFor={'order'}>Order by</InputLabel>
            <Select
              id="order"
              input={<OutlinedInput label="Order by" />}
              InputProps={{ classes: { input: classes.resize } }}
              value={this.state.descendingOrder ? 'Latest' : 'Oldest'}
              onChange={(event) => {
                this.setState({
                  descendingOrder: event.target.value !== 'Oldest',
                });
              }}
            >
              {['Oldest', 'Latest'].map((order) => (
                <MenuItem value={order} key={order}>
                  {order}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          style={{
            textAlign: 'center',
          }}
        >
          <Typography variant="caption" className={classes.center}>
            {` ${this.bottomPerPage} ${this.state.page === this.numPages ? ' ' : `- ${this.topPerPage}`} of ${numberPayments} `}
          </Typography>
        </Grid>
        <Grid item xs={3} sm={1}>
          <IconButton onClick={this.firstPage} disabled={this.state.page === 0}>
            <FirstPage />
          </IconButton>
        </Grid>
        <Grid item xs={3} sm={1}>
          <IconButton
            onClick={this.previousPage}
            disabled={this.state.page === 0}
          >
            <KeyboardArrowLeft />
          </IconButton>
        </Grid>

        <Grid item xs={3} sm={1}>
          <IconButton
            onClick={this.nextPage}
            disabled={this.state.page === this.numPages}
          >
            <KeyboardArrowRight />
          </IconButton>
        </Grid>
        <Grid item xs={3} sm={1}>
          <IconButton
            onClick={this.lastPage}
            disabled={this.state.page === this.numPages}
          >
            <LastPage />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes, payments } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <FormSection name="paymentHistory">
              <Grid
                container
                style={{
                  margin: 0,
                  width: '100%',
                }}
                direction="row"
              >
                <Grid item xs={12} style={{ marginTop: '5%' }}>
                  <Typography
                    variant="h5"
                    className={classes.spacing}
                    color="primary"
                  >
                    Your Payment History
                  </Typography>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} className={classes.textBottomSpacing}>
                    <Typography variant="body1">
                      You can see all the payments you have made below, just
                      click on any tab to expand it for more information:{' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {this.renderToolbar()}
                  </Grid>
                  <Grid item xs={12}>
                    {payments.paymentHistory ? (
                      this.renderExpansionFields()
                    ) : (
                      <Grid
                        item
                        container
                        xs={12}
                        justify="center"
                        alignContent="center"
                        style={{
                          height: '200px',
                        }}
                      >
                        <CircularProgress size={40} color="primary" />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </FormSection>
          </main>
        </Slide>
      </Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    form: state.form,
    payments: state.payments,
  };
}

PaymentHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  payments: PropTypes.object.isRequired,
  getPaymentHistory: PropTypes.func.isRequired,
};

const ConnectedPaymentHistory = connect(
  mapStateToProps,
  actions
)(PaymentHistory);

const PaymentHistoryRedux = reduxForm({
  //  validate,
  form: 'dashboardUpdateForm',
  destroyOnUnmount: false,
})(ConnectedPaymentHistory);

export default withStyles(styles)(PaymentHistoryRedux);
