import { combineReducers } from 'redux';
import { reducer as reduxForm } from 'redux-form';
import authReducer from './authReducer';
import registrationReducer from './registrationReducer';
import medicalProfileCompletionReducer from './medicalProfileCompletionReducer';
import appointmentReducer from './appointmentReducer';
import doctorJaasReducer from './doctorJaasReducer';
import paymentHistoryReducer from './paymentHistoryReducer';
import appointmentHistoryReducer from './appointmentHistoryReducer';
import patientPriceReducer from './patientPriceReducer';
import promoCodeReducer from './promoCodeReducer';
import medicalDataReducer from './medicalDataReducer';
import keepSymptomsDataReducer from './keepSymptomsDataReducer';
import patientRoomReducer from './patientRoomReducer';
import selectedUserReducer from './selectedUserReducer';
import selectedUserMedicalReducer from './selectedUserMedicalReducer';
import smartFamilyPlanReducer from './smartFamilyPlanReducer';
import smartAppointmentPlanReducer from './smartAppointmentCodeReducer';
import finalizePrescriptionReducer from './finalizePrescriptionReducer';
import finalizeSickNoteReducer from './finalizeSickNoteReducer';
import finalizeSchoolSickNoteReducer from './finalizeSchoolSickNoteReducer';
import drugInteractionCheckReducer from './drugInteractionCheckReducer';
import controlledSubstanceCheckReducer from './controlledSubstanceCheckReducer';
import savedTextChatReducer from './savedTextChatReducer';
import photoIdValidationReducer from './photoIdValidationReducer';
import medicalFormOtherFieldReducer from './medicalFormOtherFieldReducer';
import idleLogoutReducer from './idleLogoutReducer';
import timerReducer from './timerReducer';
import creditCardListReducer from './creditCardListReducer';
import deliveriesReducer from './deliveriesReducer';
import pharmacyPromoListReducer from './pharmacyPromoReducer';
import deliveryStatusesListReducer from './deliveryStatusesReducer';
import pharmacyPaymentsReducer from './pharmacyPaymentsReducer';
import diagnosticCodeReducer from './diagnosticCodeReducer';
import notificationsReducer from './notificationsReducer';
import ipGeolocationReducer from './ipGeolocationReducer';

export default combineReducers({
  auth: authReducer,
  medicalData: medicalDataReducer,
  form: reduxForm,
  registrationError: registrationReducer,
  medicalProfileCompletion: medicalProfileCompletionReducer,
  appointmentParams: appointmentReducer,
  appointmentDoctorJaas: doctorJaasReducer,
  payments: paymentHistoryReducer,
  appointments: appointmentHistoryReducer,
  appointmentPrice: patientPriceReducer,
  promoCode: promoCodeReducer,
  symptomsData: keepSymptomsDataReducer,
  patientRoom: patientRoomReducer,
  selectedUser: selectedUserReducer,
  selectedUserMedical: selectedUserMedicalReducer,
  smartFamilyPlan: smartFamilyPlanReducer,
  finalizePrescription: finalizePrescriptionReducer,
  finalizeSickNote: finalizeSickNoteReducer,
  finalizeSchoolSickNote: finalizeSchoolSickNoteReducer,
  drugInteractionCheck: drugInteractionCheckReducer,
  controlledSubstanceCheck: controlledSubstanceCheckReducer,
  savedTextChat: savedTextChatReducer,
  smartAppointmentCode: smartAppointmentPlanReducer,
  photoIdValidation: photoIdValidationReducer,
  medicalFormOtherField: medicalFormOtherFieldReducer,
  idleLogout: idleLogoutReducer,
  timerValues: timerReducer,
  creditCardList: creditCardListReducer,
  deliveriesList: deliveriesReducer,
  pharmacyPromoList: pharmacyPromoListReducer,
  deliveryStatusesList: deliveryStatusesListReducer,
  pharmacyPayments: pharmacyPaymentsReducer,
  diagnosticCodes: diagnosticCodeReducer,
  notifications: notificationsReducer,
  geo: ipGeolocationReducer,
});
