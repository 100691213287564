import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toast from 'react-hot-toast';
import * as actions from '../../actions';

class IdleTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timers: [],
      message: '',
      portal: props.portal || 'Patient',
      component: props.component || 'Dashboard',
      role: props.role || 'All',
    };
  }

  // specify events to reset timers on
  events = ['mousemove', 'scroll', 'keydown', 'touchstart', 'mousedown'];
  testFactor = 1; // shortens timer durations, set to 1 before deploying

  async componentDidMount() {
    if (this.props.timers.length === 0) await this.props.fetchTimerValues();
    // start event listeners
    this.events.forEach((event) => {
      // restart timers every time event occures
      window.addEventListener(event, this.resetTimers, false);
    });

    // start timers
    if (this.props.timers.length > 0) {
      this.props.timers.forEach((timer) => {
        if (
          timer.portal === this.state.portal &&
          timer.component === this.state.component &&
          (timer.role === this.state.role || timer.role === 'All') &&
          (timer.type === 'Warning' || timer.type === 'Logout')
        ) {
          // add new timer obj
          this.state.timers.push({
            name: timer.name,
            duration: timer.durationInMinutes,
            message: timer.message,
            timer: setTimeout(
              this.timerCallback,
              (timer.durationInMinutes * 60 * 1000) / this.testFactor,
              timer
            ),
          });
        }
      });
    }
  }

  // fn to run when timer ends
  timerCallback = async (timer) => {
    // show alert
    toast(timer.message, {
      icon: '⚠️',
      style: {
        borderRadius: '10px',
        background: '#333',
        color: '#fff',
        minWidth: 'fit-content',
      },
      position: 'top-center',
      duration: Infinity,
      id: timer.name,
    });

    // Logout if logout timer
    if (timer.type === 'Logout') {
      // cleanup event listeners
      this.events.forEach((event) => {
        window.removeEventListener(event, this.resetTimers, false);
      });

      // cleanup timers
      this.state.timers.forEach((t) => clearTimeout(t.timer));

      // logout action
      this.props.logout(
        this.state.portal.toLowerCase(),
        null,
        this.props.history
      );
    }
  };

  resetTimers = async () => {
    // clean up existing timers and state
    this.state.timers.forEach((timer) => clearTimeout(timer.timer));
    this.setState({ timers: [] });

    // start new timers
    this.props.timers.forEach((timer) => {
      if (
        timer.portal === this.state.portal &&
        timer.component === this.state.component &&
        (timer.role === this.state.role || timer.role === 'All') &&
        (timer.type === 'Warning' || timer.type === 'Logout')
      ) {
        toast.dismiss(timer.name);
        this.state.timers.push({
          name: timer.name,
          duration: timer.duration,
          message: timer.message,
          timer: setTimeout(
            this.timerCallback,
            (timer.durationInMinutes * 60 * 1000) / this.testFactor,
            timer
          ),
        });
      }
    });
  };

  async componentWillUnmount() {
    // clear all timers and event listeners
    this.state.timers.forEach((timer) => {
      clearTimeout(timer.timer);
      toast.dismiss(timer.name);
    });
    this.setState({ timers: [] });

    this.events.forEach((event) => {
      window.removeEventListener(event, this.resetTimers, false);
    });
  }

  // render nothing
  // eslint-disable-next-line class-methods-use-this
  render() {
    return <></>;
  }
}

const mapStateToProps = (state) => ({
  timers: state.timerValues,
});

IdleTimer.propTypes = {
  fetchTimerValues: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  timers: PropTypes.array.isRequired,
  portal: PropTypes.string,
  component: PropTypes.string,
  role: PropTypes.string,
  history: PropTypes.object.isRequired,
};

const ConnectedIdleTimer = connect(mapStateToProps, actions)(IdleTimer);

export default ConnectedIdleTimer;
