import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, FormSection } from 'redux-form';
import PropTypes from 'prop-types';
import {
  Button,
  CssBaseline,
  FormControl,
  Radio,
  RadioGroup,
  Typography,
  TextField,
  Grid,
  FormHelperText,
  FormControlLabel,
  Slide,
  withStyles,
} from '@material-ui/core';
import * as actions from '../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: '1.5rem',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    float: 'right',
  },
  choices: {
    marginTop: 30,
  },
  title: {
    margin: '30px 0',
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  otherField: {
    marginTop: 40,
  },
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
  },
});

const SmokingField = ({ name, input, meta: { touched, error } }) => (
  <FormControl error={touched && error} component="fieldset">
    <RadioGroup name={name} {...input} row>
      <FormControlLabel
        value="isTobaccoSmoker_yes"
        control={<Radio color="primary" />}
        label="Yes"
      />
      <FormControlLabel
        value="isTobaccoSmoker_no"
        control={<Radio color="primary" />}
        label="No"
      />
    </RadioGroup>
    {touched && error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const DrinkingField = ({ name, input, meta: { touched, error } }) => (
  <FormControl error={touched && error} component="fieldset">
    <RadioGroup name={name} {...input} row>
      <FormControlLabel
        value="isAlcoholConsumer_yes"
        control={<Radio color="primary" />}
        label="Yes"
      />
      <FormControlLabel
        value="isAlcoholConsumer_no"
        control={<Radio color="primary" />}
        label="No"
      />
    </RadioGroup>
    {touched && error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const SubstanceField = ({ name, input, meta: { touched, error } }) => (
  <FormControl error={touched && error} component="fieldset">
    <RadioGroup name={name} {...input} row>
      <FormControlLabel
        value="isSubstanceUser_yes"
        control={<Radio color="primary" />}
        label="Yes"
      />
      <FormControlLabel
        value="isSubstanceUser_no"
        control={<Radio color="primary" />}
        label="No"
      />
    </RadioGroup>
    {touched && error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const SubstancesField = ({ name, label, input, meta: { touched, error } }) => (
  <TextField
    {...input}
    id={name}
    label={label}
    type="input"
    variant="outlined"
    fullWidth
    error={touched && error}
    helperText={touched && error}
  />
);

class EditSubstances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openOtherField: this.props.selectedUserMedical.isSubstanceUser,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  renderSmokingField() {
    return (
      <Field
        name="isTobaccoSmoker"
        type="text"
        // onBlur={null}
        component={SmokingField}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderDrinkingField() {
    return (
      <Field name="isAlcoholConsumer" type="text" component={DrinkingField} />
    );
  }

  renderSubstanceField() {
    return (
      <Field
        name="isSubstanceUser"
        type="text"
        component={SubstanceField}
        onChange={(_event, newValue) => {
          this.setState({
            openOtherField: newValue === 'isSubstanceUser_yes',
          });
        }}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSubstancesInputField(name, label) {
    return <Field name={name} label={label} component={SubstancesField} />;
  }

  render() {
    const { classes, selectedUser, reset, handleSubmit } = this.props;

    const onSubmit = (formValues) => {
      const {
        isTobaccoSmoker,
        isAlcoholConsumer,
        isSubstanceUser,
        substanceInputField,
      } = formValues.medicalProfile;

      const values = {
        ...formValues,
        medicalProfile: {
          isTobaccoSmoker: isTobaccoSmoker === 'isTobaccoSmoker_yes',
          isAlcoholConsumer: isAlcoholConsumer === 'isAlcoholConsumer_yes',
          isSubstanceUser: isSubstanceUser === 'isSubstanceUser_yes',
          substance:
            isSubstanceUser === 'isSubstanceUser_yes'
              ? substanceInputField
              : '',
        },
        id: selectedUser._id,
      };
      this.props.dispatch(actions.editProfile(values));

      setTimeout(() => {
        this.props.closeEditPage();
      }, 1000);
    };

    return (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <FormSection name="medicalProfile">
              <Grid container direction="row">
                <Grid item className={classes.title}>
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Edit Substances
                  </Typography>
                </Grid>
                <Grid container direction="row">
                  <Grid item container xs={6} alignItems="center">
                    <Typography variant="body1">
                      Do you smoke tobacco?
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {this.renderSmokingField()}
                    {/* {this.props.isTobaccoSmoker==='isTobaccoSmoker_yes' && (  )} */}
                  </Grid>
                  <Grid item container xs={6} alignItems="center">
                    <Typography variant="body1">
                      Do you consume alcohol?
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {this.renderDrinkingField()}
                    {/* {this.props.isAlcoholDrinker==='isTobaccoSmoker_yes' && (  )} */}
                  </Grid>
                  <Grid item container xs={6} alignItems="center">
                    <Typography variant="body1">
                      Do you use any substance?
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {this.renderSubstanceField()}
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  {this.state.openOtherField && (
                    <Grid item xs={12}>
                      {this.renderSubstancesInputField(
                        'substanceInputField',
                        'Substance'
                      )}
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  direction="row"
                  className={classes.bottomSpacing}
                >
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      className={classes.alignButton}
                      onClick={() => {
                        reset();
                        this.props.closeEditPage();
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.alignButton}
                      style={{
                        float: 'right',
                      }}
                      onClick={handleSubmit((data) => onSubmit(data))}
                      disabled={!this.props.valid}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </FormSection>
          </main>
        </Slide>
      </Fragment>
    );
  }
}

const validate = (values) => {
  const errors = { medicalProfile: {} };

  if (values.medicalProfile) {
    if (
      values.medicalProfile.substanceInputField === '' &&
      values.medicalProfile.isSubstanceUser === 'isSubstanceUser_yes'
    ) {
      errors.medicalProfile.substanceInputField = 'This field cannot be blank';
    }
  }

  return errors;
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    selectedUser: state.selectedUser,
    selectedUserMedical: state.selectedUserMedical,
    dashboardUpdateForm: state.form.dashboardUpdateForm,
    initialValues: {
      medicalProfile: {
        isTobaccoSmoker: state.selectedUserMedical.isTobaccoSmoker
          ? 'isTobaccoSmoker_yes'
          : 'isTobaccoSmoker_no',
        isAlcoholConsumer: state.selectedUserMedical.isAlcoholConsumer
          ? 'isAlcoholConsumer_yes'
          : 'isAlcoholConsumer_no',
        isSubstanceUser: state.selectedUserMedical.isSubstanceUser
          ? 'isSubstanceUser_yes'
          : 'isSubstanceUser_no',
        substanceInputField: state.selectedUserMedical.substance,
      },
    },
  };
}

EditSubstances.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedUser: PropTypes.object.isRequired,
  selectedUserMedical: PropTypes.object.isRequired,
  dashboardUpdateForm: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  closeEditPage: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

SmokingField.propTypes = {
  name: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
};

DrinkingField.propTypes = {
  name: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
};

SubstanceField.propTypes = {
  name: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
};

SubstancesField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
};

const EditSubstancesRedux = reduxForm({
  validate,
  form: 'dashboardUpdateForm',
  destroyOnUnmount: false,
  // onSubmit: handleSave,
  enableReinitialize: true,
  // forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(EditSubstances);

const ConnectedEditSubstances = connect(
  mapStateToProps,
  actions
)(EditSubstancesRedux);

export default withStyles(styles)(ConnectedEditSubstances);
