import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControlLabel,
  Switch,
  Fade,
  TextField,
  FormHelperText,
} from '@material-ui/core';

const EditOtherField = (props) => {
  const [show, setShow] = useState(false);

  const {
    input,
    label,
    input: { name },
    meta: { error },
  } = props;

  return (
    <Fragment>
      <Grid item xs={12} sm={3}>
        <FormControlLabel
          label="Edit Other"
          labelPlacement="start"
          style={{ marginLeft: 0 }}
          control={<Switch color="primary" />}
          onChange={async () => {
            await setShow((previous) => !previous);
            if (!show) document.getElementById(name).focus();
          }}
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <Fade in={show} timeout={300}>
          <TextField
            {...input}
            id={name}
            name={name}
            label={label}
            type="input"
            variant="outlined"
            size="small"
            fullWidth
            autoFocus
            error={error}
          />
        </Fade>
      </Grid>
      <FormHelperText error={error}>{error && `*${error}`}</FormHelperText>
    </Fragment>
  );
};

EditOtherField.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

export default EditOtherField;
