import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { reduxForm, Field } from 'redux-form';
import {
  Paper,
  Typography,
  Grid,
  Button,
  TextField,
  FormControl,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import InputMask from 'react-input-mask';
import PasswordField from './PasswordField';
import DataUseConsentText from '../profileform/DataUseConsentText';

const styles = (theme) => ({
  layout: {
    width: 'auto',
    height: '100%',
    //  display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: '65px',
    },
    [theme.breakpoints.down(350 + theme.spacing.unit * 3 * 2)]: {
      width: 350,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    flexGrow: 1,
    flexDirection: 'row',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  rowSpacing: {
    margin: '10px 0px',
  },
});

const codeField = ({ input, id, label, meta: { touched, error } }) => (
  <FormControl fullWidth error={touched && error}>
    <InputMask mask="999999" {...input} onChange={input.onChange}>
      {(inputProps) => (
        <TextField
          {...inputProps}
          type="text"
          label={label}
          id={id}
          variant="outlined"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    </InputMask>
  </FormControl>
);

const dateField = ({ input, id, label, meta: { touched, error } }) => (
  <FormControl fullWidth error={touched && error}>
    <TextField
      {...input}
      type="date"
      label={label}
      id={id}
      variant="outlined"
      size="small"
      InputLabelProps={{
        shrink: true,
      }}
      placeholder="yyyy-mm-dd"
      inputProps={{ max: '9999-12-31' }}
    />
  </FormControl>
);

const emailField = ({ input, id, label, meta: { touched, error } }) => (
  <FormControl fullWidth>
    <TextField
      {...input}
      variant="outlined"
      type="email"
      label={label}
      id={id}
      size="small"
      error={touched && error}
      helperText={touched && error}
    />
  </FormControl>
);

const phoneNumberField = ({ input, name, label, meta: { touched, error } }) => (
  <FormControl fullWidth variant="outlined">
    <InputMask mask="(999) 999-9999" {...input} onChange={input.onChange}>
      {(inputProps) => (
        <TextField
          {...inputProps}
          fullWidth
          size="small"
          id={name}
          type="text"
          label={label}
          variant="outlined"
          error={touched && error}
          helperText={touched && error}
        />
      )}
    </InputMask>
  </FormControl>
);

class AccountTransferForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: '',
      userName: {},
      parentAccountName: {},
      verificationSubmitButton: false,
      confirmationSubmitButton: true,
      circularProgressActive: false,
      errorMessage: '',
      userVerified: false,
      openCancelDialog: false,
    };
  }

  async componentDidMount() {
    const { userId } = this.props.match.params;
    const user = await axios.post(
      '/api/profile/patient/account-transfer-user-verification',
      { userId, verification: false }
    );
    this.setState({
      userId,
      userName: user.data.name,
      parentAccountName: user.data.parentAccountUserId.name,
    });
  }

  handleVerification = async (values) => {
    const newValues = {
      ...values,
      userId: this.state.userId,
      verification: true,
    };
    this.setState({ verificationSubmitButton: true, errorMessage: '' });
    const response = await axios.post(
      '/api/profile/patient/account-transfer-user-verification',
      newValues
    );
    if (response.data && response.data.verified === true) {
      this.props.reset();
      this.setState({ userVerified: true, verificationSubmitButton: false });
    }
    if (response.data && response.data.errorMessage) {
      this.setState({
        verificationSubmitButton: false,
        errorMessage: response.data.errorMessage,
      });
      setTimeout(() => this.setState({ errorMessage: '' }), 3000);
    }
  };

  handleTransferForm = async (values) => {
    this.setState({
      confirmationSubmitButton: true,
      circularProgressActive: true,
      errorMessage: '',
    });
    const newValues = { ...values, userId: this.state.userId };
    const response = await axios.post(
      '/api/profile/patient/account-transfer-validation',
      newValues
    );
    if (!response.data.errorMessage) {
      this.setState({
        errorMessage: 'You will be redirected in a few seconds',
      });
      setTimeout(() => this.props.history.push('/login'), 5000);
    } else {
      this.setState({
        errorMessage: response.data.errorMessage,
        confirmationSubmitButton: false,
        circularProgressActive: false,
      });
    }
  };

  scrollCheck = (event) => {
    const bottom =
      Math.floor(event.target.scrollHeight - event.target.scrollTop) <=
      event.target.clientHeight;
    if (bottom) {
      this.setState({ confirmationSubmitButton: false });
    }
  };

  closeDialog = () => {
    this.setState({ openCancelDialog: false });
  };

  render() {
    const { classes, handleSubmit } = this.props;
    const {
      verificationSubmitButton,
      confirmationSubmitButton,
      circularProgressActive,
      errorMessage,
      userVerified,
      openCancelDialog,
    } = this.state;
    return (
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Fragment>
            {!userVerified ? (
              <form
                onSubmit={handleSubmit((values) =>
                  this.handleVerification(values)
                )}
              >
                <Grid item xs={12} container>
                  <Grid item xs={12} className={classes.rowSpacing}>
                    <Typography variant="h4" align="center" color="primary">
                      Account Transfer
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.rowSpacing}>
                    <Typography variant="h6" align="center" color="primary">
                      Hello,{' '}
                      {this.state.userName ? this.state.userName.first : null}{' '}
                      {this.state.userName ? this.state.userName.last : null}!
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.rowSpacing}>
                    <Typography align="center" color="primary">
                      For verification purposes, please enter the 6-digit code
                      you received via text message and your date of birth.
                    </Typography>
                  </Grid>
                  <Grid container spacing={1} className={classes.rowSpacing}>
                    <Grid item xs={6}>
                      <Field
                        name="verification_code"
                        label="Verification Code"
                        component={codeField}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="dob_field"
                        label="Date of Birth"
                        component={dateField}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    className={classes.rowSpacing}
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          this.setState({ openCancelDialog: true })
                        }
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={verificationSubmitButton}
                      >
                        Submit
                        {verificationSubmitButton && (
                          <CircularProgress
                            size={15}
                            color="primary"
                            style={{
                              marginLeft: 5,
                            }}
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ padding: 0 }}>
                    <Collapse in={errorMessage}>
                      <Typography align="right" color="error">
                        {errorMessage}
                      </Typography>
                    </Collapse>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <form
                onSubmit={handleSubmit((values) =>
                  this.handleTransferForm(values)
                )}
              >
                <Grid item xs={12} container>
                  <Grid item xs={12} className={classes.rowSpacing}>
                    <Typography variant="h4" align="center" color="primary">
                      Account Transfer
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.rowSpacing}>
                    <Typography variant="h6" align="center" color="primary">
                      Thank you{' '}
                      {this.state.userName ? this.state.userName.first : null},
                      you were successfully{' '}
                      <span style={{ color: 'green' }}>verified</span>!
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.rowSpacing}>
                    <Typography align="center" color="primary">
                      Please enter your new credentials for your new account
                      with SnapMED.
                    </Typography>
                  </Grid>
                  <Grid container spacing={3} className={classes.rowSpacing}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="email"
                        label="Email"
                        component={emailField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="phone_number"
                        label="Phone Number"
                        component={phoneNumberField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="password"
                        label="Password"
                        component={PasswordField}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="confirm_password"
                        label="Confirm Password"
                        component={PasswordField}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.rowSpacing}>
                      <Typography variant="body2" color="primary">
                        {`By finalizing this process you hereby acknowledge and
                        agree with SnapMED's Privacy Policy and to comply with
                        the Terms of Use and you agree that such consent has
                        been freely given.`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} container onScroll={this.scrollCheck}>
                      <DataUseConsentText />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    className={classes.rowSpacing}
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          this.setState({ openCancelDialog: true })
                        }
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={confirmationSubmitButton}
                      >
                        Submit
                        {circularProgressActive && (
                          <CircularProgress
                            size={15}
                            color="primary"
                            style={{
                              marginLeft: 5,
                            }}
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ padding: 0 }}>
                    <Collapse in={errorMessage}>
                      <Typography align="right" color="error">
                        {errorMessage}
                      </Typography>
                    </Collapse>
                  </Grid>
                </Grid>
              </form>
            )}
          </Fragment>
        </Paper>
        <Dialog open={openCancelDialog} onClose={this.closeDialog} fullWidth>
          <DialogTitle> Are you sure?</DialogTitle>
          <DialogContent>
            <Typography>
              By selecting CANCEL, you are acknowledging that you have not
              completed the account transfer process. The account will remain
              with{' '}
              {this.state.parentAccountName
                ? this.state.parentAccountName.first
                : null}{' '}
              {this.state.parentAccountName
                ? this.state.parentAccountName.last
                : null}
              .
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.closeDialog}
            >
              Dismiss
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.props.history.push('/login')}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function validate(values) {
  const errors = {};

  if (!values.email) {
    errors.email = 'Field Required';
  }
  if (values.email) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!pattern.test(values.email)) {
      errors.email = 'Please enter a valid email address';
    }
  }
  if (!values.phone_number) {
    errors.phone_number = 'Field Required';
  }
  if (!values.password) {
    errors.password = 'Field Required';
  }
  if (!values.confirm_password) {
    errors.confirm_password = 'Field Required';
  }
  if (
    values.password &&
    values.confirm_password &&
    values.password !== values.confirm_password
  ) {
    errors.confirm_password = 'Passwords must match';
  }
  if (values.password && values.password.length < 8) {
    errors.password = 'Password must have at least 8 characters';
  }
  if (values.phone_number) {
    const numberStr = values.phone_number.replace(/\D+/g, '');
    if (numberStr.length < 10) {
      errors.phone_number = 'Please enter a valid number';
    }
  }

  return errors;
}

AccountTransferForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
};

const ConnectedAccountTransferForm =
  connect(mapStateToProps)(AccountTransferForm);

const AccountTransferFormRedux = reduxForm({
  validate,
  form: 'AccountTransferForm',
})(ConnectedAccountTransferForm);

export default withStyles(styles)(AccountTransferFormRedux);
