import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginForm from './LoginForm';
import * as actions from '../../actions';

import LoginService from './LoginService';

class LoginRoot extends Component {
  constructor(props) {
    super(props);
    this.loginService = new LoginService();
  }
  render() {
    return <LoginForm LoginService={this.loginService} />;
  }
}

function mapStateToProps(state) {
  return {
    form: state.form,
  };
}

export default connect(mapStateToProps, actions)(withRouter(LoginRoot));
