import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import RegistrationForm from './RegistrationForm';
import * as actions from '../../actions';

class Registration extends Component {
  renderContent() {
    // the following flow is complicated because of the way App.js fetches the user

    return (
      <RegistrationForm
        onSubmit={(values) => {
          this.props.submitRegistration(values, this.props.history);
        }}
        registrationError={this.props.registrationError}
      />
    );
  }

  render() {
    return <div>{this.renderContent()}</div>;
  }
}
Registration.propTypes = {
  auth: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  registrationError: PropTypes.string,
  submitRegistration: PropTypes.func,
  history: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    registrationError: state.registrationError,
  };
}
export default connect(mapStateToProps, actions)(withRouter(Registration));
