import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import ToolbarIcons from './ToolbarIcons';
import iconList from './iconList';

const Toolbar = ({ classes, handleClick, iconClassName }) => (
  <Grid item id="toolbar">
    {iconList.map((name) => (
      <Tooltip
        title={name === 'videochat' ? 'Video Chat' : name}
        placement="right"
        key={name}
      >
        <IconButton
          id={name}
          className={iconClassName(name)}
          onClick={() => handleClick(name)}
        >
          <ToolbarIcons className={classes.actualIcon} name={name} />
        </IconButton>
      </Tooltip>
    ))}
  </Grid>
);

Toolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  iconClassName: PropTypes.func.isRequired,
};

export default Toolbar;
