import React, { Component, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import PropTypes from 'prop-types';
import {
  Button,
  CssBaseline,
  FormControl,
  MenuItem,
  Select,
  Paper,
  Typography,
  TextField,
  Grid,
  FormControlLabel,
  FormHelperText,
  CircularProgress,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import { ArrowBackIos } from '@material-ui/icons';
import DatePicker from 'react-datepicker';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createTextMask } from 'redux-form-input-masks';
import { Link } from 'react-router-dom';
import * as actions from '../../actions';
import countryRegionFields from '../dashboard/CountryRegionFields';

import 'react-datepicker/dist/react-datepicker.css';
import '../../styles/safariDatePicker.css';

let accountAdultNumber = 0;
const customPhoneMask = {
  9: {
    regExp: /[0-9]/,
  },
};

const phoneMask = createTextMask({
  pattern: '+9 (999) 999-9999',
  guide: false,
  allowEmpty: true,
});

const phoneMaskInternational = createTextMask({
  pattern: '+999999999999999',
  guide: false,
  allowEmpty: true,
  maskDefinitions: customPhoneMask,
});

const customPostalCodeMaskDefinitions = {
  9: {
    regExp: /[0-9]/,
  },
  A: {
    regExp: /[A-Za-z]/,
    transform: (char) => char.toUpperCase(),
  },
};

const postalCodeMask = createTextMask({
  pattern: 'A9A 9A9',
  guide: false,
  maskDefinitions: customPostalCodeMaskDefinitions,
});

const styles = (theme) => ({
  layout: {
    //  width: 'auto',
    //  height: '100%',
    //  display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 5}px ${
      theme.spacing.unit * 5
    }px`,
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    float: 'right',
    marginTop: 40,
  },

  alignButtonLeft: {
    float: 'left',
    marginTop: 40,
  },

  typography: {
    marginTop: 15,
  },
});

const ProfileField = ({
  input,
  name,
  type,
  meta: { touched, error },
  placeholder,
  disabled,
}) => (
  <TextField
    {...input}
    // required
    type={type}
    id={name}
    fullWidth
    placeholder={placeholder}
    disabled={disabled}
    error={touched && error}
    helperText={touched && error}
    variant="outlined"
  />
);

const DateField = ({ input, name, type, meta: { touched, error } }) => (
  <TextField
    {...input}
    // required
    type={type}
    id={name}
    fullWidth
    error={touched && error}
    helperText={touched && error}
    variant="outlined"
    inputProps={{ max: '9999-12-31' }}
  />
);

const FamilyAccountCheckField = ({ name, input, label }) => (
  <div>
    <FormControl>
      <FormControlLabel
        control={
          <Checkbox
            {...input}
            id={name}
            color="primary"
            checked={input.value ? label : false}
          />
        }
      />
    </FormControl>
  </div>
);

const userGender = [
  'Male',
  'Female',
  'Transgender Male / FTM',
  'Transgender Female / MTF',
  'Non-binary / Other',
  'Rather not say',
];
const userSex = ['Male', 'Female'];

const GenderField = ({ input, meta: { touched, error } }) => (
  <FormControl variant="outlined" fullWidth>
    <Autocomplete
      {...input}
      id="genderFieldAutocomplete"
      options={userGender}
      renderInput={(params) => <TextField {...params} variant="outlined" />}
    />
    {touched && error ? (
      <FormHelperText>
        <span style={{ color: '#f44336' }}>{error}</span>
      </FormHelperText>
    ) : null}
  </FormControl>
);

const SexField = ({ input, meta: { touched, error } }) => (
  <FormControl variant="outlined" fullWidth>
    <Select {...input} error={touched && error} helperText={touched && error}>
      {userSex.map((gend) => (
        <MenuItem key={gend} value={gend}>
          {gend}
        </MenuItem>
      ))}
    </Select>
    {touched && error ? (
      <FormHelperText>
        <span style={{ color: '#f44336' }}>{error}</span>
      </FormHelperText>
    ) : null}
  </FormControl>
);

const isDateSupported = () => {
  const i = document.createElement('input');
  i.setAttribute('type', 'date');
  return i.type !== 'text';
};

const CustomInput = ({ input, value, onClick }) => (
  <TextField
    value={value}
    onClick={onClick}
    {...input}
    fullWidth
    variant="outlined"
    InputLabelProps={{
      shrink: true,
    }}
    placeholder="yyyy-mm-dd"
  />
);

const SafariDatePicker = ({ id, input }) => {
  const [startDate, setStartDate] = useState();

  return (
    <div>
      <DatePicker
        {...input}
        name={id}
        customInput={<CustomInput />}
        dateFormat="yyyy-MM-dd"
        selected={startDate}
        showYearDropdown
        showMonthDropdown
        onChange={(date) => {
          setStartDate(date);
          const newDate = date.toLocaleDateString('en-CA');
          input.onChange(newDate);
        }}
      />
    </div>
  );
};

class AdditionalFamilyMembers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      country: false,
      memberLimit: this.props.memberLimit,
      familyMembers: ['false'],
      maxNumberOfFamilyMembers: 0,
      numberOfAllowedAdditionalMembers: 0,
      wasMemberAdded: false,
      disableSubmit: false,
    };
  }

  componentDidMount() {
    accountAdultNumber = this.props.auth.accountAdultNumber;
    const activeMembers = this.props.auth.familyMembers.filter(
      (member) => member.isActive
    ).length;
    const allowedExtraMembers = this.props.memberLimit - activeMembers;
    this.setState({ numberOfAllowedAdditionalMembers: allowedExtraMembers });
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      accountAdultNumber = this.props.auth.accountAdultNumber;
      const activeMembers = this.props.auth.familyMembers.filter(
        (member) => member.isActive
      ).length;
      const allowedExtraMembers = this.props.memberLimit - activeMembers;
      this.setState({ numberOfAllowedAdditionalMembers: allowedExtraMembers });
    }
  }
  addOneFamilyMember() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" color="primary">
              Family Member {this.props.auth.familyMembers.length + 1}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.typography} variant="body1">
              First Name
            </Typography>
            {this.renderFields('family_member_first_name', 'text')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.typography} variant="body1">
              Last Name
            </Typography>
            {this.renderFields('family_member_last_name', 'text')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.typography} variant="body1">
              Date of Birth
            </Typography>
            {this.renderDateField(
              'family_member_date_of_birth',
              'date',
              isDateSupported()
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.typography} variant="body1">
              Sex Assigned at Birth
            </Typography>
            {this.renderSexField('family_member_sex', 'input')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.typography} variant="body1">
              Gender Identity
            </Typography>
            {this.renderGenderField('family_member_gender', 'input')}
          </Grid>
        </Grid>
      </Fragment>
    );
  }

  renderForm() {
    const { classes, handleSubmit, pristine, valid, reset } = this.props;
    const { disableSubmit } = this.state;
    return (
      <form
        className={classes.form}
        onSubmit={handleSubmit(async (values) => {
          this.setState({ disableSubmit: true });
          const res = await this.props.submitNewFamilyMembers(
            values,
            this.props.history
          );
          if (res && res.error) this.setState({ disableSubmit: false });
          if (res && res.data) {
            this.props.reset();
            this.setState({ wasMemberAdded: true, disableSubmit: false });
          }
        })}
      >
        <Grid container direction="row" spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5" color="primary">
              Add Family Member
            </Typography>
          </Grid>
          <Grid item xs={12} container direction="row">
            <Grid item xs={12} container direction="row">
              <Grid item xs={12}>
                {this.addOneFamilyMember(0)}
              </Grid>
              <Grid item xs={12}>
                <Link style={{ textDecoration: 'none' }} to="/dashboard">
                  <Button
                    startIcon={<ArrowBackIos />}
                    variant="outlined"
                    color="primary"
                    className={classes.alignButtonLeft}
                    onClick={() => reset()}
                    disabled={disableSubmit}
                  >
                    Back
                  </Button>
                </Link>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.alignButton}
                  type="submit"
                  disabled={disableSubmit || pristine || !valid}
                >
                  Submit
                  {disableSubmit && (
                    <CircularProgress
                      size={15}
                      color="white"
                      style={{
                        marginLeft: 5,
                      }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    );
  }

  renderMemberLimitReached() {
    return (
      <Grid container direction="column" alignItems="center" justify="center">
        <Grid item style={{ marginBottom: '20px' }}>
          <Typography variant="h6" align="center">
            You have already added the maximum number of family members.
          </Typography>
        </Grid>
        <Grid item>
          <Link style={{ textDecoration: 'none' }} to="/dashboard">
            <Button variant="contained" color="primary">
              Ok
            </Button>
          </Link>
        </Grid>
        <br />
        <Typography>{this.state.errorMessage}</Typography>
      </Grid>
    );
  }

  renderMemberAdded() {
    return (
      <Grid container direction="column" alignItems="center" justify="center">
        <Grid item style={{ marginBottom: '20px' }}>
          <Typography variant="h6" color="primary" align="center">
            Member Added Successfully
          </Typography>
        </Grid>
        <Grid item container justify="space-between">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => this.setState({ wasMemberAdded: false })}
            >
              Add More
            </Button>
          </Grid>
          <Grid item>
            <Link style={{ textDecoration: 'none' }} to="/dashboard">
              <Button variant="contained" color="primary">
                Ok
              </Button>
            </Link>
          </Grid>
        </Grid>
        <br />
        <Typography>{this.state.errorMessage}</Typography>
      </Grid>
    );
  }
  // eslint-disable-next-line class-methods-use-this
  renderSexField(name, type) {
    return (
      <Field
        name={name}
        type={type}
        // placeholder={placeholder}
        multiple
        format={(value) => value || ''}
        onBlur={null}
        component={SexField}
      />
    );
  }
  // eslint-disable-next-line class-methods-use-this
  renderGenderField(name, type) {
    return (
      <Field
        name={name}
        type={type}
        // placeholder={placeholder}
        multiple
        format={(value) => value || ''}
        onBlur={null}
        component={GenderField}
      />
    );
  }
  // eslint-disable-next-line class-methods-use-this
  renderFields(name, type) {
    return <Field name={name} type={type} component={ProfileField} />;
  }
  // eslint-disable-next-line class-methods-use-this
  renderPostalCodeFields(name, type) {
    if (this.state.country === 'Canada') {
      return (
        <Field
          name={name}
          type={type}
          component={ProfileField}
          {...postalCodeMask}
        />
      );
    }
    return <Field name={name} type={type} component={ProfileField} />;
  }
  renderPhoneFields(name, type) {
    if (this.state.country === 'Canada') {
      return (
        <Field
          name={name}
          type={type}
          component={ProfileField}
          {...phoneMask}
        />
      );
    }
    return (
      <Field
        name={name}
        type={type}
        component={ProfileField}
        {...phoneMaskInternational}
      />
    );
  }

  ProvinceField = ({ input, meta: { touched, error } }) => (
    <FormControl style={{ width: 250, marginTop: 16 }}>
      <Select {...input} error={touched && error} helperText={touched && error}>
        {this.state.regionsForCountry.map((obj) => (
          <MenuItem value={obj.name} key={obj.name}>
            {obj.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  renderProvinceFields = (name, type) => {
    if (this.state.country) {
      return (
        <Field
          name={name}
          type={type}
          format={(value) => value || []}
          regionsForCountry={this.state.regionsForCountry}
          component={this.ProvinceField}
        />
      );
    }
    return (
      <Field
        name={name}
        type={type}
        placeholder={'Please Input Your Country First'}
        disabled={true}
        component={ProfileField}
      />
    );
  };
  // eslint-disable-next-line class-methods-use-this
  CountryField = ({ input, meta: { touched, error } }) => (
    <FormControl style={{ width: 250, marginTop: 16 }}>
      <Select {...input} error={touched && error} helperText={touched && error}>
        {countryRegionFields.map((lang) => (
          <MenuItem value={lang.countryName} key={lang.countryName}>
            {lang.countryName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
  renderCountryFields(name, type) {
    return (
      <Field
        name={name}
        type={type}
        format={(value) => value || []}
        component={this.CountryField}
        onChange={(event) => {
          this.setState({
            country: event.target.value,
          });
          const countryRegion = countryRegionFields.find(
            (obj) => obj.countryName === event.target.value
          );

          if (countryRegion) {
            this.setState({
              regionsForCountry: countryRegion.regions,
            });
          }
        }}
      />
    );
  }
  // eslint-disable-next-line class-methods-use-this
  renderDateField(name, type, hasDateSupport) {
    return (
      <Field
        name={name}
        type={type}
        component={hasDateSupport ? DateField : SafariDatePicker}
      />
    );
  }
  // eslint-disable-next-line class-methods-use-this
  renderFamilyCheckbox(id, name, type, label) {
    return (
      <Field
        id={id}
        name={name}
        type={type}
        label={label}
        parse={(value) => (value ? label : false)}
        component={FamilyAccountCheckField}
      />
    );
  }

  render() {
    const { classes } = this.props;
    const { numberOfAllowedAdditionalMembers, wasMemberAdded } = this.state;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            {numberOfAllowedAdditionalMembers > 0 &&
              !wasMemberAdded &&
              this.renderForm()}
            {numberOfAllowedAdditionalMembers === 0 &&
              this.renderMemberLimitReached()}
            {wasMemberAdded &&
              numberOfAllowedAdditionalMembers !== 0 &&
              this.renderMemberAdded()}
          </Paper>
        </main>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = {};

  const today = new Date();

  let numberAbove18 = accountAdultNumber;

  if (!values.family_member_first_name) {
    errors.family_member_first_name = 'Field required';
  }
  if (!values.family_member_last_name) {
    errors.family_member_last_name = 'Field required';
  }
  if (!values.family_member_date_of_birth) {
    errors.family_member_date_of_birth = 'Field required';
  }
  if (!values.family_member_gender) {
    errors.family_member_gender = 'Field required';
  }
  if (!values.family_member_sex) {
    errors.family_member_sex = 'Field required';
  }

  let dateOfBirth = new Date(values.family_member_date_of_birth);
  dateOfBirth = new Date(
    dateOfBirth.getTime() + dateOfBirth.getTimezoneOffset() * 60 * 1000
  );

  if (dateOfBirth > today) {
    errors.family_member_date_of_birth = 'Invalid date';
  }
  // validating age
  if (today.getFullYear() - dateOfBirth.getFullYear() > 18) {
    numberAbove18++;
    if (numberAbove18 > 2) {
      errors.family_member_date_of_birth =
        'Only 2 adults per account are allowed.';
    }
  } else if (today.getFullYear() - dateOfBirth.getFullYear() === 18) {
    if (today.getMonth() > dateOfBirth.getMonth()) {
      numberAbove18++;
      if (numberAbove18 > 2) {
        errors.family_member_date_of_birth =
          'Only 2 adults per account are allowed.';
      }
    } else if (
      today.getMonth() === dateOfBirth.getMonth() &&
      today.getDate() >= dateOfBirth.getDate()
    ) {
      numberAbove18++;
      if (numberAbove18 > 2) {
        errors.family_member_date_of_birth =
          'Only 2 adults per account are allowed.';
      }
    }
  }

  return errors;
}

AdditionalFamilyMembers.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  submitNewFamilyMembers: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  memberLimit: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

ProfileField.propTypes = {
  input: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

DateField.propTypes = {
  input: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

FamilyAccountCheckField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

GenderField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

SexField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

SafariDatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
};

CustomInput.propTypes = {
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
};

const ConnectedAdditionalFamilyMembers = connect(
  (state) => ({
    form: state.form,
    auth: state.auth,
    memberLimit: state.smartFamilyPlan.memberLimit,
  }),
  actions
)(AdditionalFamilyMembers);

const AdditionalFamilyMembersRedux = reduxForm({
  validate,
  form: 'profileForm',
})(ConnectedAdditionalFamilyMembers);

export default withStyles(styles)(AdditionalFamilyMembersRedux);
