import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import axios from 'axios';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../actions';

class SessionNotification extends Component {
  constructor(props) {
    super(props);
    this.socket = props.socket;
    this.state = {
      message: '',
      open: false,
      messageTimer: undefined,
      logoutTimer: undefined,
      connected: false,
    };
  }
  componentDidUpdate() {
    if (this.props.auth._id) {
      // only connect to server if logged in
      if (this.socket.disconnected && !this.state.connected) {
        this.socket.connect();
        this.setState({ connected: true });
      }
    }
  }

  componentDidMount() {
    // socket event listener
    this.socket.on('session-timeout', async ({ exp }) => {
      if (
        this.props.appointmentParams &&
        this.props.appointmentParams.sessionId
      ) {
        await this.extendSession();
      } else if (!this.state.logoutTimer && !this.state.messageTimer) {
        const expDate = new Date(exp);
        let msToExp = expDate - Date.now();
        let min = Math.floor(msToExp / (1000 * 60));
        let sec = Math.floor((msToExp % 60000) / 1000);

        if (sec === 60) {
          min += 1;
          sec = 0;
        }

        const formattedSec = sec.toString().padStart(2, '0');

        const time = `${min}:${formattedSec}`;

        this.setState({
          open: true,
          message: `Your session will expire in ${time}.`,
        });

        // update timer every second
        const messageTimer = setInterval(() => {
          let m = Math.floor(msToExp / (1000 * 60));
          let s = Math.floor((msToExp % 60000) / 1000);

          if (s === 60) {
            m += 1;
            s = 0;
          }

          const newSec = s.toString().padStart(2, '0');

          const newTime = `${m}:${newSec}`;

          this.setState({
            open: true,
            message: `Your session will expire in ${newTime}.`,
          });

          msToExp -= 1000;
        }, 1000);

        const logoutTimer = setTimeout(this.handleLogout, msToExp - 1000);

        this.setState({
          messageTimer,
          logoutTimer,
        });
      }
    });
  }

  extendSession = async () => {
    await axios.get('/api/auth/session/extend');
    this.clearTimers();
  };

  clearTimers = () => {
    clearInterval(this.state.messageTimer);
    clearTimeout(this.state.logoutTimer);
    this.setState({
      messageTimer: undefined,
      logoutTimer: undefined,
    });
  };

  closeDialog = () => {
    this.setState({
      open: false,
      message: '',
    });
  };

  // button handlers
  handleExtend = async () => {
    this.closeDialog();
    await this.extendSession();
    this.clearTimers();
  };

  handleContinue = () => {
    this.closeDialog();
  };

  handleLogout = async () => {
    this.closeDialog();
    this.clearTimers();
    this.props.logout(this.props.auth.role, null, this.props.history);
  };

  render() {
    return (
      <Fragment>
        <Dialog
          open={this.state.open}
          onClose={this.handleContinue}
          disableBackdropClick="true"
        >
          <DialogTitle>Your session will end soon.</DialogTitle>
          <DialogContent>
            <DialogContentText>{this.state.message}</DialogContentText>
            <br />
            <DialogContentText>
              You will automatically be logged out when your session is over.
              Any unsaved progress will be lost. If you require more time,
              select keep working. Dismissing this notification will cause you
              to be logged out when the session is over.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={this.handleExtend}>
              Keep Working
            </Button>
            <Button variant="contained" onClick={this.handleContinue}>
              Dismiss
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleLogout}
            >
              Log Out
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  appointmentParams: state.appointmentParams,
});

SessionNotification.propTypes = {
  auth: PropTypes.object.isRequired,
  appointmentParams: PropTypes.object,
  logout: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  socket: PropTypes.object.isRequired,
};

const ConnectedSessionNotification = connect(
  mapStateToProps,
  actions
)(SessionNotification);

export default withRouter(ConnectedSessionNotification);
