import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { Toaster } from 'react-hot-toast';
import { CssBaseline } from '@material-ui/core';

import MainRoutes from './routes/MainRoutes';
import PatientRoutes from './routes/PatientRoutes';
import DoctorRoutes from './routes/DoctorRoutes';
import PharmacyRoutes from './routes/PharmacyRoutes';
import Header from './Header';
import Footer from './Footer';
import '../styles/stickyfooter.css';
import '../styles/main-style.css';
import '../styles/header.css';
import NotificationProvider from './commonform/NotificationProvider';
import HTTPErrorHandler from './commonform/HTTPErrorHandler';
import { QueueProvider } from '../contexts/QueueContext';
import { DoctorListProvider } from '../contexts/DoctorListContext';
import theme from './SnapMedTheme';
import * as actions from '../actions';

class App extends Component {
  componentDidMount() {
    this.props.fetchLocationInfo();
    this.props.fetchUser();
  }

  render() {
    const userLoggedIn = this.props.auth && this.props.auth.name;
    return (
      <MuiThemeProvider theme={theme}>
        <Fragment>
          <CssBaseline />
          <Toaster position="bottom-right" />
          <BrowserRouter>
            <div className="Site">
              <NotificationProvider />
              <HTTPErrorHandler />
              <QueueProvider>
                <DoctorListProvider>
                  <div className="Site-content">
                    {!userLoggedIn && <MainRoutes />}
                    {userLoggedIn && this.props.auth.role === 'patient' && (
                      <div className="Site" key="1">
                        <Header />
                        <div className="Site-content">
                          <PatientRoutes />
                        </div>
                        <Footer />
                      </div>
                    )}
                    {userLoggedIn && this.props.auth.role === 'doctor' && (
                      <div className="Site" key="2">
                        <Header />
                        <div className="Site-content">
                          <DoctorRoutes />
                        </div>
                        <Footer />
                      </div>
                    )}
                    {userLoggedIn && this.props.auth.role === 'pharmacy' && (
                      <div className="Site" key="3">
                        <Header />
                        <PharmacyRoutes />
                      </div>
                    )}
                  </div>
                </DoctorListProvider>
              </QueueProvider>
            </div>
          </BrowserRouter>
        </Fragment>
      </MuiThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    appointmentParams: state.appointmentParams,
  };
}

App.propTypes = {
  auth: PropTypes.object,
  fetchLocationInfo: PropTypes.func,
  fetchUser: PropTypes.func,
};

export default connect(mapStateToProps, actions)(App);
