import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as actions from '../../../actions';
import LoginForm from './DoctorLoginForm';

class DoctorLoginRoot extends Component {
  constructor(props) {
    super(props);
    this.onLoginSubmit = this.onLoginSubmit.bind(this);
  }
  onLoginSubmit(values) {
    this.props.doctorLogin(values, this.props.history);
  }
  render() {
    return (
      <div>
        <LoginForm onSubmit={this.onLoginSubmit} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    form: state.form,
  };
}

DoctorLoginRoot.propTypes = {
  doctorLogin: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, actions)(withRouter(DoctorLoginRoot));
