import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { injectStripe, CardElement } from 'react-stripe-elements';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import axios from 'axios';
import * as actions from '../../actions';

import 'typeface-roboto';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 500,
      marginBottom: '65px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  disclaimer: {
    fontSize: 10,
  },
  empoweredHealth: {
    fontSize: 10,
    color: 'orange',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  input: {
    width: '100%',
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  link: {
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
    width: '100px',
  },
  cardElementWrapper: {
    padding: '10px',
    borderRadius: '5px',
    boxShadow: '0px 0px 3px 3px #cecece',
  },
  cardElement: {
    width: '100%',
  },
});

class ReplaceCreditCardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complete: false,
      errorMessage: '',
      paymentIntent: '',
      disableButton: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(ev) {
    ev.preventDefault();
    this.setState({
      errorMessage: '',
      disableButton: true,
    });
    const card = await this.props.elements.getElement('card');
    const paymentMethod = await this.props.stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        email: this.props.auth.email,
      },
    });
    if (paymentMethod.error) {
      this.setState({
        errorMessage: paymentMethod.error.message,
        disableButton: false,
      });
    } else {
      try {
        const newPaymentMethodResponse = await axios.post(
          '/api/billing/replace_credit_card',
          {
            oldCardId: this.props.oldCardId,
            ...paymentMethod,
          }
        );
        if (newPaymentMethodResponse.data.error) {
          this.setState({
            errorMessage: 'The card was declined. Please try again.',
            disableButton: false,
          });
        } else {
          this.props.openModal(false);
          this.props.getCardList();
        }
      } catch (err) {
        this.setState({
          errorMessage: 'Something went wrong! Please try again',
          disableButton: false,
        });
      }
    }
  }
  render() {
    const { classes } = this.props;

    return (
      <Grid container xs={12}>
        <form onSubmit={this.handleSubmit} style={{ width: '100%' }}>
          <Grid item xs={12} className={classes.cardElementWrapper}>
            <CardElement
              className={classes.cardElement}
              hidePostalCode={true}
              style={{
                base: {
                  fontSize: '15px',
                  color: '#424770',
                  letterSpacing: '0.025em',
                  fontFamily: 'Source Code Pro, monospace',
                  '::placeholder': {
                    color: '#95a1ad',
                  },
                  padding: '10px',
                },
                invalid: {
                  color: '#9e2146',
                },
              }}
            />
          </Grid>
          <Grid
            item
            container
            spacing={1}
            justify="flex-end"
            style={{ marginTop: '15px' }}
          >
            <Grid item>
              <Button onClick={() => this.props.openModal(false)}>
                dismiss
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="contained"
                textPrimary="hi"
                color="primary"
                disabled={this.state.disableButton}
              >
                Save Card
                {this.state.disableButton && (
                  <CircularProgress
                    size={15}
                    color="white"
                    style={{
                      marginLeft: 5,
                    }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid container justify="flex-end" xs={12}>
          <Typography color="error">{this.state.errorMessage}</Typography>
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

ReplaceCreditCardForm.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  elements: PropTypes.object.isRequired,
  stripe: PropTypes.object.isRequired,
  openModal: PropTypes.func.isRequired,
  getCardList: PropTypes.func.isRequired,
  oldCardId: PropTypes.string.isRequired,
};

const StyledReplaceCreditCardForm = withStyles(styles)(ReplaceCreditCardForm);

export default injectStripe(
  connect(mapStateToProps, actions)(withRouter(StyledReplaceCreditCardForm))
);
