import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Button, Typography, Grid } from '@material-ui/core';
import * as actions from '../../actions';

const FamilyMedicalFormCompletionPrompts = (props) => {
  const medicalPromptsArray = _.map(
    props.medicalProfileCompletion,
    (member) => {
      let url = member.id;

      if (!member.completed && member.isActive) {
        url = `/medicalprofileform/${url}`;
        return (
          <Grid item>
            <Fragment key={member}>
              <Typography variant="body1">
                {member.name.first} needs to complete their Medical Profile in
                order to see a doctor:
              </Typography>
              <Button
                disabled={props.disabled}
                variant="contained"
                color="primary"
                style={{
                  maxWidth: 220,
                }}
                onClick={() => {
                  props.selectedUserChange({ id: member.id }).then(() => {
                    props.history.push(url);
                  });
                }}
              >
                Complete Medical Profile - {member.name.first}
              </Button>
            </Fragment>
          </Grid>
        );
      }
      return null;
    }
  );

  return <Fragment>{medicalPromptsArray}</Fragment>;
};

FamilyMedicalFormCompletionPrompts.propTypes = {
  medicalProfileCompletion: PropTypes.array,
  selectedUserChange: PropTypes.func,
  history: PropTypes.object,
  disabled: PropTypes.bool,
};

export default connect(null, actions)(FamilyMedicalFormCompletionPrompts);
