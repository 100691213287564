/* eslint-disable default-param-last, func-names */
import { TIMER_DURATION } from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case TIMER_DURATION:
      return action.payload || false;
    default:
      return state;
  }
}
