import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import { Info } from '@material-ui/icons';

import DropzoneInput from '../commonform/FileInput';
import * as actions from '../../actions';

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointmentInfo: {},
      open: props.open,
    };
  }

  componentDidMount() {
    if (this.props.appointmentInfo) {
      this.setState({ appointmentInfo: this.props.appointmentInfo });
    }
    if (this.props.patientId && this.props.appointmentId) {
      this.setState({
        appointmentInfo: {
          patientId: this.props.patientId,
          appointmentId: this.props.appointmentId,
        },
      });
    }
  }

  componentDidUpdate() {
    if (this.props.open !== this.state.open) {
      this.setState({
        open: this.props.open,
      });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.onClose && typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  };

  render() {
    return (
      <Dialog
        fullWidth
        maxWidth="md"
        open={this.state.open}
        // onClose={this.handleClose} // This is disabled to prevent the dialog from closing when clicking outside the dialog
      >
        <DialogTitle>
          <Typography variant="h5" color="primary">
            Upload Files
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <List dense>
              <ListItem>
                <ListItemIcon>
                  <Info color="primary" />
                </ListItemIcon>
                <ListItemText primary="File size must be less than 3MB." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Info color="primary" />
                </ListItemIcon>
                <ListItemText primary="Files must be in .jpg, .jpeg or .png format (images only)." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Info color="primary" />
                </ListItemIcon>
                <ListItemText primary="Total file size must be 9MB." />
              </ListItem>
            </List>
          </Grid>
          <DropzoneInput
            patientId={this.state.appointmentInfo.patientId}
            appointmentId={this.state.appointmentInfo.appointmentId}
            closeComponent={this.handleClose}
            disableFileFetch={this.props.fileList.length === 0}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.appointmentParams.patientId,
    appointmentId: state.appointmentParams.appointmentId,
    fileList: state.appointmentParams.appointment.fileList,
    auth: state.auth,
  };
}

FileUpload.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  patientId: PropTypes.string,
  appointmentId: PropTypes.string,
  auth: PropTypes.object.isRequired,
};

const ConnectedFileUpload = connect(mapStateToProps, actions)(FileUpload);

export default withRouter(ConnectedFileUpload);
