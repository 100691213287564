import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Typography, Grid, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class CheckoutFormFiveCreditPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      price: 0,
    };
  }

  async componentDidMount() {
    const res = await axios.get('/api/billing/stripe/FiveCreditPackageData');
    this.setState({ price: res.data.price });
  }

  render() {
    return (
      <Fragment>
        <Grid container spacing={1} justify="center">
          <Grid item container xs={12} sm={8}>
            <Grid item xs={12}>
              <Typography variant="h6" color="primary">
                5 Visits Package:
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                Our <b>5 Visits package</b> option will give you 5 appointments
                with a Canadian doctor for only <b>${this.state.price} CAD</b>.
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={4} justify="center" alignItems="center">
            <Button variant="contained" color="primary" size="small">
              <Link
                style={{ color: 'white', textDecoration: 'none' }}
                to={
                  this.props.origin === 'dashboard'
                    ? '/PaymentPlanMembership/FiveCreditPackage/dashboard'
                    : '/PaymentPlanMembership/FiveCreditPackage/appointment'
                }
              >
                Select
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

CheckoutFormFiveCreditPackage.propTypes = {
  origin: PropTypes.string,
};

export default CheckoutFormFiveCreditPackage;
