import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { reduxForm, Field } from 'redux-form';
import { createTextMask } from 'redux-form-input-masks';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Card,
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControl,
  CircularProgress,
  Backdrop,
  Chip,
  Collapse,
  withStyles,
} from '@material-ui/core';
import {
  InfoOutlined,
  LocationOn,
  Search,
  LocalShipping,
  NotInterested,
  LocalOffer,
  Add,
} from '@material-ui/icons';

import * as actions from '../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 80,
      maxWidth: 600,
    },
  },
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
  },
  pharmacyField: {
    padding: '10px',
    backgroundColor: 'aliceblue',
    minHeight: '173px',
  },
  pharmacyButton: {
    textAlign: 'left',
  },
  dialogContainer: {
    marginBottom: '20px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  cancelButton: {
    borderColor: '#ff4a4a',
    color: '#ff4a4a',
  },
});

const customPostalCodeMaskDefinitions = {
  9: {
    regExp: /[0-9]/,
  },
  A: {
    regExp: /[A-Za-z]/,
    transform: (char) => char.toUpperCase(),
  },
};

const postalCodeMask = createTextMask({
  pattern: 'A9A 9A9',
  guide: false,
  stripMask: false,
  maskDefinitions: customPostalCodeMaskDefinitions,
});

const SearchField = (props) => (
  <InputMask mask="a9a 9a9" onChange={props.onChange} maskChar="">
    {(inputProps) => (
      <TextField
        {...inputProps}
        variant="outlined"
        type="text"
        label="Search by postal code"
        disableUnderline
        inputProps={{ style: { textTransform: 'uppercase' } }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <IconButton
              edge="end"
              color="primary"
              disabled={props.searchValue === ''}
              onClick={() => props.submitSearch()}
            >
              <Search position="end" />
            </IconButton>
          ),
        }}
      />
    )}
  </InputMask>
);

const FormTextField = ({
  name,
  label,
  type,
  disabled,
  input,
  meta: { touched, error },
}) => (
  <FormControl fullWidth variant="outlined">
    <TextField
      {...input}
      id={name}
      label={label}
      type={type}
      variant="outlined"
      disabled={disabled}
      fullWidth
      error={touched && error}
      helperText={touched && error}
    />
  </FormControl>
);

const PhoneNumberField = ({ input, label, name, meta: { touched, error } }) => (
  <InputMask mask="(999) 999-9999" {...input} onChange={input.onChange}>
    {(inputProps) => (
      <TextField
        {...inputProps}
        fullWidth
        name={name}
        label={label}
        variant="outlined"
        error={touched && error}
        helperText={touched && error}
      />
    )}
  </InputMask>
);

class PharmacyInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPharmacyDialog: false,
      openNewPharmacyDialog: false,
      openPromotionsDialog: false,
      openInfoDialog: false,
      selectedPharmacy: '',
      selectedDefaultPharmacy: false,
      suggestedPharmacies: [],
      newPharmacy: '',
      pharmacyLoading: false,
      searchValue: '',
      resultsPostalCode: '',
      openManualPharmacyWarning: false,
      openManualPharmacyForm: false,
    };
  }

  async componentDidMount() {
    const result = await axios.post('/api/profile/patient/pharmacy/suggested', {
      postalCode: this.props.auth.zipCode,
    });
    if (result.data) {
      this.setState({
        suggestedPharmacies: result.data,
      });
    }
    setTimeout(() => {
      document.querySelector('#toolbar-section').scrollIntoView({
        behavior: 'smooth',
      });
    }, 500);
  }

  closePharmacyDialog = () => {
    this.setState({
      selectedPharmacy: '',
      openPharmacyDialog: false,
      selectedDefaultPharmacy: false,
    });
  };

  closeNewPharmacyDialog = () => {
    this.setState({ openNewPharmacyDialog: false, newPharmacy: '' });
    this.props.reset();
  };

  closePromotionsDialog = () => {
    this.setState({ openPromotionsDialog: false, newPharmacy: '' });
  };

  handleSelectPharmacy(pharmacy) {
    this.setState({ selectedPharmacy: pharmacy, openPharmacyDialog: true });
  }

  handleSubmitSearch = async () => {
    this.setState({ pharmacyLoading: true });
    const result = await axios.post('/api/profile/patient/pharmacy/suggested', {
      postalCode: this.state.searchValue,
    });
    if (result.data) {
      this.setState({
        suggestedPharmacies: result.data,
        pharmacyLoading: false,
        resultsPostalCode: this.state.searchValue,
      });
    } else {
      this.setState({ pharmacyLoading: false });
    }
  };

  handleCloseManualPharmacy = (confirm) => {
    this.setState({
      openManualPharmacyWarning: false,
      openManualPharmacyForm: confirm,
    });
  };

  handleShowManualPharmacyDialog = () => {
    if (this.state.suggestedPharmacies.length > 0) {
      this.setState({ openManualPharmacyWarning: true });
    } else {
      this.setState({ openManualPharmacyForm: true });
    }
  };

  async handleSubmitNewPharmacy(values) {
    const newPharmacy = {
      name: values.newPharmacyName,
      faxNumber: `+1 ${values.newPharmacyFaxNumber}`,
      phoneNumber: `+1 ${values.newPharmacyPhoneNumber}`,
      mobileNumber: values.newPharmacyMobileNumber
        ? `+1 ${values.newPharmacyMobileNumber}`
        : '',
      address: {
        street: values.newPharmacyAddress,
        city: values.newPharmacyCity,
        province: values.newPharmacyProvince,
        postalCode: values.newPharmacyPostalCode,
      },
      registeredPharmacy: false,
    };
    try {
      const result = await axios.post('/api/profile/patient/pharmacy', {
        pharmacy: newPharmacy,
      });
      if (result.data) {
        this.props.fetchUser();
        this.setState({ openManualPharmacyForm: false });
        this.closeNewPharmacyDialog();
      }
    } catch (err) {
      this.closeNewPharmacyDialog();
    }
  }

  async handleSubmitPremiumPharmacy() {
    try {
      const result = await axios.post('/api/profile/patient/pharmacy', {
        pharmacy: {
          premiumPharmacy: this.state.selectedPharmacy.pharmacyObj._id,
        },
      });
      if (result.data) {
        this.props.fetchUser();
        this.closePharmacyDialog();
      }
    } catch (err) {
      this.closePharmacyDialog();
    }
  }

  async handleRemoveDefaultPharmacy() {
    const updateObj = {
      name: '',
      faxNumber: '',
      phoneNumber: '',
      mobileNumber: '',
      address: {
        street: '',
        city: '',
        province: '',
        postalCode: '',
      },
      registeredPharmacy: false,
      premiumPharmacy: '',
    };
    try {
      const result = await axios.post('/api/profile/patient/pharmacy', {
        pharmacy: { updateObj },
      });
      if (result.data) {
        this.props.fetchUser();
        this.closePharmacyDialog();
      }
    } catch (err) {
      this.closePharmacyDialog();
    }
  }

  renderSuggestedPharmacies() {
    const { classes } = this.props;
    const { suggestedPharmacies } = this.state;
    return suggestedPharmacies.length > 0 ? (
      suggestedPharmacies.map((suggestion, index) => {
        const { distance, pharmacyObj: pharmacy } = suggestion;
        return (
          <Grid item xs={12} sm={6} key={index}>
            <ButtonBase
              classes={{
                root: classes.pharmacyButton,
              }}
              onClick={() => {
                this.handleSelectPharmacy(suggestion);
              }}
            >
              <Card className={classes.pharmacyField}>
                <Grid container>
                  <Grid item xs={12} container justify="flex-end">
                    <LocationOn color="primary" />
                    <Typography color="primary" align="right">
                      {distance} km
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6" color="primary">
                      {pharmacy.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>{pharmacy.address.street}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {pharmacy.address.city}, {pharmacy.address.province}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>{pharmacy.address.postalCode}</Typography>
                  </Grid>
                  {pharmacy.promotions.length > 0 && (
                    <Grid item xs={12}>
                      <Typography variant="button" style={{ color: 'green' }}>
                        Promotions Available
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Card>
            </ButtonBase>
          </Grid>
        );
      })
    ) : (
      <Grid item xs={12} sm={6}>
        <Typography variant="h5" color="error">
          No results found
        </Typography>
      </Grid>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  buildTimeString(value) {
    let date;
    let meridiem;
    let hourString;
    if (value) {
      date = new Date(value);
      date = date.toLocaleTimeString('en-US').split(':');
      // .pop() assigns the removed element to variable 'openMeridiem' and also changes the array 'open'
      meridiem = date.pop();
      meridiem = meridiem.slice(2);
      hourString = `${date[0]}:${date[1]}${meridiem}`;
    } else {
      hourString = 'CLOSED';
    }
    return hourString;
  }

  // eslint-disable-next-line class-methods-use-this
  buildChipLabel(value) {
    if (value) {
      const date = new Date(value);
      const cutoff = date.toLocaleTimeString('en-US').split(':');
      // .pop() assigns the removed element to variable 'cutoffMeridiem' and also changes the array 'cutoff'
      let cutoffMeridiem = cutoff.pop();
      cutoffMeridiem = cutoffMeridiem.slice(2);
      return `${cutoff[0]}:${cutoff[1]}${cutoffMeridiem}`;
    }
    return 'No delivery';
  }

  render() {
    const { classes, handleSubmit } = this.props;
    const {
      openPharmacyDialog,
      selectedPharmacy,
      selectedDefaultPharmacy,
      openNewPharmacyDialog,
      openPromotionsDialog,
      openInfoDialog,
      newPharmacy,
      pharmacyLoading,
      resultsPostalCode,
      openManualPharmacyWarning,
      openManualPharmacyForm,
    } = this.state;

    return (
      <Fragment>
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid container direction="row">
              <Grid
                item
                container
                style={{ margin: '50px 0px' }}
                alignItems="center"
              >
                <Grid item xs={9} sm={10}>
                  <Typography variant="h5" color="primary">
                    Your Pharmacy Information
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <ButtonBase
                    classes={{
                      root: classes.pharmacyButton,
                    }}
                    onClick={() => {
                      this.setState({ selectedDefaultPharmacy: true });
                      this.handleSelectPharmacy({
                        pharmacyObj: this.props.auth.patient.pharmacy
                          .premiumPharmacy
                          ? this.props.auth.patient.pharmacy.premiumPharmacy
                          : this.props.auth.patient.pharmacy,
                      });
                    }}
                  >
                    <Card>
                      <Grid container className={classes.pharmacyField}>
                        <Grid
                          item
                          xs={12}
                          style={{
                            borderBottom: '1px solid lightgray',
                            marginBottom: '10px',
                          }}
                        >
                          <Typography
                            variant="body1"
                            align="center"
                            color="primary"
                          >
                            Preferred Pharmacy
                          </Typography>
                        </Grid>
                        {this.props.auth.patient.pharmacy.premiumPharmacy && (
                          <div style={{ width: '100%' }}>
                            {/* <Grid item xs={12} container justify='flex-end'>
                              <LocationOn color='primary'/>
                              <Typography  color="primary" align='right'>
                                {this.props.auth.patient.pharmacy.pharmacyDistance} km
                              </Typography>
                            </Grid> */}
                            <Grid item>
                              <Typography variant="h6" color="primary">
                                {
                                  this.props.auth.patient.pharmacy
                                    .premiumPharmacy.name
                                }
                              </Typography>
                              <Typography>
                                {
                                  this.props.auth.patient.pharmacy
                                    .premiumPharmacy.address.street
                                }
                              </Typography>
                              <Typography>
                                {
                                  this.props.auth.patient.pharmacy
                                    .premiumPharmacy.address.city
                                }
                                ,{' '}
                                {
                                  this.props.auth.patient.pharmacy
                                    .premiumPharmacy.address.province
                                }
                              </Typography>
                              <Typography>
                                {
                                  this.props.auth.patient.pharmacy
                                    .premiumPharmacy.address.postalCode
                                }
                              </Typography>
                              <Typography>
                                Fax:{' '}
                                {
                                  this.props.auth.patient.pharmacy
                                    .premiumPharmacy.faxNumber
                                }
                              </Typography>
                              <Typography>
                                Phone:{' '}
                                {
                                  this.props.auth.patient.pharmacy
                                    .premiumPharmacy.phoneNumber
                                }
                              </Typography>
                            </Grid>
                          </div>
                        )}
                        {this.props.auth.patient.pharmacy.faxNumber && (
                          <div style={{ width: '100%' }}>
                            {/* <Grid item xs={12} container justify='flex-end'>
                              <LocationOn color='primary'/>
                              <Typography  color="primary" align='right'>
                                {this.props.auth.patient.pharmacy.pharmacyDistance} km
                              </Typography>
                            </Grid> */}
                            <Grid item>
                              <Typography variant="h6" color="primary">
                                {this.props.auth.patient.pharmacy.name}
                              </Typography>
                              <Typography>
                                {
                                  this.props.auth.patient.pharmacy.address
                                    .street
                                }
                              </Typography>
                              <Typography>
                                {this.props.auth.patient.pharmacy.address.city},{' '}
                                {
                                  this.props.auth.patient.pharmacy.address
                                    .province
                                }
                              </Typography>
                              <Typography>
                                {
                                  this.props.auth.patient.pharmacy.address
                                    .postalCode
                                }
                              </Typography>
                              <Typography>
                                Fax:{' '}
                                {this.props.auth.patient.pharmacy.faxNumber}
                              </Typography>
                              <Typography>
                                Phone:{' '}
                                {this.props.auth.patient.pharmacy.phoneNumber}
                              </Typography>
                            </Grid>
                          </div>
                        )}
                        {!this.props.auth.patient.pharmacy.faxNumber &&
                          !this.props.auth.patient.pharmacy.premiumPharmacy && (
                            <Grid
                              item
                              xs={12}
                              style={{ margin: '15px 0px' }}
                              container
                              justify="center"
                            >
                              <Typography variant="button" color="error">
                                No pharmacy information
                              </Typography>
                            </Grid>
                          )}
                      </Grid>
                    </Card>
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} container style={{ marginTop: '30px' }}>
                  <Grid item xs={10} sm={6}>
                    <Typography variant="h5" color="primary">
                      Suggested Pharmacies
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={6}>
                    <Tooltip
                      title="Suggestions are based on your postal code"
                      placement="top-start"
                      arrow
                    >
                      <IconButton
                        size="small"
                        onClick={async () => {
                          // const result = await axios.get(
                          //   'https://geocoder.ca/?auth=715093075578064449364x102093&locate=L9W+7G5&prov=ON&json=1'
                          // );
                          // console.log(result.data)
                          this.setState({ openInfoDialog: true });
                        }}
                      >
                        <InfoOutlined
                          color="primary"
                          style={{ fontSize: '30px' }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12}>
                    <Typography variant="body1">Results for:</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="primary">
                      {resultsPostalCode === ''
                        ? this.props.auth.zipCode
                        : resultsPostalCode}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} sm={6}>
                  <Grid item xs={12} container>
                    <Grid item xs={12}>
                      <SearchField
                        onChange={(e) =>
                          this.setState({
                            searchValue: e.target.value.toUpperCase(),
                          })
                        }
                        searchValue={this.state.searchValue}
                        submitSearch={this.handleSubmitSearch}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  {this.renderSuggestedPharmacies()}
                </Grid>
                <Grid item xs={12} container>
                  {!openManualPharmacyForm && (
                    <Button
                      onClick={this.handleShowManualPharmacyDialog}
                      variant="outlined"
                      color="primary"
                      size="small"
                      startIcon={<Add />}
                    >
                      Add Custom Pharmacy
                    </Button>
                  )}
                  <Collapse in={openManualPharmacyForm} timeout={'auto'}>
                    <Grid item xs={12}>
                      <Typography variant="h5" color="primary">
                        Add Pharmacy
                      </Typography>
                    </Grid>
                    <form
                      id="add-pharmacy-form"
                      onSubmit={handleSubmit((values) => {
                        this.setState({
                          newPharmacy: values,
                          openNewPharmacyDialog: true,
                        });
                      })}
                      style={{
                        width: '100%',
                        margin: '15px 0px',
                      }}
                    >
                      <Grid item xs={12} container spacing={2}>
                        <Grid item xs={12}>
                          <Field
                            name="newPharmacyName"
                            type="text"
                            label="Pharmacy Name"
                            component={FormTextField}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            name="newPharmacyAddress"
                            type="text"
                            label="Address"
                            component={FormTextField}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="newPharmacyCity"
                            type="text"
                            label="City"
                            component={FormTextField}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="newPharmacyProvince"
                            type="text"
                            label="Province"
                            component={FormTextField}
                            disabled={true}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="newPharmacyPostalCode"
                            type="text"
                            label="Postal Code"
                            component={FormTextField}
                            {...postalCodeMask}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="newPharmacyFaxNumber"
                            type="text"
                            label="Fax Number"
                            component={PhoneNumberField}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="newPharmacyPhoneNumber"
                            type="text"
                            label="Phone Number"
                            component={PhoneNumberField}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            name="newPharmacyMobileNumber"
                            type="text"
                            label="Mobile Number"
                            component={PhoneNumberField}
                          />
                        </Grid>
                        <Grid item container justify="flex-end">
                          <Grid item>
                            <Button
                              variant="outlined"
                              onClick={() =>
                                this.setState({ openManualPharmacyForm: false })
                              }
                              style={{
                                marginRight: '8px',
                              }}
                              className={this.props.classes.cancelButton}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              variant="contained"
                              color="primary"
                              disabled={this.props.pristine}
                            >
                              Submit
                              {/* verificationSubmitButton && (
                              <CircularProgress
                                size={15}
                                color="primary"
                                style={{
                                  marginLeft: 5
                                }}
                              />
                            ) */}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  </Collapse>
                </Grid>
              </Grid>
            </Grid>
            <Backdrop className={classes.backdrop} open={pharmacyLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </main>
        </Slide>
        <Dialog
          open={openInfoDialog}
          onClose={() => this.setState({ openInfoDialog: false })}
        >
          <DialogContent>
            <InfoOutlined color="primary" style={{ fontSize: '40px' }} />
            <DialogContentText>
              Pharmacy suggestions are based on existing Premium pharmacies
              within a 15 km radius centered on your postal code.
            </DialogContentText>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.setState({ openInfoDialog: false })}
              >
                Close
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        {selectedPharmacy ? (
          <Dialog
            open={openPharmacyDialog}
            onClose={this.closePharmacyDialog}
            fullWidth
          >
            <DialogTitle>
              {selectedDefaultPharmacy ? 'Preferred' : null} Pharmacy
              Information
            </DialogTitle>
            <DialogContent>
              <Grid container className={classes.dialogContainer}>
                {this.state.selectedPharmacy.pharmacyObj.faxNumber ? (
                  <Grid item xs={12} sm={5} style={{ marginBottom: '10px' }}>
                    <Grid item xs={12} style={{ marginBottom: '5px' }}>
                      <Grid item xs={12}>
                        <Typography variant="h5" color="primary">
                          {selectedPharmacy.pharmacyObj.name}
                        </Typography>
                      </Grid>
                      {selectedPharmacy.distance && (
                        <Grid item xs={6} container>
                          <LocationOn
                            color="primary"
                            style={{ fontSize: '20px' }}
                          />
                          <Typography variant="subtitle2" color="primary">
                            {selectedPharmacy.distance} km
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="primary">
                        {this.state.selectedPharmacy.pharmacyObj.address.street}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="primary">
                        {this.state.selectedPharmacy.pharmacyObj.address.city},{' '}
                        {
                          this.state.selectedPharmacy.pharmacyObj.address
                            .province
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="primary">
                        {
                          this.state.selectedPharmacy.pharmacyObj.address
                            .postalCode
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="primary">
                        Fax: {this.state.selectedPharmacy.pharmacyObj.faxNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2" color="primary">
                        Phone No:{' '}
                        {this.state.selectedPharmacy.pharmacyObj.phoneNumber}
                      </Typography>
                    </Grid>
                    {this.state.selectedPharmacy.pharmacyObj.promotions &&
                      this.state.selectedPharmacy.pharmacyObj.promotions
                        .length > 0 && (
                        <Grid item xs={12} style={{ marginTop: '15px' }}>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<LocalOffer color="primary" />}
                            onClick={() =>
                              this.setState({ openPromotionsDialog: true })
                            }
                          >
                            Promotions
                          </Button>
                        </Grid>
                      )}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} style={{ marginBottom: '10px' }}>
                    <Typography variant="button" color="error">
                      No pharmacy information
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm={7} container spacing={1}>
                  <Grid item xs={8}>
                    <Typography variant="subtitle2" color="primary">
                      Hours:
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" color="primary">
                      Delivery Cutoff:
                    </Typography>
                  </Grid>
                  <Grid item xs={2} container alignContent="center">
                    <Typography variant="subtitle2" color="primary">
                      Mon:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} container alignContent="center">
                    <Typography color="primary" variant="subtitle2">
                      <b>
                        {this.buildTimeString(
                          selectedPharmacy.pharmacyObj.hours.monday.open
                        )}
                      </b>
                      {selectedPharmacy.pharmacyObj.hours.monday.open
                        ? ' to '
                        : null}
                      <b>
                        {selectedPharmacy.pharmacyObj.hours.monday.open
                          ? this.buildTimeString(
                              selectedPharmacy.pharmacyObj.hours.monday.close
                            )
                          : null}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={4} container alignContent="center">
                    <Chip
                      size="small"
                      icon={
                        selectedPharmacy.pharmacyObj.hours.monday
                          .deliveryCutoff ? (
                          <LocalShipping />
                        ) : (
                          <NotInterested />
                        )
                      }
                      label={this.buildChipLabel(
                        selectedPharmacy.pharmacyObj.hours.monday.deliveryCutoff
                      )}
                      color={
                        selectedPharmacy.pharmacyObj.hours.monday.deliveryCutoff
                          ? 'primary'
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={2} container alignContent="center">
                    <Typography variant="subtitle2" color="primary">
                      Tue:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} container alignContent="center">
                    <Typography color="primary" variant="subtitle2">
                      <b>
                        {this.buildTimeString(
                          selectedPharmacy.pharmacyObj.hours.tuesday.open
                        )}
                      </b>
                      {selectedPharmacy.pharmacyObj.hours.tuesday.open
                        ? ' to '
                        : null}
                      <b>
                        {selectedPharmacy.pharmacyObj.hours.tuesday.open
                          ? this.buildTimeString(
                              selectedPharmacy.pharmacyObj.hours.tuesday.close
                            )
                          : null}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Chip
                      size="small"
                      icon={
                        selectedPharmacy.pharmacyObj.hours.tuesday
                          .deliveryCutoff ? (
                          <LocalShipping />
                        ) : (
                          <NotInterested />
                        )
                      }
                      label={this.buildChipLabel(
                        selectedPharmacy.pharmacyObj.hours.tuesday
                          .deliveryCutoff
                      )}
                      color={
                        selectedPharmacy.pharmacyObj.hours.tuesday
                          .deliveryCutoff
                          ? 'primary'
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={2} container alignContent="center">
                    <Typography variant="subtitle2" color="primary">
                      Wed:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} container alignContent="center">
                    <Typography color="primary" variant="subtitle2">
                      <b>
                        {this.buildTimeString(
                          selectedPharmacy.pharmacyObj.hours.wednesday.open
                        )}
                      </b>
                      {selectedPharmacy.pharmacyObj.hours.wednesday.open
                        ? ' to '
                        : null}
                      <b>
                        {selectedPharmacy.pharmacyObj.hours.wednesday.open
                          ? this.buildTimeString(
                              selectedPharmacy.pharmacyObj.hours.wednesday.close
                            )
                          : null}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Chip
                      size="small"
                      icon={
                        selectedPharmacy.pharmacyObj.hours.wednesday
                          .deliveryCutoff ? (
                          <LocalShipping />
                        ) : (
                          <NotInterested />
                        )
                      }
                      label={this.buildChipLabel(
                        selectedPharmacy.pharmacyObj.hours.wednesday
                          .deliveryCutoff
                      )}
                      color={
                        selectedPharmacy.pharmacyObj.hours.wednesday
                          .deliveryCutoff
                          ? 'primary'
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={2} container alignContent="center">
                    <Typography variant="subtitle2" color="primary">
                      Thu:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} container alignContent="center">
                    <Typography color="primary" variant="subtitle2">
                      <b>
                        {this.buildTimeString(
                          selectedPharmacy.pharmacyObj.hours.thursday.open
                        )}
                      </b>
                      {selectedPharmacy.pharmacyObj.hours.thursday.open
                        ? ' to '
                        : null}
                      <b>
                        {selectedPharmacy.pharmacyObj.hours.thursday.open
                          ? this.buildTimeString(
                              selectedPharmacy.pharmacyObj.hours.thursday.close
                            )
                          : null}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Chip
                      size="small"
                      icon={
                        selectedPharmacy.pharmacyObj.hours.thursday
                          .deliveryCutoff ? (
                          <LocalShipping />
                        ) : (
                          <NotInterested />
                        )
                      }
                      label={this.buildChipLabel(
                        selectedPharmacy.pharmacyObj.hours.thursday
                          .deliveryCutoff
                      )}
                      color={
                        selectedPharmacy.pharmacyObj.hours.thursday
                          .deliveryCutoff
                          ? 'primary'
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={2} container alignContent="center">
                    <Typography variant="subtitle2" color="primary">
                      Fri:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} container alignContent="center">
                    <Typography color="primary" variant="subtitle2">
                      <b>
                        {this.buildTimeString(
                          selectedPharmacy.pharmacyObj.hours.friday.open
                        )}
                      </b>
                      {selectedPharmacy.pharmacyObj.hours.friday.open
                        ? ' to '
                        : null}
                      <b>
                        {selectedPharmacy.pharmacyObj.hours.friday.open
                          ? this.buildTimeString(
                              selectedPharmacy.pharmacyObj.hours.friday.close
                            )
                          : null}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Chip
                      size="small"
                      icon={
                        selectedPharmacy.pharmacyObj.hours.friday
                          .deliveryCutoff ? (
                          <LocalShipping />
                        ) : (
                          <NotInterested />
                        )
                      }
                      label={this.buildChipLabel(
                        selectedPharmacy.pharmacyObj.hours.friday.deliveryCutoff
                      )}
                      color={
                        selectedPharmacy.pharmacyObj.hours.friday.deliveryCutoff
                          ? 'primary'
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={2} container alignContent="center">
                    <Typography variant="subtitle2" color="primary">
                      Sat:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} container alignContent="center">
                    <Typography color="primary" variant="subtitle2">
                      <b>
                        {this.buildTimeString(
                          selectedPharmacy.pharmacyObj.hours.saturday.open
                        )}
                      </b>
                      {selectedPharmacy.pharmacyObj.hours.saturday.open
                        ? ' to '
                        : null}
                      <b>
                        {selectedPharmacy.pharmacyObj.hours.saturday.open
                          ? this.buildTimeString(
                              selectedPharmacy.pharmacyObj.hours.saturday.close
                            )
                          : null}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Chip
                      size="small"
                      icon={
                        selectedPharmacy.pharmacyObj.hours.saturday
                          .deliveryCutoff ? (
                          <LocalShipping />
                        ) : (
                          <NotInterested />
                        )
                      }
                      label={this.buildChipLabel(
                        selectedPharmacy.pharmacyObj.hours.saturday
                          .deliveryCutoff
                      )}
                      color={
                        selectedPharmacy.pharmacyObj.hours.saturday
                          .deliveryCutoff
                          ? 'primary'
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={2} container alignContent="center">
                    <Typography variant="subtitle2" color="primary">
                      Sun:
                    </Typography>
                  </Grid>
                  <Grid item xs={6} container alignContent="center">
                    <Typography color="primary" variant="subtitle2">
                      <b>
                        {this.buildTimeString(
                          selectedPharmacy.pharmacyObj.hours.sunday.open
                        )}
                      </b>
                      {selectedPharmacy.pharmacyObj.hours.sunday.open
                        ? ' to '
                        : null}
                      <b>
                        {selectedPharmacy.pharmacyObj.hours.sunday.open
                          ? this.buildTimeString(
                              selectedPharmacy.pharmacyObj.hours.sunday.close
                            )
                          : null}
                      </b>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Chip
                      size="small"
                      icon={
                        selectedPharmacy.pharmacyObj.hours.sunday
                          .deliveryCutoff ? (
                          <LocalShipping />
                        ) : (
                          <NotInterested />
                        )
                      }
                      label={this.buildChipLabel(
                        selectedPharmacy.pharmacyObj.hours.sunday.deliveryCutoff
                      )}
                      color={
                        selectedPharmacy.pharmacyObj.hours.sunday.deliveryCutoff
                          ? 'primary'
                          : ''
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              {!selectedDefaultPharmacy ? (
                <DialogContentText>
                  Do you wish to make this your default pharmacy?
                </DialogContentText>
              ) : null}
            </DialogContent>
            {selectedDefaultPharmacy ? (
              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={!selectedPharmacy.pharmacyObj.name}
                  className={
                    selectedPharmacy.pharmacyObj.name
                      ? classes.cancelButton
                      : ''
                  }
                  onClick={() => this.handleRemoveDefaultPharmacy()}
                >
                  Remove
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.closePharmacyDialog}
                >
                  Close
                </Button>
              </DialogActions>
            ) : (
              <DialogActions>
                <Button
                  variant="outlined"
                  style={{ borderColor: '#ff4a4a', color: '#ff4a4a' }}
                  onClick={this.closePharmacyDialog}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.handleSubmitPremiumPharmacy()}
                >
                  Confirm
                </Button>
              </DialogActions>
            )}
          </Dialog>
        ) : null}
        <Dialog
          open={openNewPharmacyDialog}
          onClose={this.closeNewPharmacyDialog}
          fullWidth
        >
          <DialogTitle>Update Default Pharmacy Information</DialogTitle>
          <DialogContent>
            <Grid container className={classes.dialogContainer}>
              <Grid item xs={6}>
                <Grid item xs={12} style={{ marginBottom: '5px' }}>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="primary">
                      {newPharmacy.newPharmacyName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="primary">
                    {newPharmacy.newPharmacyAddress}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="primary">
                    {newPharmacy.newPharmacyCity},{' '}
                    {newPharmacy.newPharmacyProvince}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="primary">
                    {newPharmacy.newPharmacyPostalCode}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="primary">
                    Fax Number: {newPharmacy.newPharmacyFaxNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="primary">
                    Phone Number: {newPharmacy.newPharmacyPhoneNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="primary">
                    Mobile Number: {newPharmacy.newPharmacyMobileNumber}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <DialogContentText>
              Do you wish to make this your default pharmacy?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              style={{ borderColor: '#ff4a4a', color: '#ff4a4a' }}
              onClick={this.closeNewPharmacyDialog}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.handleSubmitNewPharmacy(newPharmacy)}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        {selectedPharmacy && selectedPharmacy.pharmacyObj.promotions && (
          <Dialog
            open={openPromotionsDialog}
            onClose={this.closePromotionsDialog}
            fullWidth
          >
            <DialogTitle>
              {selectedPharmacy.pharmacyObj.name} Promotions
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                {selectedPharmacy.pharmacyObj.promotions.map((item) => (
                  <Grid item xs={12} key={item.name}>
                    <Typography color="primary">{item.name}</Typography>
                    <Typography variant="h6" color="primary">
                      {item.description}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => this.closePromotionsDialog()}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
        <Dialog
          open={openManualPharmacyWarning}
          onClose={() => this.handleCloseManualPharmacy(false)}
        >
          <DialogTitle color="primary">Custom Pharmacy</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to add a custom pharmacy?
              <br />
              <br />
              The custom pharmacy you are about to select <b>does not</b> offer
              promotions, discounts and other benefits through SnapMED.
              <br />
              <br />
              Are you interested in viewing the <b>
                Premium pharmacies
              </b> that <b>offer promotions and discounts</b> in your area?
              <br />
              <br />
              By selecting continue, you are agreeing to take complete
              responsibility for the accuracy and correctness of the information
              you are about to enter.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleCloseManualPharmacy(false)}
              variant="outlined"
              className={this.props.classes.cancelButton}
            >
              View
            </Button>
            <Button
              onClick={() => this.handleCloseManualPharmacy(true)}
              variant="contained"
              color="primary"
            >
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.newPharmacyName) {
    errors.newPharmacyName = 'Please enter an address';
  }
  if (!values.newPharmacyAddress) {
    errors.newPharmacyAddress = 'Please enter an address';
  }
  if (!values.newPharmacyCity) {
    errors.newPharmacyCity = 'Please enter a city';
  }
  if (!values.newPharmacyProvince) {
    errors.newPharmacyProvince = 'Please enter a province';
  }
  if (!values.newPharmacyPostalCode) {
    errors.newPharmacyPostalCode = 'Please enter a postal code';
  }
  if (
    !values.newPharmacyFaxNumber ||
    (values.newPharmacyFaxNumber &&
      values.newPharmacyFaxNumber.replace(/\D+/g, '').length !== 10)
  ) {
    errors.newPharmacyFaxNumber = 'Please enter a valid number';
  }
  if (
    !values.newPharmacyPhoneNumber ||
    (values.newPharmacyPhoneNumber &&
      values.newPharmacyPhoneNumber.replace(/\D+/g, '').length !== 10)
  ) {
    errors.newPharmacyPhoneNumber = 'Please enter a valid number';
  }

  return errors;
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

PharmacyInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
};

SearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  submitSearch: PropTypes.func.isRequired,
};

FormTextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

PhoneNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

const ConnectedPharmacyInformation = connect(
  mapStateToProps,
  actions
)(PharmacyInformation);

const PharmacyInformationRedux = reduxForm({
  validate,
  form: 'add-pharmacy-form',
  touchOnBlur: false,
  initialValues: { newPharmacyProvince: 'Ontario' },
})(ConnectedPharmacyInformation);

export default withStyles(styles)(PharmacyInformationRedux);
