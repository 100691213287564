import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { AccessAlarm } from '@material-ui/icons';

class HoursOfOperationNotification extends Component {
  render() {
    const { openingHour, closingHour, closedAllDay } = this.props;
    return (
      <Grid
        item
        container
        xs={12}
        direction="row"
        style={{
          marginTop: '10px',
          borderLeft: '10px solid red',
          borderRadius: '5px',
          padding: '10px',
          backgroundColor: '#ffefec',
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={1}
          justify="center"
          alignItems="center"
        >
          <AccessAlarm style={{ fontSize: '30px' }} />
        </Grid>
        {closedAllDay ? (
          <Grid item container xs={12} sm={11} alignContent="center">
            <Typography variant="body1">
              {`We are closed today. We're sorry for the inconvenience.`}
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12} sm={11}>
            <Typography variant="body1">
              You are visiting us outside working hours.
              <br />
              Medical appointments are available between <b>
                {openingHour}
              </b>{' '}
              and <b>{closingHour}</b>.
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }
}

HoursOfOperationNotification.propTypes = {
  openingHour: PropTypes.string,
  closingHour: PropTypes.string,
  closedAllDay: PropTypes.bool,
};

export default HoursOfOperationNotification;
