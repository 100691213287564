import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Typography, Zoom, withStyles } from '@material-ui/core';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import * as actions from '../../../actions';

const styles = () => ({
  container: {
    marginTop: '5%',
  },
  reportButton: {
    margin: '10px 0px',
  },
});

class AvgDeliveryTimeCard extends Component {
  renderDeliveryTimeAnimation() {
    let delay = 0;
    const today = new Date();
    const previousMonth = () =>
      today.getMonth() === 0 ? 11 : today.getMonth() - 1;
    const deliveries = this.props.deliveries ? this.props.deliveries : [];
    const previousMonthDeliveries = deliveries.filter((item) => {
      const month = new Date(item.date).getMonth();
      return month === previousMonth() && item.status.name === 'Delivered';
    });
    const deliveryTimes = previousMonthDeliveries.map((item) => {
      const received = new Date(item.date).getTime();
      const delivered = new Date(item.deliveredOn).getTime();
      return (delivered - received) / 60 / 60 / 1000;
    });
    const totalHours = deliveryTimes.reduce((sum, value) => sum + value, 0);
    let avgTimeDays = (totalHours / deliveryTimes.length / 24).toFixed(1);
    avgTimeDays = avgTimeDays !== 'NaN' ? avgTimeDays : '0';
    let avgTimeHours = (totalHours / deliveryTimes.length).toFixed(1);
    avgTimeHours = avgTimeHours !== 'NaN' ? `${avgTimeHours}` : '0';
    avgTimeHours = avgTimeHours.split('');
    const numberAnimation = avgTimeHours.map((number, index) => {
      delay += 100;
      return (
        <Zoom
          key={index}
          in={true}
          timeout={500}
          style={{ transitionDelay: `${delay}ms` }}
        >
          <Grid item>
            <Typography color="primary" variant="h3" align="center">
              {number}
            </Typography>
          </Grid>
        </Zoom>
      );
    });
    numberAnimation.push(
      <Zoom in={true} timeout={500} style={{ transitionDelay: `${delay}ms` }}>
        <Grid item style={{ marginLeft: '10px' }}>
          <Typography color="primary" variant="h3" align="center">
            hours
          </Typography>
        </Grid>
      </Zoom>
    );
    numberAnimation.push(
      <Grid item container justify="center">
        <Typography variant="h6" color="primary">
          {avgTimeDays} days
        </Typography>
      </Grid>
    );
    return numberAnimation;
  }

  renderDeliveryTimePercentge() {
    const today = new Date();
    const previousMonth = () =>
      today.getMonth() === 0 ? 11 : today.getMonth() - 1;
    const controlMonth = previousMonth() - 1;
    const totalDeliveries = this.props.deliveries ? this.props.deliveries : [];
    const previousMonthDeliveries = totalDeliveries.filter((item) => {
      const month = new Date(item.date).getMonth();
      return month === previousMonth() && item.status.name === 'Delivered';
    });
    const deliveryTimes = previousMonthDeliveries.map((item) => {
      const received = new Date(item.date).getTime();
      const delivered = new Date(item.deliveredOn).getTime();
      return (delivered - received) / 60 / 60 / 1000;
    });
    const totalHours = deliveryTimes.reduce((sum, value) => sum + value, 0);
    const avgTimeHours = (totalHours / deliveryTimes.length).toFixed(1);

    const controlMonthDeliveries = totalDeliveries.filter((item) => {
      const month = new Date(item.date).getMonth();
      return month === controlMonth && item.status.name === 'Delivered';
    });
    const controlDeliveryTimes = controlMonthDeliveries.map((item) => {
      const received = new Date(item.date).getTime();
      const delivered = new Date(item.deliveredOn).getTime();
      return (delivered - received) / 60 / 60 / 1000;
    });
    const controlTotalHours = controlDeliveryTimes.reduce(
      (sum, value) => sum + value,
      0
    );
    const avgControlTimeHours = (
      controlTotalHours / controlDeliveryTimes.length
    ).toFixed(1);

    const difference = avgTimeHours - avgControlTimeHours;
    let percentage;
    if (Number.isNaN(difference)) {
      percentage = '0';
    } else {
      const percentageCalc = () => {
        if (difference !== avgTimeHours) {
          const result = (difference / avgControlTimeHours) * 100;
          return result > 0
            ? `${result.toFixed(1)}`
            : `${(result * -1).toFixed(1)}`;
        }
        return 'Absolute growth.';
      };
      percentage = percentageCalc();
    }
    return (
      <Grid item container alignItems="center" justify="center">
        {difference > 0 ? (
          <ArrowDropUp style={{ fontSize: 40, color: 'red' }} />
        ) : (
          <ArrowDropDown style={{ fontSize: 40, color: 'green' }} />
        )}
        <Typography
          align="center"
          style={difference > 0 ? { color: 'red' } : { color: 'green' }}
        >
          {percentage}%
        </Typography>
      </Grid>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container className={classes.container} spacing={1}>
        <Grid item container justify="center">
          {this.renderDeliveryTimeAnimation()}
        </Grid>
        <Grid item container alignItems="center" justify="center">
          {this.renderDeliveryTimePercentge()}
        </Grid>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    deliveries: state.deliveriesList,
  };
}

AvgDeliveryTimeCard.propTypes = {
  classes: PropTypes.object.isRequired,
  deliveries: PropTypes.array,
};

const ConnectedAvgDeliveryTimeCard = connect(
  mapStateToProps,
  actions
)(withRouter(AvgDeliveryTimeCard));

export default withStyles(styles)(ConnectedAvgDeliveryTimeCard);
