import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk from 'redux-thunk';
import { StripeProvider } from 'react-stripe-elements';
import App from './components/App';
import reducers from './reducers';
import { REACT_APP_STRIPE_KEY, REACT_APP_ENV } from './constants';

let store;

if (
  REACT_APP_ENV !== 'production' ||
  process.env.REACT_APP_MYSTRIPE === 'LOCAL'
) {
  store = createStore(
    reducers,
    {},
    composeWithDevTools(applyMiddleware(reduxThunk))
  );
} else {
  store = createStore(reducers, {}, applyMiddleware(reduxThunk));
}

switch (REACT_APP_ENV) {
  case 'development':
    document.title = 'SnapMED - Dev';
    break;

  case 'qa':
    document.title = 'SnapMED - QA';
    break;

  case 'production':
    document.title = 'SnapMED';
    break;

  default:
    document.title = 'SnapMED';
    break;
}

ReactDOM.render(
  <Provider store={store}>
    <StripeProvider apiKey={REACT_APP_STRIPE_KEY}>
      <App />
    </StripeProvider>
  </Provider>,
  document.querySelector('#root')
);
