import React, { Component, Fragment } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  Button,
  Slide,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import { Save, GetApp } from '@material-ui/icons';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 700,
      minHeight: '80%',
    },
  },
  titleMargin: {
    marginBottom: '30px',
  },
  table: {
    height: '100%',
  },
});

class TextChatTranscript extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatHistory: '',
      selected: [],
      page: 0,
      rowsPerPage: 8,
    };
  }

  async componentDidMount() {
    await axios
      .get(`/api/chat-history/${this.props.textChatId}`)
      .then((res) => {
        const ids = [];
        if (res.data && res.data.messages) {
          const importantMessages = res.data.messages.filter(
            (message) => message.isImportant
          );
          importantMessages.forEach((message) => {
            ids.push(message._id);
          });
        }
        this.setState({
          chatHistory: res.data,
          selected: ids,
        });
      });
  }

  handleSelect = (_, id) => {
    this.setState((prevState) => {
      const isSelected = prevState.selected.includes(id);
      const updatedSelected = isSelected
        ? prevState.selected.filter((item) => item !== id)
        : [...prevState.selected, id];

      const updatedMessages = prevState.chatHistory.messages.map((message) => {
        if (message._id === id) {
          return {
            ...message,
            isImportant: !message.isImportant,
          };
        }
        return message;
      });

      return {
        selected: updatedSelected,
        chatHistory: {
          ...prevState.chatHistory,
          messages: updatedMessages,
        },
      };
    });
  };

  handleSave = async () => {
    const { chatHistory, selected } = this.state;
    chatHistory.hasFlaggedMsg = selected.length > 0;
    await axios.post('/api/save-important-messages', {
      chat: chatHistory,
      backdrop: true,
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  // eslint-disable-next-line class-methods-use-this
  handleDownload = async (path) => {
    const response = await axios.get(path, {
      responseType: 'blob',
    });
    if (response.data) {
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = path.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }
  };

  render() {
    const { classes } = this.props;
    const { chatHistory, selected, page, rowsPerPage } = this.state;

    return (
      <Fragment>
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <Grid
              item
              container
              style={{ marginTop: '5%' }}
              alignItems="center"
            >
              <Grid item xs={6} className={classes.titleMargin}>
                <Typography variant="h5" color="primary">
                  Text Chat Transcript
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={6}
                className={classes.titleMargin}
                justify="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => this.handleDownload(chatHistory.documentSrc)}
                    startIcon={<GetApp />}
                  >
                    Download
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.handleSave()}
                    startIcon={<Save />}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Paper>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-label="text chat table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Important</TableCell>
                      <TableCell>From</TableCell>
                      <TableCell>Message</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {chatHistory &&
                      chatHistory.messages
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          const isItemSelected = selected.includes(row._id);
                          return (
                            <TableRow
                              hover
                              onClick={(event) =>
                                this.handleSelect(event, row._id)
                              }
                              role="checkbox"
                              key={row._id}
                              selected={isItemSelected}
                            >
                              <TableCell>
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                />
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.name}
                              </TableCell>
                              <TableCell>{row.message}</TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                count={chatHistory && chatHistory.messages.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                page={page}
                onChangePage={this.handleChangePage}
              />
            </Paper>
          </main>
        </Slide>
      </Fragment>
    );
  }
}

TextChatTranscript.propTypes = {
  classes: PropTypes.object.isRequired,
  textChatId: PropTypes.string.isRequired,
};

export default withStyles(styles)(TextChatTranscript);
