/* eslint-disable default-param-last, func-names */
import { PHARMACY_PAYMENTS } from '../actions/types';

export default function (state = null, action) {
  switch (action.type) {
    case PHARMACY_PAYMENTS:
      return action.payload;
    default:
      return state;
  }
}
