import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import SignaturePad from 'react-signature-canvas';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Typography,
  Grid,
  Fab,
  Card,
  CardContent,
  CardActions,
  Slide,
  CircularProgress,
  withStyles,
} from '@material-ui/core';
import { Delete, ArrowBack } from '@material-ui/icons';

import PathBuilder from '../../api/media-path-builder';
import '../../styles/signaturePad.css';
import * as actions from '../../actions';

const styles = () => ({
  layout: {
    maxWidth: 800,
  },
  bottomSpacing: {
    marginBottom: 15,
  },
});

class DoctorSignature extends Component {
  state = {
    disableSubmit: true,
    loading: false,
  };
  sigPad = {};

  clear = () => {
    this.sigPad.clear();
    this.setState({ disableSubmit: true });
  };

  uploadSignature = async () => {
    this.sigPad.off();
    this.setState({ disableSubmit: true, loading: true });
    const pathBuilder = PathBuilder;
    await axios.delete(
      pathBuilder.doctorSignatureFilePath(this.props.auth._id, '')
    );
    const formData = new FormData();
    const newFileName = 'signature.png';

    const blob = await new Promise((resolve) => {
      this.sigPad.getTrimmedCanvas().toBlob(resolve, 'image/png');
    });

    const newFile = new File([blob], newFileName, {
      type: 'image/png',
    });

    const path = pathBuilder.doctorSignatureFilePath(
      this.props.auth._id,
      newFileName
    );
    formData.append('file', newFile);
    formData.append('role', this.props.auth.role);
    axios
      .post(path, formData, {
        headers: {
          'Content-Type': 'image/png',
        },
      })
      .then(async (res) => {
        if (res.data && res.data.newFile) {
          await this.props.submitDoctorSignature({
            signature: res.data.newFile,
          });
          this.props.fetchUser();
          this.props.exitPage();
        } else {
          this.sigPad.on();
          this.setState({ loading: false, disableSubmit: false });
        }
      })
      .catch(() => {
        this.sigPad.on();
        this.setState({ loading: false, disableSubmit: false });
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Slide in={true} direction="right">
        <main className={classes.layout}>
          <Card>
            <CardContent>
              {this.props.auth.signature && (
                <div className={classes.bottomSpacing}>
                  <Typography
                    color="primary"
                    variant="body1"
                    className={classes.bottomSpacing}
                  >
                    Previous Signature
                  </Typography>
                  <Grid container justify="center">
                    <img
                      id="previous-signature"
                      src={this.props.auth.signature}
                    />
                  </Grid>
                </div>
              )}
              <Grid container className={classes.bottomSpacing}>
                <Typography color="primary" variant="h5">
                  Please sign below{' '}
                  {this.props.auth.signature && 'to change your signature'}:
                </Typography>
              </Grid>
              <div id="sigContainer">
                <SignaturePad
                  onEnd={() => this.setState({ disableSubmit: false })}
                  canvasProps={{
                    className: 'canvas',
                  }}
                  ref={(ref) => {
                    this.sigPad = ref;
                  }}
                />
              </div>
              <CardActions>
                <Grid container spacing={1}>
                  <Grid item xs={2} sm={1}>
                    <Fab
                      size="small"
                      disabled={this.state.loading}
                      onClick={() => this.props.exitPage()}
                    >
                      <ArrowBack />
                    </Fab>
                  </Grid>
                  <Grid item xs={2} sm={1}>
                    <Fab
                      size="small"
                      disabled={this.state.disableSubmit}
                      onClick={this.clear}
                    >
                      <Delete />
                    </Fab>
                  </Grid>
                  <Grid item xs={8} sm={10}>
                    <Button
                      style={{ float: 'right' }}
                      color="primary"
                      variant="contained"
                      disabled={this.state.disableSubmit}
                      onClick={this.uploadSignature}
                    >
                      Submit
                      {this.state.loading && (
                        <CircularProgress
                          style={{ marginLeft: '5px' }}
                          size={20}
                          color="primary"
                        />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </CardActions>
            </CardContent>
          </Card>
        </main>
      </Slide>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

DoctorSignature.propTypes = {
  classes: PropTypes.object.isRequired,
  submitDoctorSignature: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  exitPage: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const ConnectedDoctorSignature = connect(
  mapStateToProps,
  actions
)(withRouter(DoctorSignature));

export default withStyles(styles)(ConnectedDoctorSignature);
