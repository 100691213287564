import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import axios from 'axios';
import PropTypes from 'prop-types';
import { JaaSMeeting } from '@jitsi/react-sdk';
import {
  Grid,
  withStyles,
  IconButton,
  Button,
  Fab,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { Send } from '@material-ui/icons';

import ToolbarRoot from './toolbar/Toolbar';
import ToolbarPageRoot from './toolbarComponents/ToolbarPageRoot';
import '../../../../styles/doctorRoomStyles.css';
import pharmacyIcon from '../../../../images/pharmacy_64';
import uploadIcon from '../../../../images/upload_64';
import eventsApi from '../../../../api/events-api';
import FileUpload from '../../../dialogs/FileUpload';
import * as actions from '../../../../actions';
import { REACT_APP_JAAS_APP_ID } from '../../../../constants';

const styles = () => ({
  iconButton: {
    borderRadius: 0,
    height: '14.29%',
    padding: 0,
    width: '100%',
    flex: 'wrap',
  },
  iconButtonHighlighted: {
    borderRadius: 0,
    padding: 0,
    height: '14.29%',
    width: '100%',
    backgroundColor: '#db4437',
  },
  actualIcon: {
    fontSize: 30,
    color: 'white',
  },
  roomContainer: {
    borderRadius: 10,
    overflow: 'hidden',
    margin: 0,
    position: 'relative',
  },
});

const TextChatFormField = () => (
  <form autoComplete="off">
    <p id="history" />
    <Grid container direction="row" style={{ paddingBottom: '5px' }}>
      <Grid item xs={10} container direction="row" justify="center">
        <input
          type="text"
          name="msgTxt"
          placeholder="Type a message..."
          id="msgTxt"
        />
      </Grid>
      <Grid item xs={2} container direction="row" justify="center">
        <IconButton
          type="submit"
          style={{
            padding: 0,
          }}
        >
          <Fab color="secondary" size="small" aria-label="send">
            <Send />
          </Fab>
        </IconButton>
      </Grid>
    </Grid>
  </form>
);

class DoctorVideoChat extends Component {
  state = {
    currentIcon: 'videochat',
    firstTime: true,
    appointmentParams: {},
    textNotification: false,
    callTimedOut: false,
    pharmacyInfoDialog: false,
    callStart: null,
    enableFinalizeButton: false,
    openConfirmationDialog: false,
    jaasApi: null,
    openUploadDialog: false,
  };

  componentDidMount() {
    // subscribing to patient's call updates
    this.subscribeToUpdates();
  }

  manageUpdates = (data) => {
    switch (data) {
      case 'patient_in_pre_meeting':
        this.state.jaasApi.executeCommand('showNotification', {
          title: 'Patient in Pre-Meeting',
          timeout: 'long',
        });
        break;
      case 'patient_left_queue':
        this.state.jaasApi.executeCommand('showNotification', {
          title: 'Patient left queue',
          timeout: 'long',
        });
        break;
      case 'appt_files_updated':
        this.state.jaasApi.executeCommand('showNotification', {
          title: 'Appointment files were updated',
          timeout: 'long',
        });
        break;
      default:
        break;
    }
  };

  subscribeToUpdates = () => {
    eventsApi.on(`appt-updates:${this.props.room}`, (message) => {
      this.manageUpdates(message);
    });
  };

  startTimer() {
    this.setState({ callStart: new Date() });
  }

  endTimer() {
    const callEnd = new Date();
    const durationInMilliseconds = callEnd - this.state.callStart;
    const durationInSeconds = Math.floor(durationInMilliseconds / 1000);
    const durationInMinutes = Math.floor(durationInSeconds / 60);
    const remainderSeconds = durationInSeconds % 60;

    // Format time as 2-digit numbers
    const min = String(durationInMinutes).padStart(2, '0');
    const sec = String(remainderSeconds).padStart(2, '0');
    // pass timer string to parent component
    this.props.getCallDuration(`${min} : ${sec}`);
  }

  handleIncomingMessage(obj) {
    const newMessage = {
      timestamp: new Date(),
      userId: this.props.appointmentParams.patient._id,
      name: obj.nick,
      message: obj.message,
      isImportant: false,
      isPrivate: obj.privateMessage,
    };
    this.props.updateTextChat(newMessage);
  }

  handleOutgoingMessage(obj) {
    const newMessage = {
      timestamp: new Date(),
      userId: this.props.auth._id,
      name: `${this.props.auth.name.first} ${this.props.auth.name.last}`,
      message: obj.message,
      isImportant: false,
      isPrivate: obj.privateMessage,
    };
    this.props.updateTextChat(newMessage);
  }

  handleToolbar = (icon) => {
    const { firstTime } = this.state;
    if (firstTime) {
      this.setState({ currentIcon: icon, firstTime: false });
    } else {
      this.setState({ currentIcon: icon });
    }
  };

  iconClassName = (type) => {
    const { currentIcon, firstTime } = this.state;
    const { classes } = this.props;
    if (currentIcon === type && !firstTime) {
      return classes.iconButtonHighlighted;
    }
    return classes.iconButton;
  };

  handleCheckboxChange(event, name) {
    this.setState({ [name]: event.target.checked });
  }

  // eslint-disable-next-line class-methods-use-this
  renderFormField() {
    return <Field component={TextChatFormField} />;
  }

  handleEnableFinalize = () => {
    this.setState({ enableFinalizeButton: true });
  };

  // eslint-disable-next-line class-methods-use-this
  buildTimeString(value) {
    let date;
    let meridiem;
    let hourString;

    if (value) {
      date = new Date(value);
      date = date.toLocaleTimeString('en-US').split(':');
      // .pop() assigns the removed element to variable 'openMeridiem' and also changes the array 'open'
      meridiem = date.pop();
      meridiem = meridiem.slice(2);
      hourString = `${date[0]}:${date[1]}${meridiem}`;
    } else {
      hourString = 'CLOSED';
    }
    return hourString;
  }

  renderFinalizeButton() {
    return (
      <Button
        disabled={!this.state.enableFinalizeButton}
        variant="contained"
        onClick={() => this.props.finalizeAppointment()}
      >
        Finalize Appointment
      </Button>
    );
  }

  closeConfirmationDialog = (endCall, room) => {
    const { jaasApi } = this.state;
    this.setState({ openConfirmationDialog: false });
    if (endCall) {
      this.updateDoctorStatus(room, 'ended');
      this.props.endCallForPatient();
      jaasApi.executeCommand('hangup');
    }
  };

  // eslint-disable-next-line class-methods-use-this
  updateDoctorStatus = async (room, status) => {
    const body = {
      apptId: room,
    };
    switch (status) {
      case 'connected':
        body.connected = true;
        break;
      case 'ended':
        body.ended = true;
        break;
      default:
        break;
    }
    await axios.put('/api/doctor-video-call-status', body);
  };

  render() {
    const { classes, appointmentParams, room, docToken } = this.props;
    const {
      currentIcon,
      enableFinalizeButton,
      openConfirmationDialog,
      openUploadDialog,
    } = this.state;
    return (
      <Grid container className={classes.roomContainer} direction="row">
        <Grid id="videos" item xs={10} sm={10} md={11}>
          <JaaSMeeting
            appId={REACT_APP_JAAS_APP_ID}
            roomName={room}
            jwt={docToken}
            configOverwrite={{
              readOnlyName: true,
              disableThirdPartyRequests: true,
              disableLocalVideoFlip: true,
              backgroundAlpha: 0.5,
              disablePolls: true,
              disableReactions: true,
              disableChatSmileys: true,
              toolbarConfig: {
                alwaysVisible: true,
              },
              toolbarButtons: [
                'camera',
                'microphone',
                'chat',
                'fullscreen',
                'hangup',
                'desktop',
                'invite',
                'participants-pane',
                'security',
                'select-background',
                'settings',
                'stats',
                'tileview',
              ],
              hiddenPremeetingButtons: ['settings', 'invite'],
              customToolbarButtons: [
                {
                  icon: pharmacyIcon,
                  id: 'custom-pharmacy-button',
                  text: 'View pharmacy',
                },
                {
                  icon: uploadIcon,
                  id: 'upload-docs-button',
                  text: 'Upload',
                },
              ],
              buttonsWithNotifyClick: ['hangup'],
              remoteVideoMenu: {
                disablePrivateChat: true,
                disableGrantModerator: true,
              },
              breakoutRooms: {
                hideAddRoomButton: true,
                hideAutoAssignButton: true,
                hideJoinRoomButton: true,
              },
              recordings: {
                // If true, shows a notification at the start of the meeting with a call to action button
                // to start recording (for users who can do so, like moderators).
                suggestRecording: false,
                // If true, shows a warning label in the prejoin screen to point out the possibility that
                // the call you're joining might be recorded.
                showPrejoinWarning: false,
              },
            }}
            interfaceConfigOverwrite={{
              VIDEO_LAYOUT_FIT: 'nocrop',
              MOBILE_APP_PROMO: false,
              TILE_VIEW_MAX_COLUMNS: 4,
            }}
            onApiReady={(api) => {
              this.startTimer();
              this.setState({ jaasApi: api });
              api.addListener('videoConferenceJoined', () => {
                this.updateDoctorStatus(room, 'connected');
              });
              api.addListener('participantJoined', () =>
                api.executeCommand('toggleLobby', true)
              );
              api.addListener('incomingMessage', (obj) =>
                this.handleIncomingMessage(obj)
              );
              api.addListener('outgoingMessage', (obj) =>
                this.handleOutgoingMessage(obj)
              );
              api.addListener('toolbarButtonClicked', (obj) => {
                if (obj.key === 'custom-pharmacy-button') {
                  this.setState({ pharmacyInfoDialog: true });
                }
                if (obj.key === 'hangup') {
                  this.setState({ openConfirmationDialog: true });
                }
                if (obj.key === 'upload-docs-button') {
                  this.setState({ openUploadDialog: true });
                }
              });
            }}
            onReadyToClose={() => {
              this.endTimer();
              this.handleEnableFinalize();
            }}
            getIFrameRef={(iframeRef) => {
              const iframe = iframeRef;
              iframe.style.height = '100%';
              iframe.style.width = '100%';
              iframe.style.position = 'absolute';
            }}
          />
          <ToolbarPageRoot
            appointmentParams={this.props.appointmentParams}
            pageSelected={currentIcon}
          />
          {enableFinalizeButton && (
            <Grid
              item
              xs={12}
              container
              justify="center"
              alignItems="center"
              style={{ height: '100%' }}
            >
              {this.renderFinalizeButton()}
            </Grid>
          )}
        </Grid>
        <Grid item xs={2} sm={2} md={1}>
          <ToolbarRoot
            classes={classes}
            handleClick={this.handleToolbar}
            iconClassName={this.iconClassName}
          />
        </Grid>
        <Dialog
          open={this.state.pharmacyInfoDialog}
          onClose={() => {
            this.setState({ pharmacyInfoDialog: false });
          }}
          fullWidth
        >
          <DialogTitle>Pharmacy Information</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Any prescription will be faxed to this pharmacy:
            </DialogContentText>
            <Grid container>
              <Grid item xs={6} container>
                <Grid item xs={12}>
                  <Typography variant="h6" color="primary">
                    {appointmentParams.patient.patient.pharmacy.premiumPharmacy
                      ? appointmentParams.patient.patient.pharmacy
                          .premiumPharmacy.name
                      : appointmentParams.patient.patient.pharmacy.name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="primary">
                    {appointmentParams.patient.patient.pharmacy.premiumPharmacy
                      ? appointmentParams.patient.patient.pharmacy
                          .premiumPharmacy.address.street
                      : appointmentParams.patient.patient.pharmacy.address
                          .street}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="primary">
                    {appointmentParams.patient.patient.pharmacy.premiumPharmacy
                      ? `${appointmentParams.patient.patient.pharmacy.premiumPharmacy.address.city},`
                      : appointmentParams.patient.patient.pharmacy.address.city}
                    {appointmentParams.patient.patient.pharmacy.premiumPharmacy
                      ? appointmentParams.patient.patient.pharmacy
                          .premiumPharmacy.address.province
                      : appointmentParams.patient.patient.pharmacy.address
                          .province}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="primary">
                    {appointmentParams.patient.patient.pharmacy.premiumPharmacy
                      ? appointmentParams.patient.patient.pharmacy
                          .premiumPharmacy.address.postalCode
                      : appointmentParams.patient.patient.pharmacy.address
                          .postalCode}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="primary">
                    Fax:{' '}
                    {appointmentParams.patient.patient.pharmacy.premiumPharmacy
                      ? appointmentParams.patient.patient.pharmacy
                          .premiumPharmacy.faxNumber
                      : appointmentParams.patient.patient.pharmacy.faxNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color="primary">
                    Phone:{' '}
                    {appointmentParams.patient.patient.pharmacy.premiumPharmacy
                      ? appointmentParams.patient.patient.pharmacy
                          .premiumPharmacy.phoneNumber
                      : appointmentParams.patient.patient.pharmacy.phoneNumber}
                  </Typography>
                </Grid>
              </Grid>
              {appointmentParams.patient.patient.pharmacy.premiumPharmacy && (
                <Grid item xs={6} container>
                  <Grid item xs={12}>
                    <Typography variant="h6">Hours:</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="primary">Mon.</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography color="primary">
                      {this.buildTimeString(
                        appointmentParams.patient.patient.pharmacy
                          .premiumPharmacy.hours.monday.open
                      )}
                      {appointmentParams.patient.patient.pharmacy
                        .premiumPharmacy.hours.monday.open
                        ? ' to '
                        : null}
                      {appointmentParams.patient.patient.pharmacy
                        .premiumPharmacy.hours.monday.open
                        ? this.buildTimeString(
                            appointmentParams.patient.patient.pharmacy
                              .premiumPharmacy.hours.monday.close
                          )
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="primary">Tue.</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography color="primary">
                      {this.buildTimeString(
                        appointmentParams.patient.patient.pharmacy
                          .premiumPharmacy.hours.tuesday.open
                      )}
                      {appointmentParams.patient.patient.pharmacy
                        .premiumPharmacy.hours.tuesday.open
                        ? ' to '
                        : null}
                      {appointmentParams.patient.patient.pharmacy
                        .premiumPharmacy.hours.tuesday.open
                        ? this.buildTimeString(
                            appointmentParams.patient.patient.pharmacy
                              .premiumPharmacy.hours.tuesday.close
                          )
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="primary">Wed.</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography color="primary">
                      {this.buildTimeString(
                        appointmentParams.patient.patient.pharmacy
                          .premiumPharmacy.hours.wednesday.open
                      )}
                      {appointmentParams.patient.patient.pharmacy
                        .premiumPharmacy.hours.wednesday.open
                        ? ' to '
                        : null}
                      {appointmentParams.patient.patient.pharmacy
                        .premiumPharmacy.hours.wednesday.open
                        ? this.buildTimeString(
                            appointmentParams.patient.patient.pharmacy
                              .premiumPharmacy.hours.wednesday.close
                          )
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="primary">Thu.</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography color="primary">
                      {this.buildTimeString(
                        appointmentParams.patient.patient.pharmacy
                          .premiumPharmacy.hours.thursday.open
                      )}
                      {appointmentParams.patient.patient.pharmacy
                        .premiumPharmacy.hours.thursday.open
                        ? ' to '
                        : null}
                      {appointmentParams.patient.patient.pharmacy
                        .premiumPharmacy.hours.thursday.open
                        ? this.buildTimeString(
                            appointmentParams.patient.patient.pharmacy
                              .premiumPharmacy.hours.thursday.close
                          )
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="primary">Fri.</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography color="primary">
                      {this.buildTimeString(
                        appointmentParams.patient.patient.pharmacy
                          .premiumPharmacy.hours.friday.open
                      )}
                      {appointmentParams.patient.patient.pharmacy
                        .premiumPharmacy.hours.friday.open
                        ? ' to '
                        : null}
                      {appointmentParams.patient.patient.pharmacy
                        .premiumPharmacy.hours.friday.open
                        ? this.buildTimeString(
                            appointmentParams.patient.patient.pharmacy
                              .premiumPharmacy.hours.friday.close
                          )
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="primary">Sat.</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography color="primary">
                      {this.buildTimeString(
                        appointmentParams.patient.patient.pharmacy
                          .premiumPharmacy.hours.saturday.open
                      )}
                      {appointmentParams.patient.patient.pharmacy
                        .premiumPharmacy.hours.saturday.open
                        ? ' to '
                        : null}
                      {appointmentParams.patient.patient.pharmacy
                        .premiumPharmacy.hours.saturday.open
                        ? this.buildTimeString(
                            appointmentParams.patient.patient.pharmacy
                              .premiumPharmacy.hours.saturday.close
                          )
                        : null}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="primary">Sun.</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <Typography color="primary">
                      {this.buildTimeString(
                        appointmentParams.patient.patient.pharmacy
                          .premiumPharmacy.hours.sunday.open
                      )}
                      {appointmentParams.patient.patient.pharmacy
                        .premiumPharmacy.hours.sunday.open
                        ? ' to '
                        : null}
                      {appointmentParams.patient.patient.pharmacy
                        .premiumPharmacy.hours.sunday.open
                        ? this.buildTimeString(
                            appointmentParams.patient.patient.pharmacy
                              .premiumPharmacy.hours.sunday.close
                          )
                        : null}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({ pharmacyInfoDialog: false })}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openConfirmationDialog}
          onClose={() => this.closeConfirmationDialog(false, null)}
        >
          <DialogTitle>
            <Typography variant="h5" color="primary">
              Are you sure?
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid item xs={12}>
              <Typography color="primary">
                This will end this virtual medical consultation and the patient
                will be redirected to their dashboard.
              </Typography>
            </Grid>
          </DialogContent>
          <DialogActions
            style={{
              paddingLeft: 24,
              paddingRight: 24,
              paddingTop: 16,
              paddingBottom: 16,
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => this.closeConfirmationDialog(false, null)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.closeConfirmationDialog(true, room)}
            >
              End call
            </Button>
          </DialogActions>
        </Dialog>
        <FileUpload
          open={openUploadDialog}
          onClose={() => this.setState({ openUploadDialog: false })}
        />
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return {
    appointmentParams: state.appointmentParams,
    appointmentDoctorJaas: state.appointmentDoctorJaas,
    auth: state.auth,
  };
}

DoctorVideoChat.propTypes = {
  classes: PropTypes.object.isRequired,
  appointmentParams: PropTypes.object.isRequired,
  room: PropTypes.string.isRequired,
  docToken: PropTypes.string.isRequired,
  getCallDuration: PropTypes.func.isRequired,
  finalizeAppointment: PropTypes.func.isRequired,
  endCallForPatient: PropTypes.func.isRequired,
  updateTextChat: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

TextChatFormField.propTypes = {
  input: PropTypes.object,
};

const ConnectedDoctorVideoChat = connect(
  mapStateToProps,
  actions
)(DoctorVideoChat);

export default withStyles(styles)(ConnectedDoctorVideoChat);
