import axios from 'axios';
import {
  FETCH_USER,
  FETCH_REGISTRATION,
  SMART_FAMILY_PLAN,
  SMART_APPOINTMENT_CODE,
  DIAGNOSTIC_CODE,
  TIMER_DURATION,
  IP_GEOLOCATION,
  USER_PLANS,
} from './types';
import { authApi } from '../api/auth-api';

export const logout =
  (role, appointment, history, appointmentParams, idleLogout, doubleLogin) =>
  async (dispatch) => {
    if (role === 'patient') {
      // if (appointment) {
      //   await axios.post('/api/finish_appointment_patient_logout', {
      //     appointmentId: appointmentParams.appointmentId
      //   });
      //   const data = {
      //     appointmentId: appointmentParams.appointmentId,
      //   };
      //   const blob = new Blob([JSON.stringify(data)], {
      //     type: 'text/plain; charset=UTF-8',
      //   });
      //   navigator.sendBeacon('/api/patientleavesopentoken', blob);
      // }
      await axios.post('/api/auth/logout', {
        doubleLogin,
        backdrop: true,
      });
      authApi.setAuthenticated(false);
      history.push('/login');
      window.location.reload();
    }

    if (role === 'doctor') {
      // if (appointment) {
      //   const data = {
      //     appointment: appointmentParams.appointment,
      //   };
      //   const blob = new Blob([JSON.stringify(data)], {
      //     type: 'text/plain; charset=UTF-8',
      //   });
      //   navigator.sendBeacon('/api/doctor_incorrectly_left', blob);
      // }
      await axios.post('/api/auth/logout', {
        doubleLogin,
      });
      authApi.setAuthenticated(false);
      history.push('/doctors');
      window.location.reload();
    }
    if (role === 'pharmacy') {
      await axios.post('/api/auth/logout', {
        doubleLogin,
      });
      authApi.setAuthenticated(false);
      history.push('/pharmacy');
      window.location.reload();
    }
    // dispatch({ type: 'logout', payload: false });
    if (idleLogout === false) {
      dispatch({ type: 'idleLogout', payload: false });
    } else {
      dispatch({ type: 'idleLogout', payload: true });
    }
    dispatch({ type: 'USER_LOGOUT', payload: false });
  };

export const fetchUsers = () => async (dispatch) => {
  const res = await axios.get('/api/registration');
  dispatch({ type: FETCH_REGISTRATION, payload: res.data });
};

export const payAsYouGoPaymentSetup =
  (values, history, origin) => async (dispatch) => {
    await axios.post('/api/billing/AddCreditToUser/', values);
    dispatch({
      type: 'firstCredit',
      payload: { payAsYouGoFirstCredit: true },
    });
    if (origin === 'dashboard') {
      history.push('/paymentacknowledgment/dashboard');
    } else {
      history.push('/paymentacknowledgment/PayAsYouGo');
    }
  };

export const payAsYouGoPaymentRemoval = () => async (dispatch) => {
  dispatch({
    type: 'firstCredit',
    payload: { payAsYouGoFirstCredit: false },
  });
};

export const fiveCreditPackagePaymentSetup =
  (values, history, origin) => async (dispatch) => {
    await axios.post('/api/billing/AddFiveCreditsToUser/', values);
    dispatch({
      type: 'firstCredit',
      payload: { fiveCreditPackageFirstCredit: true },
    });
    if (origin === 'dashboard') {
      history.push('/paymentacknowledgment/dashboard');
    } else {
      history.push('/paymentacknowledgment');
    }
  };

export const fiveCreditPackagePaymentRemoval = () => async (dispatch) => {
  dispatch({
    type: 'firstCredit',
    payload: { fiveCreditPackageFirstCredit: false },
  });
};

export const fetchUser = () => async (dispatch) => {
  const res = await axios.get('/api/auth/current_user');
  authApi.setAuthenticated(!!res.data, res.data.role);
  dispatch({ type: FETCH_USER, payload: res.data });
};

export const fetchLocationInfo = () => async (dispatch) => {
  const locationData = await axios.get('/api/auth/ip-geolocation');
  dispatch({ type: IP_GEOLOCATION, payload: locationData.data });
};

export const fetchTimerValues = () => async (dispatch) => {
  const timerValues = await axios.get('/api/get_timer_values');
  dispatch({ type: TIMER_DURATION, payload: timerValues.data });
};

export const fetchUserPlans = () => async (dispatch) => {
  const userPlans = await axios.post('/api/billing/stripe/CheckPlan');
  dispatch({ type: USER_PLANS, payload: userPlans.data });
  return userPlans.data;
};

export const fetchData = () => async (dispatch) => {
  const res = await axios.get('/api/medicalInformationSelection');
  dispatch({ type: 'FETCH_MEDICAL_DATA', payload: res.data });
  const resTwo = await axios.get('/api/smartFamilyPlan');
  dispatch({ type: SMART_FAMILY_PLAN, payload: resTwo.data });
  const resThree = await axios.get('/api/smartAppointmentCodes');
  dispatch({ type: SMART_APPOINTMENT_CODE, payload: resThree.data });
  const diagnosticCodes = await axios.get('/api/diagnostic-codes');
  dispatch({ type: DIAGNOSTIC_CODE, payload: diagnosticCodes.data });
};

export const login = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/auth/login', {
    ...values,
    backdrop: true, // enable backdrop in HTTP request
  });
  if (res.data) {
    if (res.data._id && !res.data.isActive) {
      history.push('/profileform');
      dispatch({ type: FETCH_USER, payload: res.data });
    } else {
      dispatch({ type: FETCH_USER, payload: res.data });
      authApi.setAuthenticated(true, 'patient');
      history.push('/dashboard/origin_login');
    }
  }
};

export const doctorLogin = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/auth/doctor/login', values);
  if (res.data) {
    history.push('/dashboard');
    authApi.setAuthenticated(true, 'doctor');
    dispatch({ type: FETCH_USER, payload: res.data });
  }
};

export const pharmacyLogin = (values, history) => async (dispatch) => {
  const res = await axios.post('/api/auth/pharmacy/login', values);
  if (res.data) {
    history.push('/pharmacy-dashboard');
    authApi.setAuthenticated(true, 'patient');
    dispatch({ type: FETCH_USER, payload: res.data });
  }
};

export const resetIdleLogout = () => async (dispatch) => {
  dispatch({ type: 'idleLogout', payload: {} });
};
