import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Typography,
  Grid,
} from '@material-ui/core';

function ConfirmPatientRequest(props) {
  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>
        <Typography variant="h5" color="primary">
          Confirm Patient Request
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid item xs={12}>
            <Typography color="primary">
              Are you sure you want to request the next patient in queue?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              Prior to accepting to see the patient, please ensure that you are
              in a physical space that is private in order to protect the
              privacy of the patient information discussed over the video
              conference.
            </Typography>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{ backgroundColor: '#ff4a4a', color: '#ffffff' }}
          onClick={props.close}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={props.requestPatient}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmPatientRequest.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  requestPatient: PropTypes.func.isRequired,
};

export default ConfirmPatientRequest;
