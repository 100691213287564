import { SocketIOClient } from '../classes/socket-io-client';

class EventApi extends SocketIOClient {
  #events = [];
  #buffer = [];

  constructor() {
    super('events');
  }

  connect() {
    super.connect();
    this.client.on('connected', this._reset.bind(this));
  }

  /**
   * Adds a listener for events that are triggered on the server
   *
   * @param {string} event the event to listen to
   * @param {(data: any) => void} handler function invoked when event occures
   */
  on(event, handler) {
    if (this.client.connected) {
      // notify server to send events
      this.client.emit('join', event);

      // add event handler
      this.client.on(event, handler);
    } else {
      this.#buffer.push({ event: 'join', data: event });
    }

    if (!this.#events.includes(event)) {
      this.#events = [...this.#events, event];
    }
  }

  /**
   * Removes all event listeners for a given event
   *
   * @param {string} event The event to remove listeners for
   */
  off(event) {
    if (this.client.connected) {
      this.client.emit('leave', event);
      this.client.off(event);

      if (this.#events.includes(event)) {
        this.#events = this.#events.filter((val) => val !== event);
      }
    } else {
      this.#buffer.push({ event: 'leave', data: event });
    }
  }

  /**
   * Sends an event and data to the server to be emitted to other clients
   * listening
   *
   * @param {string} event The event type
   * @param {any} data Data to send alongside the event
   */
  emit(event, data) {
    if (this.client.connected) {
      let payload = data;
      if (typeof payload === 'object') payload = JSON.stringify(data);

      if (payload) {
        this.client.emit(event, payload);
      } else {
        this.client.emit(event);
      }
    } else {
      this.#buffer.push({ event, data });
    }
  }

  /**
   * Re-subscribes the client to events in the case of a disconnect
   *
   * @private
   */
  _reset() {
    if (this.client && this.client.connected) {
      this.#events.forEach((event) => {
        this.client.emit('subscribe', event);
      });
    }
  }
}

export default new EventApi();
