import React, { Component, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, Field, FormSection } from 'redux-form';
import {
  Button,
  CssBaseline,
  FormControl,
  Typography,
  Grid,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  Slide,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import EditOtherField from '../commonform/EditOtherField';
import * as actions from '../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: '1.5rem',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    float: 'right',
  },
  choices: {
    marginTop: 30,
  },
  title: {
    margin: '30px 0',
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  otherField: {
    marginTop: 40,
  },
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
  },
});

const ProcedureField = ({ name, input, label, state, meta: { error } }) => (
  <FormControl error={error}>
    <FormControlLabel
      error={error || undefined}
      style={{ display: 'flex' }}
      control={
        <Checkbox
          {...input}
          id={name}
          checked={state}
          values={state}
          error={error || undefined}
          // value="checkedB"
          color="primary"
        />
      }
      label={label}
    />
    {error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const CheckedProcedureField = ({ name, input, label, meta: { error } }) => {
  const [checked, setChecked] = useState(true);
  return (
    <FormControl error={error}>
      <FormControlLabel
        error={error || undefined}
        style={{ display: 'flex' }}
        control={
          <Checkbox
            {...input}
            id={name}
            checked={checked || input.value}
            error={error || undefined}
            color="primary"
            onClick={() => setChecked(false)}
          />
        }
        label={label}
      />
      {error ? <FormHelperText styles>*{error}</FormHelperText> : null}
    </FormControl>
  );
};

class EditMedicalProcedures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      procedures: [],
      openOtherField: false,
      otherFieldValue: '',
      touchedOther: false,
    };
  }

  componentDidMount() {
    this.setState({
      procedures: this.props.selectedUserMedical.medicalProcedures,
      otherFieldValue:
        this.props.selectedUserMedical.medicalProceduresOtherField,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderAllProceduresCheckBoxesFirstHalf(
    ProceduresArray,
    userMedicalProcedures
  ) {
    return _.map(ProceduresArray, ({ name }, index) => {
      if (index % 2 === 0) {
        if (userMedicalProcedures.includes(name)) {
          return (
            <Field name={name} label={name} component={CheckedProcedureField} />
          );
        }
        if (name !== 'Other') {
          return <Field name={name} label={name} component={ProcedureField} />;
        }
      }
      return null;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderAllProceduresCheckBoxesSecondHalf(
    ProceduresArray,
    userMedicalProcedures
  ) {
    return _.map(ProceduresArray, ({ name }, index) => {
      if (index % 2 !== 0) {
        if (userMedicalProcedures.includes(name)) {
          return (
            <Field name={name} label={name} component={CheckedProcedureField} />
          );
        }
        if (name !== 'Other') {
          return <Field name={name} label={name} component={ProcedureField} />;
        }
      }
      return null;
    });
  }

  renderChoiceField(userProcedures) {
    let text = '';
    if (this.props.selectedUserMedical.medicalProceduresOtherField !== '') {
      text = userProcedures
        .concat(this.props.selectedUserMedical.medicalProceduresOtherField)
        .join(', ');
    } else {
      text = userProcedures.join(', ');
    }

    if (
      userProcedures.length === 0 &&
      this.props.selectedUserMedical.medicalProceduresOtherField === ''
    ) {
      text = 'None';
    }

    return text;
  }

  render() {
    const {
      classes,
      selectedUserMedical,
      medicalProceduresArray,
      handleSubmit,
    } = this.props;
    const { procedures } = this.state;
    const userMedicalProcedures =
      selectedUserMedical.medicalProcedures.join(', ');

    const handleChange = (event) => {
      const name = event.target.name.replace('medicalProfile.', '');
      let newProcedures;
      if (!procedures.includes(name)) {
        newProcedures = [...procedures, name];
      } else {
        newProcedures = procedures.filter((val) => val !== name);
      }
      this.setState({ procedures: newProcedures });
    };

    return (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <FormSection name="medicalProfile">
              <Grid container direction="row">
                <Grid item className={classes.title}>
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Edit Medical Procedures
                  </Typography>
                </Grid>
                <Grid container direction="row">
                  <Grid item container xs={6} justify="left">
                    <FormGroup onChange={handleChange}>
                      {this.renderAllProceduresCheckBoxesFirstHalf(
                        medicalProceduresArray,
                        userMedicalProcedures
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item container xs={6} justify="left">
                    <FormGroup onChange={handleChange}>
                      {this.renderAllProceduresCheckBoxesSecondHalf(
                        medicalProceduresArray,
                        userMedicalProcedures
                      )}
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container direction="row" className={classes.otherField}>
                  <Field
                    name="medicalProceduresOtherField"
                    label="Add other medical allergies"
                    component={EditOtherField}
                  />
                </Grid>
                <Grid item xs={12} className={classes.choices}>
                  <Typography variant="body1" color="primary">
                    Your medical procedures:
                  </Typography>
                </Grid>
                <Grid xs={12} className={classes.textField}>
                  <Typography variant="h6" color="primary">
                    {this.renderChoiceField(procedures)}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  className={classes.bottomSpacing}
                >
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      className={classes.alignButton}
                      onClick={() => this.props.closeEditPage()}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.alignButton}
                      style={{
                        float: 'right',
                      }}
                      onClick={() => {
                        handleSubmit();
                        setTimeout(() => {
                          this.props.closeEditPage();
                        }, 300);
                      }}
                      disabled={this.props.pristine || !this.props.valid}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </FormSection>
          </main>
        </Slide>
      </Fragment>
    );
  }
}

const handleSave = (values, dispatch, props) => {
  const { medicalProceduresOtherField, ...rest } = values.medicalProfile;
  const mapValues = () => {
    const array = [];
    Object.entries(rest).forEach(([key, value]) => {
      if (value) array.push(key);
    });
    return array;
  };

  const payload = {
    medicalProfile: {
      medicalProceduresOtherField,
      medicalProcedures: mapValues(),
    },
    id: props.selectedUser._id,
  };

  dispatch(actions.editProfile(payload));
};

const validate = (values) => {
  const errors = { medicalProfile: {} };
  const { medicalProfile } = values;

  /* 
  when user selects an option, the associated obj gets added to medicalProfile
  when it is de-selected, entry is not removed from the list

  Therefore we cannot rely on the size of medicalProfile to tell us how many
  options are currently selected. Instead we get the subset of entries that
  do not equal false using lodash.reduce and check the size of that.
  */
  const selected = _.reduce(
    medicalProfile,
    (result, val) => {
      if (val) {
        result.push(val);
      }
      return result;
    },
    []
  );

  if (
    _.has(medicalProfile, 'No Past Medical Procedures') &&
    medicalProfile['No Past Medical Procedures'] !== false &&
    _.size(selected) > 1
  ) {
    errors.medicalProfile['No Past Medical Procedures'] =
      'You cannot select this with other options!';

    if (medicalProfile && medicalProfile.medicalProceduresOtherField) {
      errors.medicalProfile.medicalProceduresOtherField =
        'Please remove this value.';
    }
  }
  return errors;
};

function mapStateToProps(state) {
  const medicalProfile = {
    medicalProceduresOtherField:
      state.selectedUserMedical.medicalProceduresOtherField,
  };

  _.map(state.selectedUserMedical.medicalProcedures, (val) => {
    medicalProfile[val] = true;
  });

  return {
    auth: state.auth,
    selectedUser: state.selectedUser,
    selectedUserMedical: state.selectedUserMedical,
    medicalProceduresArray: state.medicalData.medicalProceduresArray,
    initialValues: { medicalProfile },
  };
}

EditMedicalProcedures.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedUserMedical: PropTypes.object.isRequired,
  medicalProceduresArray: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  closeEditPage: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
};

ProcedureField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  state: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
};

CheckedProcedureField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

const EditMedicalProceduresRedux = reduxForm({
  form: 'medical-procedures-form',
  validate,
  destroyOnUnmount: true,
  enableReinitialize: true,
  onSubmit: handleSave,
})(EditMedicalProcedures);

const ConnectedEditMedicalProcedures = connect(
  mapStateToProps,
  actions
)(EditMedicalProceduresRedux);

export default withStyles(styles)(ConnectedEditMedicalProcedures);
