import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';
import CheckoutFormYearlyFamilyPlan from './CheckoutFormYearlyFamilyPlan';
import CheckoutFormMonthlyFamilyPlan from './CheckoutFormMonthlyFamilyPlan';
import CheckoutFormPayAsYouGo from './CheckoutFormPayAsYouGo';
import CheckoutFormYearlyPlan from './CheckoutFormYearlyPlan';
import CheckoutFormMonthlyPlan from './CheckoutFormMonthlyPlan';
import CheckoutFormFiveCreditPackage from './CheckoutFormFiveCreditPackage';

class CheckoutForm extends Component {
  render() {
    const { selectedType, origin } = this.props.match.params;
    switch (selectedType) {
      case 'YearlyFamilyPlan':
        return (
          <Elements>
            <CheckoutFormYearlyFamilyPlan origin={origin} />
          </Elements>
        );
      case 'MonthlyFamilyPlan':
        return (
          <Elements>
            <CheckoutFormMonthlyFamilyPlan origin={origin} />
          </Elements>
        );
      case 'YearlyFamilyPlanExisting':
        return (
          <Elements>
            <CheckoutFormYearlyFamilyPlan existing="test" origin={origin} />
          </Elements>
        );
      case 'MonthlyFamilyPlanExisting':
        return (
          <Elements>
            <CheckoutFormMonthlyFamilyPlan existing="test" origin={origin} />
          </Elements>
        );
      case 'PayAsYouGo':
        return (
          <Elements>
            <CheckoutFormPayAsYouGo origin={origin} />
          </Elements>
        );
      case 'FiveCreditPackage':
        return (
          <Elements>
            <CheckoutFormFiveCreditPackage origin={origin} />
          </Elements>
        );
      case 'MonthlyPlan':
        return (
          <Elements>
            <CheckoutFormMonthlyPlan origin={origin} />
          </Elements>
        );
      case 'YearlyPlan':
        return (
          <Elements>
            <CheckoutFormYearlyPlan origin={origin} />
          </Elements>
        );
      default:
        return null;
    }
  }
}

CheckoutForm.propTypes = {
  match: PropTypes.object,
};

export default CheckoutForm;
