/* eslint-disable default-param-last, func-names */
import { SMART_FAMILY_PLAN, USER_PLANS } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    case SMART_FAMILY_PLAN:
      return { ...state, memberLimit: action.payload };
    case USER_PLANS:
      return { ...state, userPlans: action.payload };
    default:
      return state;
  }
}
