import React, { Component, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, Field, FormSection } from 'redux-form';
import {
  Button,
  CssBaseline,
  FormControl,
  Typography,
  Grid,
  FormHelperText,
  FormControlLabel,
  FormGroup,
  Slide,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import EditOtherField from '../commonform/EditOtherField';
import * as actions from '../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: '1.5rem',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    float: 'right',
  },
  choices: {
    marginTop: 30,
  },
  title: {
    margin: '30px 0',
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  otherField: {
    marginTop: 40,
  },
  textField: {
    backgroundColor: 'aliceblue',
    borderRadius: '5px',
  },
});

const ConditionField = ({ name, input, label, state, meta: { error } }) => (
  <FormControl error={error}>
    <FormControlLabel
      error={error || undefined}
      style={{ display: 'flex' }}
      control={
        <Checkbox
          {...input}
          id={name}
          checked={state}
          values={state}
          error={error || undefined}
          // value="checkedB"
          color="primary"
        />
      }
      label={label}
    />
    {error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const CheckedConditionField = ({ name, input, label, meta: { error } }) => {
  const [checked, setChecked] = useState(true);
  return (
    <FormControl error={error}>
      <FormControlLabel
        error={error || undefined}
        style={{ display: 'flex' }}
        control={
          <Checkbox
            {...input}
            id={name}
            checked={checked || input.value}
            error={error || undefined}
            color="primary"
            onClick={() => setChecked(false)}
          />
        }
        label={label}
      />
      {error ? <FormHelperText styles>*{error}</FormHelperText> : null}
    </FormControl>
  );
};

class EditMedicalConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conditions: [],
      openOtherField: false,
      otherFieldValue: '',
      touchedOther: false,
    };
  }

  componentDidMount() {
    this.setState({
      conditions: this.props.selectedUserMedical.currentMedicalCondition,
      otherFieldValue:
        this.props.selectedUserMedical.currentMedicalConditionOtherField,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderAllConditionsCheckBoxesFirstHalf(
    ConditionsArray,
    userMedicalConditions
  ) {
    return _.map(ConditionsArray, ({ name }, index) => {
      if (index % 2 === 0) {
        if (userMedicalConditions.includes(name)) {
          return (
            <Field name={name} label={name} component={CheckedConditionField} />
          );
        }
        if (name !== 'Other') {
          return <Field name={name} label={name} component={ConditionField} />;
        }
      }
      return null;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderAllConditionsCheckBoxesSecondHalf(
    ConditionsArray,
    userMedicalConditions
  ) {
    return _.map(ConditionsArray, ({ name }, index) => {
      if (index % 2 !== 0) {
        if (userMedicalConditions.includes(name)) {
          return (
            <Field name={name} label={name} component={CheckedConditionField} />
          );
        }
        if (name !== 'Other') {
          return <Field name={name} label={name} component={ConditionField} />;
        }
      }
      return null;
    });
  }

  renderChoiceField(userConditions) {
    let text = '';
    if (
      this.props.selectedUserMedical.currentMedicalConditionOtherField !== ''
    ) {
      text = userConditions
        .concat(
          this.props.selectedUserMedical.currentMedicalConditionOtherField
        )
        .join(', ');
    } else {
      text = userConditions.join(', ');
    }

    if (
      userConditions.length === 0 &&
      this.props.selectedUserMedical.currentMedicalConditionOtherField === ''
    ) {
      text = 'None';
    }

    return text;
  }

  render() {
    const {
      classes,
      selectedUserMedical,
      medicalConditionsArray,
      handleSubmit,
    } = this.props;
    const { conditions } = this.state;
    const userMedicalConditions =
      selectedUserMedical.currentMedicalCondition.join(', ');

    const handleChange = (event) => {
      const name = event.target.name.replace('medicalProfile.', '');
      let newConditions;

      if (!conditions.includes(name)) {
        newConditions = [...conditions, name];
      } else {
        newConditions = conditions.filter((val) => val !== name);
      }
      this.setState({ conditions: newConditions });
    };

    return (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <FormSection name="medicalProfile">
              <Grid container direction="row">
                <Grid item className={classes.title}>
                  <Typography
                    variant="h5"
                    color="primary"
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    Edit Medical Conditions
                  </Typography>
                </Grid>
                <Grid container direction="row">
                  <Grid item container xs={6} justify="left">
                    <FormGroup onChange={handleChange}>
                      {this.renderAllConditionsCheckBoxesFirstHalf(
                        medicalConditionsArray,
                        userMedicalConditions
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item container xs={6} justify="left">
                    <FormGroup onChange={handleChange}>
                      {this.renderAllConditionsCheckBoxesSecondHalf(
                        medicalConditionsArray,
                        userMedicalConditions
                      )}
                    </FormGroup>
                  </Grid>
                </Grid>
                <Grid container direction="row" className={classes.otherField}>
                  <Field
                    name="currentMedicalConditionOtherField"
                    label="Add other medical allergies"
                    component={EditOtherField}
                  />
                </Grid>
                <Grid item xs={12} className={classes.choices}>
                  <Typography variant="body1" color="primary">
                    Your medical conditions:
                  </Typography>
                </Grid>
                <Grid xs={12} className={classes.textField}>
                  <Typography variant="h6" color="primary">
                    {this.renderChoiceField(conditions)}
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  className={classes.bottomSpacing}
                >
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      className={classes.alignButton}
                      onClick={() => this.props.closeEditPage()}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.alignButton}
                      style={{
                        float: 'right',
                      }}
                      onClick={() => {
                        handleSubmit();
                        setTimeout(() => {
                          this.props.closeEditPage();
                        }, 300);
                      }}
                      disabled={this.props.pristine || !this.props.valid}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </FormSection>
          </main>
        </Slide>
      </Fragment>
    );
  }
}

const handleSave = (values, dispatch, props) => {
  const { currentMedicalConditionOtherField, ...rest } = values.medicalProfile;
  const mapValues = () => {
    const array = [];
    Object.entries(rest).forEach(([key, value]) => {
      if (value) array.push(key);
    });
    return array;
  };

  const payload = {
    medicalProfile: {
      currentMedicalConditionOtherField,
      currentMedicalCondition: mapValues(),
    },
    id: props.selectedUser._id,
  };

  dispatch(actions.editProfile(payload));
};

const validate = (values) => {
  const errors = { medicalProfile: {} };
  const { medicalProfile } = values;

  /* 
  when user selects an option, the associated obj gets added to medicalProfile
  when it is de-selected, entry is not removed from the list

  Therefore we cannot rely on the size of medicalProfile to tell us how many
  options are currently selected. Instead we get the subset of entries that
  do not equal false using lodash.reduce and check the size of that.
  */
  const selected = _.reduce(
    medicalProfile,
    (result, val) => {
      if (val) {
        result.push(val);
      }
      return result;
    },
    []
  );

  if (
    _.has(medicalProfile, 'No Medical Conditions') &&
    medicalProfile['No Medical Conditions'] !== false &&
    _.size(selected) > 1
  ) {
    errors.medicalProfile['No Medical Conditions'] =
      'You cannot select this with other options!';
  }

  if (
    _.has(medicalProfile, 'No Medical Conditions') &&
    medicalProfile['No Medical Conditions'] !== false &&
    _.size(selected) > 1
  ) {
    errors.medicalProfile['No Medical Conditions'] =
      'You cannot select this with other options!';

    if (medicalProfile && medicalProfile.currentMedicalConditionOtherField) {
      errors.medicalProfile.currentMedicalConditionOtherField =
        'Please remove this value.';
    }
  }

  return errors;
};

function mapStateToProps(state) {
  const medicalProfile = {
    currentMedicalConditionOtherField:
      state.selectedUserMedical.currentMedicalConditionOtherField,
  };

  _.map(state.selectedUserMedical.currentMedicalCondition, (val) => {
    medicalProfile[val] = true;
  });

  return {
    auth: state.auth,
    selectedUser: state.selectedUser,
    selectedUserMedical: state.selectedUserMedical,
    medicalConditionsArray: state.medicalData.currentMedicalConditionArray,
    initialValues: { medicalProfile },
  };
}

EditMedicalConditions.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedUserMedical: PropTypes.object.isRequired,
  medicalConditionsArray: PropTypes.array.isRequired,
  closeEditPage: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

ConditionField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  state: PropTypes.bool.isRequired,
  meta: PropTypes.object.isRequired,
};

CheckedConditionField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

const EditMedicalConditionsRedux = reduxForm({
  form: 'medical-conditions-form',
  validate,
  destroyOnUnmount: true,
  enableReinitialize: true,
  onSubmit: handleSave,
})(EditMedicalConditions);

const ConnectedEditMedicalConditions = connect(
  mapStateToProps,
  actions
)(EditMedicalConditionsRedux);

export default withStyles(styles)(ConnectedEditMedicalConditions);
