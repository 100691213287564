import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { doctorSocket } from '../api/doctor-ws';

export const DoctorListContext = createContext();

export function DoctorListProvider({ children }) {
  const [doctorList, setDoctorList] = useState([]);
  const [inSession, setInSession] = useState([]);

  function updateDoctorList(doctors) {
    if (!Array.isArray(doctors)) throw new Error('doctors was not an array');

    const list = doctors.map((doctor) => {
      let isInSession = false;

      inSession.forEach((session) => {
        if (session.doctor === doctor._id) {
          isInSession = true;
        }
      });

      return {
        _id: doctor._id || '',
        name: doctor.name || { first: '', last: '' },
        email: doctor.email || '',
        mobilePhone: doctor.mobilePhone || '',
        inSession: isInSession,
      };
    });

    setDoctorList(list);
  }

  useEffect(() => {
    doctorSocket.on('doctorList', updateDoctorList);
    doctorSocket.on('currentSessions', (args) => setInSession(args));

    return () => {
      doctorSocket.off('doctorList', updateDoctorList);
      doctorSocket.off('currentSessions', (args) => setInSession(args));
    };
  }, []);

  useEffect(() => {
    updateDoctorList(doctorList);
  }, [inSession]);

  return (
    <DoctorListContext.Provider value={{ doctorList, inSession }}>
      {children}
    </DoctorListContext.Provider>
  );
}

DoctorListProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useDoctorList = () => useContext(DoctorListContext);
