import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Typography, Grid } from '@material-ui/core';

const DataUseConsentText = () => (
  <Fragment>
    <Grid item xs={12} container>
      <Grid xs={12}>
        <Typography align="center" color="primary">
          SnapMED Terms and Consent to Use of Data
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          padding: '10px',
          height: '200px',
          border: 'solid 1px',
          overflowY: 'scroll',
        }}
      >
        <Typography variant="body2" color="primary">
          <p>
            SnapMed provides a medical platform that allows members of the
            public to safely and securely connect to physicians or other health
            practitioners for medical or health related consultations. This form
            of consultation is called Telehealth in Canada. It includes using
            secure technology to allow communication between patients and
            providers without them being present in the same location.
          </p>
          <p>
            You are able to communicate with the practitioners on SnapMed
            through phone, chat or video conferencing. It is your responsibility
            to ensure that you are in a location where your communication
            remains private between you and the practitioner and that the method
            of communication that you choose to use, including but not limited
            to phone line, email server or the internet and WIFI network, is
            secure. You hereby understand that there is an inherent risk in all
            online communication and release SnapMed from any and all loss or
            liability with respect to any type of interception of data that may
            occur during or after your consultation.
          </p>
          <p>
            To provide this service, SnapMed offers a platform in which users
            provide information for SnapMed to manage the user accounts and
            personal health information for physicians and other health care
            practitioners to use in provision of health care to the user through
            the SnapMed platform. Your use of the SnapMed platform is governed
            by the SnapMed{' '}
            <Link to="/terms-and-conditions" target="_blank">
              <Typography variant="caption" color="primary" align="center">
                Terms of Use
              </Typography>
            </Link>
            , which you are responsible to read and understand.
          </p>
          <p>
            You are responsible for the information that you provide and your
            use of the information that you receive, including your use of any
            prescriptions received through SnapMed. SnapMed is not responsible
            for any errors or abuse you commit in the administration of
            medications or in the course of medical treatments. SnapMed does not
            record the audio or video transmissions between users and
            physicians/health care practitioners. SnapMed does not permit users
            to record the audio or video transmissions between users and
            physicians/health care practitioners.
          </p>
          <p>
            SnapMed uses your information in accordance with its{' '}
            <Link to="/privacy-policy" target="_blank">
              <Typography variant="caption" color="primary" align="center">
                Privacy Policy
              </Typography>
            </Link>
            .
          </p>
          <p>
            As part of the continuous development and improvement plans, SnapMed
            may collect information that are non-identifying. Only when
            necessary to manage the platform, certain personal information may
            be used, most commonly for statistical operation or process
            improvements.
          </p>
          <p>
            In addition to using your personal information to provide its
            services and the use by physicians/health practitioners to provide
            their services, SnapMed may use your personal information to comply
            with applicable regulatory and legal requirements. SnapMed may also
            use your personal information for its internal quality improvement
            to help improve the services provided on the SnapMed platform
            including customizing the experience, providing content and
            advertising.
          </p>
          <p>
            SNAPMED IS NOT A REPLACEMENT FOR PRIMARY CARE. The SnapMed platform
            is provided for non-urgent health care needs only. Examples of the
            types of health needs supported through SnapMed are listed on the
            platform.
          </p>
          <p>
            SERIOUS MEDICAL EMERGENCIES CANNOT BE TREATED THROUGH THE SNAPMED
            PLATFORM. IF YOU THINK YOU HAVE AN EMERGENCY, PLEASE CALL 911 AND
            IMMEDIATELY ATTEND THE NEAREST HOSPITAL FOR HELP.
          </p>
          <p>
            If you withdraw consent or otherwise opt out of consenting to the
            use of data, it will not impact any personal information that has
            already been collected, used or disclosed by SnapMed or its
            physicians/health practitioners.
          </p>
          <p>
            By clicking “I Have Read and I Agree” you hereby acknowledge and
            agree to comply with the Terms of Use and you hereby consent to the
            use of data for the purposes set out above and in the Privacy Policy
            and agree that such consent has been freely given. You hereby
            release SnapMed from any loss or liability with respect to the use
            of your personal information in accordance with this document, the{' '}
            <Link to="/terms-and-conditions" target="_blank">
              <Typography variant="caption" color="primary" align="center">
                Terms of Use
              </Typography>
            </Link>{' '}
            and our{' '}
            <Link to="/privacy-policy" target="_blank">
              <Typography variant="caption" color="primary" align="center">
                Privacy Policy
              </Typography>
            </Link>
            .
          </p>
        </Typography>
      </Grid>
    </Grid>
  </Fragment>
);

export default DataUseConsentText;
