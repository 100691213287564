import React, { Component, Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import PropTypes from 'prop-types';
import {
  Button,
  CssBaseline,
  FormControl,
  Radio,
  RadioGroup,
  Paper,
  Typography,
  TextField,
  Grid,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  withStyles,
} from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import * as actions from '../../../actions';
import validationHelperFunction from '../validationHelperFunction';
import snapmedLogo from '../../SnapMedLogoRetina';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  paper: {
    display: 'flex',
    flexGrow: 1,
    padding: '1.5rem',
    borderRadius: '7px',
  },
  form: {
    width: '100%', // Fix IE11 issue.
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    float: 'right',
  },
  alignButton: {
    float: 'right',
    marginTop: 40,
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  backButton: {
    marginRight: theme.spacing.unit,
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

function validate(values, props) {
  let errors = {};

  const { medicalAllergiesArray } = props;
  const { otherAllergiesArray } = props;

  errors = validationHelperFunction(
    values,
    medicalAllergiesArray,
    'medical_allergy_',
    'medical_allergy_No Medical Allergies',
    errors
  );
  errors = validationHelperFunction(
    values,
    otherAllergiesArray,
    'other_allergy_',
    'other_allergy_No Other Allergies',
    errors
  );

  if (!values.isTobaccoSmoker) {
    errors.isTobaccoSmoker = 'Please Select an Option';
  }
  if (!values.isAlcoholConsumer) {
    errors.isAlcoholConsumer = 'Please Select an Option';
  }
  if (!values.isAlcoholConsumer) {
    errors.isAlcoholConsumer = 'Please Select an Option';
  }
  if (!values.isSubstanceUser) {
    errors.isSubstanceUser = 'Please Select an Option';
  }

  if (
    values.isSubstanceUser &&
    !values.substanceInputField &&
    values.isSubstanceUser === 'isSubstanceUser_yes'
  ) {
    errors.substanceInputField = 'This field cannot be blank';
  }

  if (
    !('medical_allergies_other' in values) &&
    !values.medical_allergies_other &&
    !values.medical_allergies_other_field
  ) {
    errors.medical_allergies_other_field = 'This field cannot be blank';
  }

  if (
    !('other_allergies_other' in values) &&
    !values.other_allergies_other &&
    !values.other_allergies_other_field
  ) {
    errors.other_allergies_other_field = 'This field cannot be blank';
  }

  return errors;
}

const AllergyField = ({ name, input, label, meta: { touched, error } }) => (
  <div>
    <FormControl error={touched && error}>
      <FormControlLabel
        error={touched && error ? error : undefined}
        style={{ display: 'flex' }}
        control={
          <Checkbox
            {...input}
            id={name}
            checked={input.value ? label : false}
            error={touched && error ? error : undefined}
            // value="checkedB"
            color="primary"
          />
        }
        label={label}
      />
      {touched && error ? (
        <FormHelperText styles>*{error}</FormHelperText>
      ) : null}
    </FormControl>
  </div>
);

const SmokingField = ({ name, input, meta: { touched, error } }) => (
  <FormControl error={touched && error} component="fieldset">
    <RadioGroup name={name} {...input} row>
      <FormControlLabel
        value="isTobaccoSmoker_yes"
        control={<Radio color="primary" />}
        label="Yes"
      />
      <FormControlLabel
        value="isTobaccoSmoker_no"
        control={<Radio color="primary" />}
        label="No"
      />
    </RadioGroup>
    {touched && error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const DrinkingField = ({ name, input, meta: { touched, error } }) => (
  <FormControl error={touched && error} component="fieldset">
    <RadioGroup name={name} {...input} row>
      <FormControlLabel
        value="isAlcoholConsumer_yes"
        control={<Radio color="primary" />}
        label="Yes"
      />
      <FormControlLabel
        value="isAlcoholConsumer_no"
        control={<Radio color="primary" />}
        label="No"
      />
    </RadioGroup>
    {touched && error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const SubstanceField = ({ name, input, meta: { touched, error } }) => (
  <FormControl error={touched && error} component="fieldset">
    <RadioGroup name={name} {...input} row>
      <FormControlLabel
        value="isSubstanceUser_yes"
        control={<Radio color="primary" />}
        label="Yes"
      />
      <FormControlLabel
        value="isSubstanceUser_no"
        control={<Radio color="primary" />}
        label="No"
      />
    </RadioGroup>
    {touched && error ? <FormHelperText styles>*{error}</FormHelperText> : null}
  </FormControl>
);

const OtherField = ({ name, label, input, meta: { error, touched } }) => (
  <TextField
    {...input}
    id={name}
    label={label}
    type="input"
    variant="outlined"
    fullWidth
    error={touched && error}
    helperText={touched && error}
  />
);

const OtherFieldCheckbox = ({ name, id, label, input }) => {
  const dispatch = useDispatch();
  return (
    <FormControl>
      <FormControlLabel
        id={name}
        control={<Checkbox {...input} color="primary" />}
        label={label}
        labelPlacement="end"
        onClick={dispatch(actions.activateAllergiesOtherField(input.value, id))}
      />
    </FormControl>
  );
};

class AboutYouForm extends Component {
  renderAllAllergiesCheckBoxesFirstHalf(AllergiesArray, naming) {
    const filtered = AllergiesArray.filter((_, index) => index % 2 === 0);

    return filtered.map(({ name, _id }) => {
      const fieldProps = {
        name: `${naming}${name}`,
        label: name,
        type: 'checkbox',
        component: name === 'Other' ? OtherFieldCheckbox : AllergyField,
      };

      if (name === 'Other') {
        fieldProps.id = `${naming}${name}`;
        fieldProps.onChange = (_e, newValue) => {
          if (!newValue) {
            if (naming === 'medical_allergy_') {
              this.props.change('medical_allergies_other_field', '');
            } else {
              this.props.change('other_allergies_other_field', '');
            }
          }
        };
      } else {
        fieldProps.parse = (value) => (value ? { name, _id } : false);
      }

      return <Field {...fieldProps} key={_id} />;
    });
  }

  renderAllAllergiesCheckBoxesSecondHalf(AllergiesArray, naming) {
    const filtered = AllergiesArray.filter((_, index) => index % 2 !== 0);

    return filtered.map(({ name, _id }) => {
      const fieldProps = {
        name: `${naming}${name}`,
        label: name,
        type: 'checkbox',
        component: name === 'Other' ? OtherFieldCheckbox : AllergyField,
      };

      if (name === 'Other') {
        fieldProps.id = `${naming}${name}`;
        fieldProps.onChange = (_e, newValue) => {
          if (!newValue) {
            if (naming === 'medical_allergy_') {
              this.props.change('medical_allergies_other_field', '');
            } else {
              this.props.change('other_allergies_other_field', '');
            }
          }
        };
      } else {
        fieldProps.parse = (value) => (value ? { name, _id } : false);
      }

      return <Field {...fieldProps} key={_id} />;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  renderSmokingField() {
    return (
      <Field
        name="isTobaccoSmoker"
        type="text"
        // onBlur={null}
        component={SmokingField}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderDrinkingField() {
    return (
      <Field name="isAlcoholConsumer" type="text" component={DrinkingField} />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSubstanceField() {
    return (
      <Field name="isSubstanceUser" type="text" component={SubstanceField} />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderOtherField(name, label) {
    return <Field name={name} label={label} component={OtherField} />;
  }

  render() {
    const { handleSubmit, classes } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            <Grid container direction="row">
              <Grid xs={12}>
                <img
                  src={snapmedLogo}
                  style={{ width: 100, marginBottom: 10 }}
                />
              </Grid>
              <form className={classes.form} onSubmit={handleSubmit}>
                <Typography
                  variant="h4"
                  color="primary"
                  style={{
                    textAlign: 'center',
                  }}
                >
                  About You
                </Typography>
                <br />
                <Grid container direction="row">
                  <Grid item container xs={6} alignItems="center">
                    <Typography variant="body1">
                      Do you smoke tobacco?
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {this.renderSmokingField()}
                    {/* {this.props.isTobaccoSmoker==='isTobaccoSmoker_yes' && (  )} */}
                  </Grid>
                  <Grid item container xs={6} alignItems="center">
                    <Typography variant="body1">
                      Do you consume alcohol?
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {this.renderDrinkingField()}
                    {/* {this.props.isAlcoholDrinker==='isTobaccoSmoker_yes' && (  )} */}
                  </Grid>
                  <Grid item container xs={6} alignItems="center">
                    <Typography variant="body1">
                      Do you use any substances?
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    {this.renderSubstanceField()}
                  </Grid>
                  <Grid xs={12}>
                    {this.props.isSubstanceUser === 'isSubstanceUser_yes' &&
                      this.renderOtherField('substanceInputField', 'Substance')}
                  </Grid>
                </Grid>
                <br />
                <Typography variant="h6" color="primary">
                  Please indicate if you have any medical allergies:{' '}
                </Typography>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <div>
                      {this.renderAllAllergiesCheckBoxesFirstHalf(
                        this.props.medicalAllergiesArray,
                        'medical_allergy_'
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      {this.renderAllAllergiesCheckBoxesSecondHalf(
                        this.props.medicalAllergiesArray,
                        'medical_allergy_'
                      )}
                    </div>
                  </Grid>
                  {this.props.medicalAllergiesOtherField === true && (
                    <Grid item xs={12}>
                      {this.renderOtherField(
                        'medical_allergies_other_field',
                        'Other medical allergies'
                      )}
                    </Grid>
                  )}
                </Grid>
                <br />

                <Typography variant="h6" color="primary">
                  Please indicate if you have any other allergies:{' '}
                </Typography>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    <div>
                      {this.renderAllAllergiesCheckBoxesFirstHalf(
                        this.props.otherAllergiesArray,
                        'other_allergy_'
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div>
                      {this.renderAllAllergiesCheckBoxesSecondHalf(
                        this.props.otherAllergiesArray,
                        'other_allergy_'
                      )}
                    </div>
                  </Grid>
                  {this.props.otherAllergiesOtherField === true && (
                    <Grid item xs={12}>
                      {this.renderOtherField(
                        'other_allergies_other_field',
                        'Other allergies'
                      )}
                    </Grid>
                  )}
                </Grid>
                <Button
                  endIcon={<ArrowForwardIos />}
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  type="submit"
                >
                  Next
                </Button>
              </form>
            </Grid>
          </Paper>
        </main>
      </Fragment>
    );
  }
}

const selector = formValueSelector('wizard'); // <-- same as form name

function mapStateToProps(state) {
  return {
    wizard: state.wizard,
    formData: state.form,
    medicalAllergiesArray: state.medicalData.medicalAllergiesArray,
    otherAllergiesArray: state.medicalData.otherAllergiesArray,
    otherAllergiesOtherField:
      state.medicalFormOtherField.otherAllergiesOtherField,
    isAlcoholDrinker: selector(state, 'isAlcoholConsumer'),
    isTobaccoSmoker: selector(state, 'isTobaccoSmoker'),
    isSubstanceUser: selector(state, 'isSubstanceUser'),
    medicalAllergiesOtherField: selector(state, 'medical_allergy_Other'),
  };
}

AboutYouForm.propTypes = {
  classes: PropTypes.object.isRequired,
  medicalAllergiesArray: PropTypes.array,
  otherAllergiesArray: PropTypes.array,
  otherAllergiesOtherField: PropTypes.bool,
  previousStep: PropTypes.func,
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  change: PropTypes.func,
  isAlcoholDrinker: PropTypes.string,
  isTobaccoSmoker: PropTypes.string,
  isSubstanceUser: PropTypes.string,
  medicalAllergiesOtherField: PropTypes.string,
};

AllergyField.propTypes = {
  name: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
};

OtherField.propTypes = {
  name: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.string,
};

OtherFieldCheckbox.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.object,
};

SmokingField.propTypes = {
  name: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
};

DrinkingField.propTypes = {
  name: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
};

SubstanceField.propTypes = {
  name: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.object,
};

OtherFieldCheckbox.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  input: PropTypes.object,
};

const AboutYouFormRedux = reduxForm({
  validate,
  form: 'wizard',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(AboutYouForm);

export default withStyles(styles)(
  connect(mapStateToProps, actions)(AboutYouFormRedux)
);
