import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  ButtonBase,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios';

import * as actions from '../../actions';

class UpgradePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      creditCardList: [],
      processing: false,
      upgradePrice: 0,
    };
  }

  componentDidMount = async () => {
    let familyProduct = this.getFamilySub();
    let personalProduct = this.getPersonalSub();

    await this.props.checkCreditCardList({
      customerId: this.props.auth.customerId,
    });

    this.setState({ creditCardList: this.props.creditCardList });

    [{ value: familyProduct }, { value: personalProduct }] =
      await Promise.allSettled([familyProduct, personalProduct]);

    if (familyProduct.data && personalProduct.data) {
      this.setState({
        upgradePrice:
          (familyProduct.data.prices.data[0].unit_amount -
            personalProduct.data.prices.data[0].unit_amount) /
          100,
      });
    }
  };

  componentDidUpdate = () => {
    if (this.props.open !== this.state.open) {
      this.setState({
        open: this.props.open,
      });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  getFamilySub = async () =>
    axios.get('/api/billing/stripe/yearly-family-plan');

  // eslint-disable-next-line class-methods-use-this
  getPersonalSub = async () => axios.get('/api/billing/stripe/yearly-plan');

  handleClose = (options) => {
    this.setState({ open: false });
    if (this.props.onClose && typeof this.props.onClose === 'function') {
      this.props.onClose(options);
    }
  };

  handleContinue = async () => {
    this.setState({ processing: true });
    if (this.props.onContinue && typeof this.props.onContinue === 'function') {
      await this.props.onContinue();
    }
  };

  render() {
    const { creditCardList, upgradePrice } = this.state;
    return (
      <Dialog open={this.state.open} onClose={this.handleClose}>
        <DialogTitle>
          <Typography variant="h5" color="primary">
            Upgrade to Family Plan?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Your card ending in{' '}
            <b>
              ••••{' '}
              {creditCardList && creditCardList.length > 0
                ? creditCardList[0].card.last4
                : null}{' '}
            </b>
            will be charged <b>${upgradePrice} CAD plus HST</b>. If you wish to
            change your payment method, click{' '}
            <ButtonBase
              style={{
                color: 'blue',
                marginBottom: '2px',
                fontSize: '15px',
              }}
              onClick={() => {
                this.handleClose({
                  redirect: true,
                  page: 'patientSettings',
                  path: '/dashboard/patientSettings/payments',
                });
              }}
            >
              HERE
            </ButtonBase>
          </Typography>
          {this.state.processing && (
            <Grid container justify="center" style={{ paddingTop: 16 }}>
              <Grid item>
                <CircularProgress size={25} color="primary" />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions
          style={{
            paddingLeft: 24,
            paddingRight: 24,
            paddingTop: 16,
            paddingBottom: 16,
          }}
        >
          <Button variant="contained" onClick={this.handleClose}>
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleContinue}
            disabled={this.state.processing}
          >
            Upgrade
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  creditCardList: state.creditCardList,
});

UpgradePlan.propTypes = {
  auth: PropTypes.object,
  creditCardList: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onContinue: PropTypes.func,
  checkCreditCardList: PropTypes.func,
};

export default connect(mapStateToProps, actions)(withRouter(UpgradePlan));
