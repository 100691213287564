import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  CssBaseline,
  Paper,
  Typography,
  withStyles,
  Grid,
  CircularProgress,
  Button,
} from '@material-ui/core';
import * as actions from '../../actions';

const styles = (theme) => ({
  layout: {
    width: 'auto',
    height: '100%',
    //  display: 'block', // Fix IE11 issue.
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.down(350 + theme.spacing.unit * 3 * 2)]: {
      width: 350,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    flexGrow: 1,
    flexDirection: 'row',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${
      theme.spacing.unit * 3
    }px`,
    borderRadius: '7px',
  },
  content: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
  },
});

class CheckoutConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      product: undefined,
      redirectUrl: undefined,
    };
  }

  componentDidMount = async () => {
    await this.props.fetchUser();
    await this.props.selectedUserChange({
      fullName: this.props.selectedUser.fullName,
      id: this.props.selectedUser._id,
    });

    this.props.selectedUser.ohipMembershipEnd = new Date(
      this.props.selectedUser.ohipMembershipEnd
    );

    this.setState({
      loading: false,
      redirect: this.props.location.state.redirect,
      product: this.props.location.state.product,
    });
  };

  render() {
    const { classes, auth, selectedUser } = this.props;
    const { product } = this.state;
    return (
      <Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Paper className={classes.paper}>
            {this.state.loading ? (
              <Grid container justify="center">
                <Grid item>
                  <CircularProgress size={25} color="primary" />
                </Grid>
              </Grid>
            ) : (
              <Fragment>
                <Typography variant="h4" color="primary">
                  Purchase Complete!
                </Typography>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={classes.content}
                >
                  <Grid item xs={12}>
                    <Typography>
                      Thank you for purchasing {product.name}.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      Your e-receipt will be emailed to: {auth.email}.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5" color="primary">
                      Product Details
                    </Typography>
                    <Typography>
                      <b>User:</b> {selectedUser.fullName}
                    </Typography>
                    <Typography>
                      <b>Item:</b> {product.name}
                    </Typography>
                    <Typography>
                      <b>Total:</b> ${product.unit_amount / 100}{' '}
                      {product.currency.toUpperCase()}
                    </Typography>
                    {product.product_type === 'Subscription' && (
                      <Typography variant="body1">
                        <b>Ends:</b>{' '}
                        {selectedUser.ohipMembershipEnd.toDateString()},{' '}
                        {selectedUser.ohipMembershipEnd.toLocaleTimeString()}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Fragment>
            )}
            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => this.props.history.push(this.state.redirect)}
                >
                  Continue
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </main>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  selectedUser: state.selectedUser,
});

CheckoutConfirmation.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  selectedUser: PropTypes.object.isRequired,
  fetchUser: PropTypes.func.isRequired,
  selectedUserChange: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const ConnectedCheckoutConfirmation = connect(
  mapStateToProps,
  actions
)(withStyles(styles)(CheckoutConfirmation));

export default ConnectedCheckoutConfirmation;
