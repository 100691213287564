import React, { Component, Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, FormSection, change } from 'redux-form';
import ReactFlagsSelect from 'react-flags-select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputMask from 'react-input-mask';
import _ from 'lodash';
import {
  Button,
  CssBaseline,
  FormControl,
  MenuItem,
  Select,
  Typography,
  TextField,
  Grid,
  InputLabel,
  OutlinedInput,
  Slide,
  FormHelperText,
  withStyles,
} from '@material-ui/core';
import { createTextMask } from 'redux-form-input-masks';

import countryRegionFields from './CountryRegionFields';
import * as actions from '../../actions';

const styles = (theme) => ({
  layout: {
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  alignButton: {
    marginTop: 40,
    marginBottom: 10,
    marginLeft: 8,
    marginRight: 8,
  },
  bottomSpacing: {
    marginBottom: 40,
  },
  tagBottomSpacing: {
    marginBottom: 5,
  },
  dateInput: {
    '&::placeholder': {
      color: 'black',
      opacity: '1',
    },
  },
  menuFlagsContainer: {
    maxWidth: '96px',
    height: '100%',
    paddingBottom: 0,
  },
  menuFlagsButton: {
    height: '56px',
  },
});

const customPostalCodeMaskDefinitions = {
  9: {
    regExp: /[0-9]/,
  },
  A: {
    regExp: /[A-Za-z]/,
    transform: (char) => char.toUpperCase(),
  },
};

const postalCodeMask = createTextMask({
  pattern: 'A9A 9A9',
  guide: false,
  stripMask: false,
  maskDefinitions: customPostalCodeMaskDefinitions,
});

const ProfileField = ({ input, placeholder, disabled, name, type }) => (
  <TextField
    {...input}
    variant="outlined"
    type={type}
    id={name}
    fullWidth
    placeholder={placeholder}
    disabled={disabled}
    InputProps={{
      style: { color: 'black' },
    }}
  />
);

const DateField = ({ input, disabled, name, defaultValue }) => (
  <TextField
    id={name}
    variant="outlined"
    disabled={disabled}
    // label={defaultValue.substring(0, 10)}
    placeholder={defaultValue ? defaultValue.substring(0, 10) : ''}
    InputProps={{
      style: { color: 'black' },
    }}
    inputProps={{ max: '9999-12-31' }}
    fullWidth
    {...input}
  />
);

const userGender = [
  'Male',
  'Female',
  'Transgender Male / FTM',
  'Transgender Female / MTF',
  'Non-binary / Other',
  'Rather not say',
];

const userSex = ['Male', 'Female'];

const GenderField = ({ input, placeholder, meta: { touched, error } }) => (
  <FormControl variant="outlined" fullWidth>
    <Autocomplete
      {...input}
      id="genderFieldAutocomplete"
      options={userGender}
      onChange={(e, val) => {
        input.onChange(val);
      }}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" placeholder={placeholder} />
      )}
    />
    {touched && error ? (
      <FormHelperText>
        <span style={{ color: '#f44336' }}>{error}</span>
      </FormHelperText>
    ) : null}
  </FormControl>
);

const SexField = ({ name, input, placeholder }) => (
  <FormControl variant="outlined" fullWidth disabled>
    <InputLabel id={name}> {placeholder} </InputLabel>
    <Select
      {...input}
      input={<OutlinedInput id={name} label={placeholder} disabled />}
    >
      {userSex.map((gend) => (
        <MenuItem key={gend} value={gend}>
          {gend}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const language = [
  'English',
  // ' Mandarin',
  // ' Spanish',
  // ' Italian',
  // ' Hindi',
  // ' Arabic',
  // ' Portuguese',
  // ' Bengali',
  // ' Russian',
  // ' Japanese',
  // ' Punjabi',
  // ' German',
  // ' French',
  // ' Korean'  //uncomment for future use
];

const phoneLabels = {
  GB: {
    primary: 'GB',
    secondary: '+44',
  },
  US: {
    primary: 'US',
    secondary: '+1',
  },
  CA: {
    primary: 'CA',
    secondary: '+1',
  },
  AU: {
    primary: 'AU',
    secondary: '+61',
  },
  LK: {
    primary: 'LK',
    secondary: '+94',
  },
  IN: {
    primary: 'IN',
    secondary: '+91',
  },
  FR: {
    primary: 'FR',
    secondary: '+33',
  },
};

const LanguageField = ({ name, input, placeholder }) => (
  <FormControl variant="outlined" fullWidth>
    <InputLabel id={name}> {placeholder} </InputLabel>
    <Select {...input} input={<OutlinedInput id={name} label={placeholder} />}>
      {language.map((lang) => (
        <MenuItem key={lang} value={lang}>
          {lang}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

class ProfileForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openAlert: false,
      regions: this.props.auth.province ? this.props.auth.province : false,
      regionsForCountry: this.props.auth.country
        ? _.find(countryRegionFields, ['countryName', this.props.auth.country])
            .regions
        : false,
      selectedUser: props.selectedUser.fullName,
      mainUser: `${props.auth.name.first} ${props.auth.name.last}`,
      firstName: props.auth.name.first,
      lastName: props.auth.name.last,
      dateOfBirth: props.auth.dateOfBirth,
      gender: props.auth.gender,
      sex: props.auth.sex,
      spokenLanguages: props.auth.spokenLanguages.join(', '),
      streetAddress: props.auth.address,
      country: props.auth.country,
      city: props.auth.city,
      province: props.auth.province,
      zipCode: props.auth.zipCode,
      mobilePhone: props.auth.mobilePhone,
      countryCode: '+1',
    };
  }

  // async componentDidMount() {
  //   await this.updateState();
  // }

  // async componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.selectedUser !== this.props.selectedUser) {
  //     await this.updateState();
  //   }
  // }

  menuFlags = () => {
    const [selected, setSelected] = useState('CA');
    const { classes } = this.props;
    return (
      <ReactFlagsSelect
        selected={selected}
        onSelect={(code) => {
          setSelected(code);
          switch (code) {
            case 'US':
              this.props.dispatch(
                change(
                  'dashboardUpdateForm',
                  'profileForm.country_code',
                  `${phoneLabels.US.secondary}`
                )
              );
              this.setState({
                countryCode: phoneLabels.US.secondary,
              });
              break;
            case 'CA':
              this.props.dispatch(
                change(
                  'dashboardUpdateForm',
                  'profileForm.country_code',
                  `${phoneLabels.CA.secondary}`
                )
              );
              this.setState({
                countryCode: phoneLabels.CA.secondary,
              });
              break;
            case 'AU':
              this.props.dispatch(
                change(
                  'dashboardUpdateForm',
                  'profileForm.country_code',
                  `${phoneLabels.AU.secondary}`
                )
              );
              this.setState({
                countryCode: phoneLabels.AU.secondary,
              });
              break;
            case 'LK':
              this.props.dispatch(
                change(
                  'dashboardUpdateForm',
                  'profileForm.country_code',
                  `${phoneLabels.LK.secondary}`
                )
              );
              this.setState({
                countryCode: phoneLabels.LK.secondary,
              });
              break;
            case 'IN':
              this.props.dispatch(
                change(
                  'dashboardUpdateForm',
                  'profileForm.country_code',
                  `${phoneLabels.IN.secondary}`
                )
              );
              this.setState({
                countryCode: phoneLabels.IN.secondary,
              });
              break;
            case 'FR':
              this.props.dispatch(
                change(
                  'dashboardUpdateForm',
                  'profileForm.country_code',
                  `${phoneLabels.FR.secondary}`
                )
              );
              this.setState({
                countryCode: phoneLabels.FR.secondary,
              });
              break;
            default:
              this.props.dispatch(
                change(
                  'dashboardUpdateForm',
                  'profileForm.country_code',
                  `${phoneLabels.CA.secondary}`
                )
              );
              this.setState({
                countryCode: phoneLabels.CA.secondary,
              });
              break;
          }
        }}
        showSelectedLabel={false}
        showOptionLabel={false}
        selectedSize={14}
        optionsSize={14}
        className={classes.menuFlagsContainer}
        selectButtonClassName={classes.menuFlagsButton}
        countries={['CA', 'US', 'AU', 'LK', 'IN', 'FR']}
        customLabels={phoneLabels}
      />
    );
  };

  // eslint-disable-next-line class-methods-use-this
  PhoneNumberField = ({
    input,
    name,
    placeholder,
    meta: { touched, error },
  }) => (
    <InputMask mask="(999) 999-9999" {...input} onChange={input.onChange}>
      {(inputProps) => (
        <TextField
          {...inputProps}
          fullWidth
          name={name}
          variant="outlined"
          placeholder={placeholder}
          error={touched && error}
          helperText={touched && error}
        />
      )}
    </InputMask>
  );

  // eslint-disable-next-line class-methods-use-this
  PhoneNumberFieldNoMask = ({
    input,
    name,
    placeholder,
    meta: { touched, error },
  }) => (
    <TextField
      {...input}
      fullWidth
      name={name}
      variant="outlined"
      placeholder={placeholder}
      error={touched && error}
      helperText={touched && error}
    />
  );

  // eslint-disable-next-line class-methods-use-this
  renderLanguageField(placeholder) {
    return (
      <Field
        name="spokenLanguages"
        type="text"
        placeholder={placeholder}
        multiple
        format={
          (value) => value || '' /* (Array.isArray(value) ? value : []) */
        }
        onBlur={null}
        component={LanguageField}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderSexField(placeholder) {
    return (
      <Field
        name="sex"
        type="text"
        placeholder={placeholder}
        multiple
        format={(value) => value || ''}
        onBlur={null}
        component={SexField}
        disabled
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderGenderField(placeholder) {
    return (
      <Field
        name="gender"
        type="text"
        placeholder={placeholder}
        selectOnFocus={true}
        format={(value) => value || ''}
        component={GenderField}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  ProvinceField = ({
    name,
    placeholder,
    regionsForCountry,
    input,
    meta: { error },
  }) => (
    <FormControl variant="outlined" fullWidth error={error}>
      <InputLabel id={name}> {placeholder} </InputLabel>

      <Select
        {...input}
        error={error}
        helperText={error}
        input={<OutlinedInput id={name} label={placeholder} />}
      >
        {regionsForCountry
          ? regionsForCountry.map((lang) => {
              if (lang) {
                return (
                  <MenuItem value={lang.name} key={lang.name}>
                    {lang.name}
                  </MenuItem>
                );
              }
              return null;
            })
          : []}
      </Select>
      <FormHelperText>{error}</FormHelperText>
    </FormControl>
  );

  renderProvinceFields = (name, type, placeholder) => {
    if (this.state.country) {
      return (
        <Field
          name={name}
          placeholder={placeholder}
          type={type}
          format={(value) => value || []}
          regionsForCountry={this.state.regionsForCountry}
          component={this.ProvinceField}
        />
      );
    }
    return (
      <Field
        name={name}
        type={type}
        placeholder={'Please Input Your Country First'}
        disabled={true}
        component={ProfileField}
      />
    );
  };

  // eslint-disable-next-line class-methods-use-this
  CountryField = ({ name, placeholder, input, meta: { touched, error } }) => (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id={name}> {placeholder} </InputLabel>

      <Select
        {...input}
        error={touched && error}
        helperText={touched && error}
        input={<OutlinedInput id={name} label={placeholder} />}
      >
        {countryRegionFields
          ? countryRegionFields.map((lang) => (
              <MenuItem value={lang.countryName} key={lang.countryName}>
                {lang.countryName}
              </MenuItem>
            ))
          : []}
      </Select>
    </FormControl>
  );

  // eslint-disable-next-line class-methods-use-this
  renderCountryFields(name, type, placeholder) {
    return (
      <Field
        name={name}
        placeholder={placeholder}
        type={type}
        format={(value) => value || []}
        component={this.CountryField}
        onChange={(event) => {
          this.setState({
            country: event.target.value,
          });
          countryRegionFields.map((obj) => {
            if (obj.countryName === event.target.value) {
              this.setState({
                regionsForCountry: obj.regions,
              });
            }
            return null;
          });
        }}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderFields(name, disabled, type, placeholder) {
    return (
      <Field
        name={name}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        component={ProfileField}
      />
    );
  }

  renderPhoneFields(name, type, placeholder) {
    return (
      <Grid item container>
        <Grid item xs={4}>
          <Field name="country_code" component={this.menuFlags} />
        </Grid>
        {this.state.countryCode && this.state.countryCode === '+1' ? (
          <Grid item xs={8}>
            <Field
              name={name}
              type={type}
              placeholder={placeholder}
              component={this.PhoneNumberField}
            />
          </Grid>
        ) : (
          <Grid item xs={8}>
            <Field
              name={name}
              type={type}
              placeholder={placeholder}
              component={this.PhoneNumberFieldNoMask}
            />
          </Grid>
        )}
      </Grid>
    );
  }

  renderPostalCodeFields(name, type, placeholder) {
    if (this.state.country === 'Canada') {
      return (
        <Field
          name={name}
          type={type}
          placeholder={placeholder}
          component={ProfileField}
          {...postalCodeMask}
        />
      );
    }
    return (
      <Field
        name={name}
        type={type}
        placeholder={placeholder}
        component={ProfileField}
      />
    );
  }

  renderDateField(disabled, defaultValue) {
    return (
      <Field
        name="date_of_birth"
        disabled={disabled}
        placeholderStyle={this.props.classes.dateInput}
        defaultValue={defaultValue}
        component={DateField}
      />
    );
  }

  onClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, selectedUser } = this.props;

    return (
      <Fragment>
        <CssBaseline />
        <Slide in={true} direction="right">
          <main className={classes.layout}>
            <FormSection name="profileForm">
              <Grid
                container
                spacing={2}
                style={{
                  overflowX: 'hidden',
                }}
                direction="row"
              >
                <Grid
                  item
                  container
                  style={{ margin: '50px 0px' }}
                  alignItems="center"
                >
                  <Grid item xs={9} sm={10}>
                    <Typography variant="h5" color="primary">
                      Your Profile
                    </Typography>
                  </Grid>
                  <Grid item container xs={3} sm={2} justify="flex-end">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        this.props.changePage();
                        this.props.disableToolbar();
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    className={classes.tagBottomSpacing}
                  >
                    First Name
                  </Typography>
                  {this.renderFields(
                    'name.first',
                    true,
                    'text',
                    selectedUser.name.first,
                    true
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    className={classes.tagBottomSpacing}
                  >
                    Last Name
                  </Typography>
                  {this.renderFields(
                    'name.last',
                    true,
                    'text',
                    selectedUser.name.last,
                    true
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    className={classes.tagBottomSpacing}
                  >
                    Date of Birth
                  </Typography>
                  {this.renderDateField(true, selectedUser.dateOfBirth)}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    className={classes.tagBottomSpacing}
                  >
                    Gender Identity
                  </Typography>
                  {this.renderGenderField(selectedUser.gender)}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    className={classes.tagBottomSpacing}
                  >
                    Sex Assigned at Birth
                  </Typography>
                  {this.renderSexField(selectedUser.sex)}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body1"
                    className={classes.tagBottomSpacing}
                  >
                    Spoken Languages
                  </Typography>
                  {this.renderLanguageField(
                    selectedUser.spokenLanguages.join(', ')
                  )}
                </Grid>
                {this.props.selectedUser._id === this.props.auth._id && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        className={classes.tagBottomSpacing}
                      >
                        Mobile Phone Number
                      </Typography>
                      {this.renderPhoneFields(
                        'mobilePhone',
                        'text',
                        selectedUser.mobilePhone
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="body1"
                        className={classes.tagBottomSpacing}
                      >
                        Address
                      </Typography>
                      {this.renderFields(
                        'address',
                        false,
                        'text',
                        selectedUser.address
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        className={classes.tagBottomSpacing}
                      >
                        City
                      </Typography>
                      {this.renderFields(
                        'city',
                        false,
                        'text',
                        selectedUser.city
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        className={classes.tagBottomSpacing}
                      >
                        Province/Region
                      </Typography>
                      {this.renderProvinceFields(
                        'province',
                        'text',
                        selectedUser.province
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        className={classes.tagBottomSpacing}
                      >
                        Postal Code
                      </Typography>
                      {this.renderPostalCodeFields(
                        'zipCode',
                        'text',
                        selectedUser.zipCode
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Typography
                        variant="body1"
                        className={classes.tagBottomSpacing}
                      >
                        Country
                      </Typography>
                      {this.renderCountryFields(
                        'country',
                        'text',
                        selectedUser.country
                      )}
                    </Grid>
                  </>
                )}
                <Grid
                  container
                  direction="row"
                  className={classes.bottomSpacing}
                >
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      className={classes.alignButton}
                      onClick={() => {
                        this.props.changePage();
                        this.props.disableToolbar();
                      }}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.alignButton}
                      form="patient_info_update"
                      type="submit"
                      style={{
                        float: 'right',
                      }}
                      disabled={!this.props.valid}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </FormSection>
          </main>
        </Slide>
      </Fragment>
    );
  }
}

function validate(values) {
  const errors = { profileForm: {} };

  if (
    values.profileForm &&
    values.profileForm.mobile_phone_number &&
    values.profileForm.country_code === '+1' &&
    values.profileForm.mobile_phone_number.replace(/\D+/g, '').length !== 10
  ) {
    errors.profileForm = { mobile_phone_number: 'Please enter a valid number' };
  }

  // if (values.profileForm) {
  //   const countryObj = _.find(countryRegionFields, (obj) => {
  //     return obj.countryName === values.profileForm.country;
  //   });

  //   if (
  //     !_.find(countryObj.regions, (obj) => {
  //       return obj.name === values.profileForm.province;
  //     })
  //   ) {
  //     errors.profileForm['province'] = 'Please select a valid region';
  //   }
  // }

  return errors;
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    data: state.data,
    selectedUser: state.selectedUser,
    // initialValues: {
    //   profileForm: {
    //     country: state.auth.country,
    //     province: state.auth.province,
    //     gender: state.auth.gender,
    //     sex: state.auth.sex,
    //     spokenLanguages: state.auth.spokenLanguages[0],
    //     country_code: '+1',
    //   },
    // },
  };
}

const mapDispatchToProps = (dispatch) => ({
  change,
  actions,
  dispatch,
});

ProfileForm.propTypes = {
  auth: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  selectedUser: PropTypes.object.isRequired,
  changePage: PropTypes.func.isRequired,
  disableToolbar: PropTypes.func.isRequired,
  valid: PropTypes.bool.isRequired,
  change: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

ProfileField.propTypes = {
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
};

DateField.propTypes = {
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  meta: PropTypes.object,
};

GenderField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  meta: PropTypes.object,
};

SexField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  meta: PropTypes.object,
};

LanguageField.propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const ConnectedProfileForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileForm);

const ProfileFormRedux = reduxForm({
  form: 'dashboardUpdateForm',
  validate,
  enableReinitialize: true,
})(ConnectedProfileForm);

export default withStyles(styles)(ProfileFormRedux);
